.toggle_switch {
  display: block;
  position: relative;
  height: 18px;
  line-height: 18px;
}
.toggle_switch_list {
  display: none;
  position: absolute;
  left: 0;
  z-index: 20;
}
.toggle_switch_list.active {
  display: block;
}
.ico_mall.small {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 15px;
  background-image: url(#{$imgPath}/common/ico_mall_v2.png);
  background-repeat: no-repeat;
  *zoom: 1;
  *display: inline;
}
.ico_mall.small.emart {
  background-position: -200px 0;
  vertical-align: top;
}
.ico_mall.small.ssgmall {
  background-position: -200px -50px;
}
.ico_mall.small.depart {
  background-position: -200px -100px;
}
.ico_mall.small.traders {
  background-position: -200px -150px;
}
.ico_mall.small.boons {
  background-position: -200px -200px;
}
.ico_mall.small.ssg {
  background-position: -200px -250px;
}
.ico_mall.normal {
  display: inline-block;
  height: 21px;
  background-image: url(#{$imgPath}/common/ico_mall_v2.png);
  background-repeat: no-repeat;
  *zoom: 1;
  *display: inline;
  vertical-align: top;
}
.ico_mall.normal.emart {
  width: 77px;
  background-position: -480px 0;
}
.ico_mall.normal.ssgmall {
  width: 102px;
  background-position: -480px -40px;
}
.ico_mall.normal.depart {
  width: 79px;
  background-position: -480px -80px;
}
.ico_mall.normal.traders {
  width: 59px;
  background-position: -480px -120px;
}
.ico_mall.normal.boons {
  width: 52px;
  background-position: -480px -160px;
}
.ico_mall.normal.ssg {
  width: 64px;
  background-position: -480px -200px;
}
.ico_mall.normal.howdy {
  width: 54px;
  background-position: -480px -240px;
}
.ico_mall.normal.boots {
  width: 51px;
  background-position: -480px -280px;
}
.ico_mall.normal.tv {
  width: 82px;
  background-position: -480px -320px;
}
.ico_mall.normal.si {
  width: 86px;
  background-position: -480px -360px;
}
.ico_delivery.store {
  display: inline-block;
  width: 27px;
  height: 20px;
  background-image: url(#{$imgPath}/common/ico_mall_v2.png);
  background-repeat: no-repeat;
  *zoom: 1;
  *display: inline;
}
.ico_delivery.store.emart {
  background-position: -250px 0;
}
.ico_delivery.store.ssgmall {
  background-position: -250px -50px;
}
.ico_delivery.store.depart {
  background-position: -250px -100px;
}
.ico_delivery.store.traders {
  background-position: -250px -150px;
}
.ico_delivery.store.boons {
  background-position: -250px -200px;
}
.ico_delivery.parcel {
  display: inline-block;
  width: 28px;
  height: 20px;
  background-image: url(#{$imgPath}/common/ico_mall_v2.png);
  background-repeat: no-repeat;
  *zoom: 1;
  *display: inline;
}
.ico_delivery.parcel.emart {
  background-position: -350px 0;
}
.ico_delivery.parcel.ssgmall {
  background-position: -350px -50px;
}
.ico_delivery.parcel.depart {
  background-position: -350px -100px;
}
.ico_delivery.parcel.traders {
  background-position: -350px -150px;
}
.ico_delivery.parcel.boons {
  background-position: -350px -200px;
}
.ico_item {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  *zoom: 1;
  *display: inline;
  font-size: 0;
  line-height: 0;
}
.ico_item .ir {
  background-image: url(#{$imgPath}/common/ico_iteminfo.png);
  background-repeat: no-repeat;
}
.ico_item.normal.extra {
  width: 55px;
  height: 21px;
}
.ico_item.normal.cold {
  width: 31px;
  height: 21px;
}
.ico_item.normal.new {
  width: 34px;
  height: 21px;
}
.ico_item.normal.freezing {
  width: 31px;
  height: 21px;
}
.ico_item.normal.right {
  width: 33px;
  height: 21px;
}
.ico_item.normal.carryover {
  width: 33px;
  height: 21px;
}
.ico_item.normal.best {
  width: 34px;
  height: 21px;
}
.ico_item.normal.gangnam {
  width: 39px;
  height: 21px;
}
.ico_item.normal.bonjum {
  width: 31px;
  height: 21px;
}
.ico_item.normal.congceng {
  width: 39px;
  height: 21px;
}
.ico_item.normal.centem {
  width: 47px;
  height: 21px;
}
.ico_item.normal.genggi {
  width: 39px;
  height: 21px;
}
.ico_item.normal.yizengbu {
  width: 47px;
  height: 21px;
}
.ico_item.normal.younga {
  width: 53px;
  height: 21px;
}
.ico_item.normal.youngb {
  width: 53px;
  height: 21px;
}
.ico_item.normal.guangzu {
  width: 39px;
  height: 21px;
}
.ico_item.normal.masan {
  width: 39px;
  height: 21px;
}
.ico_item.normal.stylemarket {
  width: 55px;
  height: 21px;
}
.ico_item.normal.inchen {
  width: 39px;
  height: 21px;
}
.ico_item.normal.online {
  width: 55px;
  height: 21px;
}
.ico_item.normal.wrappingfee {
  width: 55px;
  height: 21px;
}
.ico_item.normal.organic {
  width: 39px;
  height: 21px;
}
.ico_item.normal.extra .ir {
  background-position: 0 0;
}
.ico_item.normal.cold .ir {
  background-position: 0 -30px;
}
.ico_item.normal.new .ir {
  background-position: 0 -60px;
}
.ico_item.normal.freezing .ir {
  background-position: 0 -90px;
}
.ico_item.normal.right .ir {
  background-position: 0 -120px;
}
.ico_item.normal.carryover .ir {
  background-position: 0 -150px;
}
.ico_item.normal.best .ir {
  background-position: 0 -180px;
}
.ico_item.normal.gangnam .ir {
  background-position: 0 -210px;
}
.ico_item.normal.bonjum .ir {
  background-position: 0 -240px;
}
.ico_item.normal.congceng .ir {
  background-position: 0 -270px;
}
.ico_item.normal.centem .ir {
  background-position: 0 -300px;
}
.ico_item.normal.genggi .ir {
  background-position: 0 -330px;
}
.ico_item.normal.yizengbu .ir {
  background-position: 0 -360px;
}
.ico_item.normal.younga .ir {
  background-position: 0 -390px;
}
.ico_item.normal.youngb .ir {
  background-position: 0 -420px;
}
.ico_item.normal.guangzu .ir {
  background-position: 0 -450px;
}
.ico_item.normal.masan .ir {
  background-position: 0 -480px;
}
.ico_item.normal.stylemarket .ir {
  background-position: 0 -510px;
}
.ico_item.normal.inchen .ir {
  background-position: 0 -540px;
}
.ico_item.normal.online .ir {
  background-position: 0 -570px;
}
.ico_item.normal.wrappingfee .ir {
  background-position: 0 -600px;
}
.ico_item.normal.organic .ir {
  background-position: 0 -630px;
}
.ico_item.normal.free,
.ico_item.normal.point,
.ico_item.normal.pay,
.ico_item.normal.plusone,
.ico_item.normal.gift,
.ico_item.normal.mmore,
.ico_item.normal.phone {
  width: 64px;
  height: 24px;
  vertical-align: middle;
}
.ico_item.normal.family_point {
  width: 71px;
  height: 24px;
  vertical-align: middle;
}
.ico_item.normal.staff_point {
  width: 74px;
  height: 24px;
  vertical-align: middle;
}
.ico_item.normal.ssgmoney {
  width: 94px;
  height: 24px;
  vertical-align: middle;
}
.ico_item.normal.ssgmoney .ir {
  background-position: -100px -298px;
}
.ico_item.normal.free .ir {
  background-position: -100px 0;
}
.ico_item.normal.point .ir {
  background-position: -100px -30px;
}
.ico_item.normal.plusone .ir {
  background-position: -100px -150px;
}
.ico_item.normal.family_point .ir {
  background-position: -100px -120px;
}
.ico_item.normal.staff_point .ir {
  background-position: -100px -90px;
}
.ico_item.normal.gift .ir {
  background-position: -100px -180px;
}
.ico_item.normal.gift2 {
  width: 64px;
  height: 24px;
  vertical-align: middle;
}
.ico_item.normal.gift2 .ir {
  background-position: -100px -180px;
}
.ico_item.normal.pay .ir {
  background-position: -100px -60px;
}
.ico_item.normal.mmore .ir {
  background-position: -100px -210px;
}
.ico_item.normal.phone .ir {
  background-position: -100px -240px;
}
.ico_rank.small {
  display: inline-block;
  width: 58px;
  height: 37px;
  background: url(#{$imgPath}/common/ranking_small.png) no-repeat;
  *zoom: 1;
  *display: inline;
}
.ico_rank.small.rank1 {
  background-position: 0 0;
}
.ico_rank.small.rank2 {
  background-position: -61px 0;
}
.ico_rank.small.rank3 {
  background-position: -122px 0;
}
.ico_rank.small.rank4 {
  background-position: -183px 0;
}
.ico_rank.small.rank5 {
  background-position: -244px 0;
}
.ico_rank.small.rank6 {
  background-position: -305px 0;
}
.ico_rank.small.rank7 {
  background-position: -366px 0;
}
.ico_rank.small.rank8 {
  background-position: -427px 0;
}
.ico_rank.small.rank9 {
  background-position: -488px 0;
}
.ico_rank.small.rank10 {
  background-position: -549px 0;
}
.ico_rank.small.rank11 {
  background-position: 0 -40px;
}
.ico_rank.small.rank12 {
  background-position: -61px -40px;
}
.ico_rank.small.rank13 {
  background-position: -122px -40px;
}
.ico_rank.small.rank14 {
  background-position: -183px -40px;
}
.ico_rank.small.rank15 {
  background-position: -244px -40px;
}
.ico_rank.small.rank16 {
  background-position: -305px -40px;
}
.ico_rank.small.rank17 {
  background-position: -366px -40px;
}
.ico_rank.small.rank18 {
  background-position: -427px -40px;
}
.ico_rank.small.rank19 {
  background-position: -488px -40px;
}
.ico_rank.small.rank20 {
  background-position: -549px -40px;
}
.ico_rank.small.rank21 {
  background-position: 0 -80px;
}
.ico_rank.small.rank22 {
  background-position: -61px -80px;
}
.ico_rank.small.rank23 {
  background-position: -122px -80px;
}
.ico_rank.small.rank24 {
  background-position: -183px -80px;
}
.ico_rank.small.rank25 {
  background-position: -244px -80px;
}
.ico_rank.small.rank26 {
  background-position: -305px -80px;
}
.ico_rank.small.rank27 {
  background-position: -366px -80px;
}
.ico_rank.small.rank28 {
  background-position: -427px -80px;
}
.ico_rank.small.rank29 {
  background-position: -488px -80px;
}
.ico_rank.small.rank30 {
  background-position: -549px -80px;
}
.ico_rank.small.rank31 {
  background-position: 0 -120px;
}
.ico_rank.small.rank32 {
  background-position: -61px -120px;
}
.ico_rank.small.rank33 {
  background-position: -122px -120px;
}
.ico_rank.small.rank34 {
  background-position: -183px -120px;
}
.ico_rank.small.rank35 {
  background-position: -244px -120px;
}
.ico_rank.small.rank36 {
  background-position: -305px -120px;
}
.ico_rank.small.rank37 {
  background-position: -366px -120px;
}
.ico_rank.small.rank38 {
  background-position: -427px -120px;
}
.ico_rank.small.rank39 {
  background-position: -488px -120px;
}
.ico_rank.small.rank40 {
  background-position: -549px -120px;
}
.ico_rank.small.rank41 {
  background-position: 0 -160px;
}
.ico_rank.small.rank42 {
  background-position: -61px -160px;
}
.ico_rank.small.rank43 {
  background-position: -122px -160px;
}
.ico_rank.small.rank44 {
  background-position: -183px -160px;
}
.ico_rank.small.rank45 {
  background-position: -244px -160px;
}
.ico_rank.small.rank46 {
  background-position: -305px -160px;
}
.ico_rank.small.rank47 {
  background-position: -366px -160px;
}
.ico_rank.small.rank48 {
  background-position: -427px -160px;
}
.ico_rank.small.rank49 {
  background-position: -488px -160px;
}
.ico_rank.small.rank50 {
  background-position: -549px -160px;
}
.ico_rank.small.rank51 {
  background-position: 0 -200px;
}
.ico_rank.small.rank52 {
  background-position: -61px -200px;
}
.ico_rank.small.rank53 {
  background-position: -122px -200px;
}
.ico_rank.small.rank54 {
  background-position: -183px -200px;
}
.ico_rank.small.rank55 {
  background-position: -244px -200px;
}
.ico_rank.small.rank56 {
  background-position: -305px -200px;
}
.ico_rank.small.rank57 {
  background-position: -366px -200px;
}
.ico_rank.small.rank58 {
  background-position: -427px -200px;
}
.ico_rank.small.rank59 {
  background-position: -488px -200px;
}
.ico_rank.small.rank60 {
  background-position: -549px -200px;
}
.ico_rank.small.rank61 {
  background-position: 0 -240px;
}
.ico_rank.small.rank62 {
  background-position: -61px -240px;
}
.ico_rank.small.rank63 {
  background-position: -122px -240px;
}
.ico_rank.small.rank64 {
  background-position: -183px -240px;
}
.ico_rank.small.rank65 {
  background-position: -244px -240px;
}
.ico_rank.small.rank66 {
  background-position: -305px -240px;
}
.ico_rank.small.rank67 {
  background-position: -366px -240px;
}
.ico_rank.small.rank68 {
  background-position: -427px -240px;
}
.ico_rank.small.rank69 {
  background-position: -488px -240px;
}
.ico_rank.small.rank70 {
  background-position: -549px -240px;
}
.ico_rank.small.rank71 {
  background-position: 0 -280px;
}
.ico_rank.small.rank72 {
  background-position: -61px -280px;
}
.ico_rank.small.rank73 {
  background-position: -122px -280px;
}
.ico_rank.small.rank74 {
  background-position: -183px -280px;
}
.ico_rank.small.rank75 {
  background-position: -244px -280px;
}
.ico_rank.small.rank76 {
  background-position: -305px -280px;
}
.ico_rank.small.rank77 {
  background-position: -366px -280px;
}
.ico_rank.small.rank78 {
  background-position: -427px -280px;
}
.ico_rank.small.rank79 {
  background-position: -488px -280px;
}
.ico_rank.small.rank80 {
  background-position: -549px -280px;
}
.ico_rank.small.rank81 {
  background-position: 0 -320px;
}
.ico_rank.small.rank82 {
  background-position: -61px -320px;
}
.ico_rank.small.rank83 {
  background-position: -122px -320px;
}
.ico_rank.small.rank84 {
  background-position: -183px -320px;
}
.ico_rank.small.rank85 {
  background-position: -244px -320px;
}
.ico_rank.small.rank86 {
  background-position: -305px -320px;
}
.ico_rank.small.rank87 {
  background-position: -366px -320px;
}
.ico_rank.small.rank88 {
  background-position: -427px -320px;
}
.ico_rank.small.rank89 {
  background-position: -488px -320px;
}
.ico_rank.small.rank90 {
  background-position: -549px -320px;
}
.ico_rank.small.rank91 {
  background-position: 0 -360px;
}
.ico_rank.small.rank92 {
  background-position: -61px -360px;
}
.ico_rank.small.rank93 {
  background-position: -122px -360px;
}
.ico_rank.small.rank94 {
  background-position: -183px -360px;
}
.ico_rank.small.rank95 {
  background-position: -244px -360px;
}
.ico_rank.small.rank96 {
  background-position: -305px -360px;
}
.ico_rank.small.rank97 {
  background-position: -366px -360px;
}
.ico_rank.small.rank98 {
  background-position: -427px -360px;
}
.ico_rank.small.rank99 {
  background-position: -488px -360px;
}
.ico_rank.small.rank100 {
  background-position: -549px -360px;
}
.ico_rank.small.rank_clip {
  display: inline-block;
  width: 55px;
  height: 32px;
  background-image: url(#{$imgPath}/common/ranking_clip.gif);
  background-repeat: no-repeat;
  *zoom: 1;
  *display: inline;
}
.ico_rank.large {
  display: inline-block;
  width: 51px;
  height: 51px;
  background-image: url(#{$imgPath}/@temp/rank.png);
  background-repeat: no-repeat;
}
.ico_rank.large.rank1 {
  width: 68px;
  height: 68px;
  background-position: 0 0;
}
.ico_rank.large.rank2 {
  background-position: 0 -100px;
}
.ico_rank.large.rank3 {
  background-position: 0 -200px;
}
.ico_rank.large.rank4 {
  background-position: 0 -300px;
}
.ico_rank.large.rank5 {
  background-position: 0 -400px;
}
.ico_rank.large.rank6 {
  background-position: 0 -500px;
}
.ico_rank.large.rank7 {
  background-position: 0 -600px;
}
.ico_rank.large.rank8 {
  background-position: 0 -700px;
}
.ico_rank.large.rank9 {
  background-position: 0 -800px;
}
.ico_rank.large.rank10 {
  background-position: 0 -900px;
}
.ico_rank.large.rank11 {
  background-position: 0 -1000px;
}

.ico_cold_storage {
  display: inline-block;
  width: 49px;
  height: 16px;
  background-image: url(#{$imgPath}/common/ico_cold_storage.gif);
  background-repeat: no-repeat;
  *zoom: 1;
  *display: inline;
}
.ico_event.small {
  display: inline-block;
  width: 42px;
  height: 26px;
  background: url(#{$imgPath}/common/bg_event_type.gif) no-repeat;
  *zoom: 1;
  *display: inline;
}
.ico_event.large {
  display: inline-block;
  width: 52px;
  height: 26px;
  background: url(#{$imgPath}/common/bg_event_type.gif) no-repeat;
  *zoom: 1;
  *display: inline;
}
.ico_event.small.save {
  background-position: 0 0;
}
.ico_event.small.card {
  background-position: 0 -30px;
}
.ico_event.small.coupon {
  background-position: 0 -60px;
}
.ico_event.small.present {
  background-position: 0 -90px;
}
.ico_event.small.prizes {
  background-position: 0 -120px;
}
.ico_event.large.buy_gift {
  background-position: 0 -150px;
}
.star_rating,
.star_rating span {
  display: inline-block;
  height: 14px;
  background-color: transparent;
  background-image: url(#{$imgPath}/common/bg_star_rating.png);
  background-repeat: no-repeat;
}
.star_rating {
  position: relative;
  vertical-align: middle;
  text-align: left;
  z-index: 1;
  zoom: 1;
}
.star_rating span {
  vertical-align: top;
}
.star_rating span span {
  display: inline;
  height: auto;
  background: none;
}
.star_rating.small {
  width: 50px;
  height: 10px;
  background-position: 0 0;
}
.star_rating.small span {
  height: 13px;
}
.star_rating.small.weighty span {
  background-position: 0 -20px;
}
.star_rating.small.warning span {
  background-position: 0 -40px;
}
.star_rating.small .num {
  position: absolute;
  top: 0;
  left: 120%;
  width: 50px;
  height: 100%;
}
.star_rating.medium {
  width: 61px;
  height: 14px;
  background-position: 0 -70px;
}
.star_rating.medium span {
  height: 14px;
}
.star_rating.medium.weighty span {
  background-position: 0 -90px;
}
.star_rating.medium.warning span {
  background-position: 0 -105px;
}
.star_rating.medium .num {
  position: absolute;
  top: 0;
  left: 120%;
  width: 50px;
  height: 100%;
}
.star_rating.large {
  width: 93px;
  height: 18px;
  background-position: 0 -120px;
}
.star_rating.large span {
  height: 18px;
}
.star_rating.large.weighty span {
  background-position: 0 -150px;
}
.star_rating.large .num {
  position: absolute;
  top: 0;
  left: 110%;
  width: 50px;
  height: 100%;
}
