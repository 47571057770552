.lp_bank .tbl_data thead th {
  background: #f2f2f2;
  border-left: 1px solid #d1d1d1;
}
.lp_bank .tbl_data thead th:first-child {
  border-left: 0;
}
.lp_bank .tbl_data thead th.fw_normal {
  font-weight: normal;
}
.lp_bank .tbl_data tbody th {
  background: #f9f9f9;
  padding: 12px 0 10px;
  text-align: center;
  font-weight: normal;
}
.lp_bank .tbl_data tbody td {
  font-size: 12px;
  border-left: 1px solid #e5e5e5;
  color: #666;
}
.lp_bank .tbl_data tbody td:first-child {
  border-left: 0;
}
.lp_bank .tbl_data tbody td a {
  text-decoration: none;
  color: #666;
}
.lp_bank .tbl_data tbody td a:hover {
  text-decoration: underline;
}
.lp_bank .tbl_data .bd_left {
  border-left: 1px solid #d1d1d1 !important;
}
