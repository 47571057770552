.card_guide_pop {
  margin-top: 20px;
}
.card_guide_pop .content {
  display: none;
}
.card_guide_pop .content.active {
  display: block;
}
.card_guide_pop p {
  letter-spacing: -1px;
  color: #949494;
}
.card_guide_pop p strong {
  font-weight: normal;
  color: #666;
}
.card_guide_pop .use_guide {
  margin-top: 20px;
}
.card_guide_pop .use_guide li {
  overflow: hidden;
  width: 580px;
}
.card_guide_pop .use_guide h3 {
  margin-bottom: 3px;
  padding: 0;
  font-size: 12px;
  color: #666;
  background: none;
  text-align: left;
  line-height: 1.75em;
}
.card_guide_pop .use_guide h3 .ir {
  position: static;
  display: inline;
  font-size: 16px;
  color: #272727;
  vertical-align: -1px;
  margin-right: 5px;
}
.card_guide_pop .use_guide p {
  margin-bottom: 15px;
  font-size: 11px;
  color: #a6a6a6;
  line-height: 17px;
  letter-spacing: -1px;
}
.card_guide_pop .use_guide h4.subject {
  margin-top: 15px;
  padding-left: 9px;
  background: url(#{$imgPath}/odr/bg_dot3.gif) no-repeat 0 8px;
  font-size: 12px;
  font-weight: normal;
  color: #666;
}
.card_guide_pop .use_guide .card_join01 .ir {
  position: static;
  overflow: hidden;
  width: 100%;
  height: 50px;
  margin: 5px 0 20px 0;
  background: #fff url(#{$imgPath}/odr/bg_card_guide03.gif) no-repeat 0 0;
}
.card_guide_pop .use_guide .card_join02 .ir {
  position: static;
  overflow: hidden;
  width: 100%;
  height: 50px;
  margin: 5px 0 20px 0;
  background: #fff url(#{$imgPath}/odr/bg_card_guide04.gif) no-repeat 0 0;
}
.card_guide_pop .use_guide .card_join03 .ir {
  position: static;
  overflow: hidden;
  width: 100%;
  height: 50px;
  margin: 5px 0 20px 0;
  background: #fff url(#{$imgPath}/odr/bg_card_guide05.gif) no-repeat 0 0;
}
.card_guide_pop .use_guide .card_join04 .ir {
  position: static;
  overflow: hidden;
  width: 100%;
  height: 50px;
  margin: 5px 0 20px 0;
  background: #fff url(#{$imgPath}/odr/bg_card_guide06.gif) no-repeat 0 0;
}
.card_guide_pop .use_guide .card_join05 .ir {
  position: static;
  overflow: hidden;
  width: 100%;
  height: 50px;
  margin: 5px 0 20px 0;
  background: #fff url(#{$imgPath}/odr/bg_card_guide07.gif) no-repeat 0 0;
}
.card_guide_pop .use_guide .card_join06 .ir {
  position: static;
  overflow: hidden;
  width: 100%;
  height: 115px;
  margin: 5px 0 20px 0;
  background: #fff url(#{$imgPath}/odr/bg_card_guide08.gif) no-repeat 0 0;
}
.card_guide_pop .use_guide .card_join07 .ir {
  position: static;
  overflow: hidden;
  width: 100%;
  height: 55px;
  margin: 5px 0 20px 0;
  background: #fff url(#{$imgPath}/odr/bg_card_guide09.gif) no-repeat 0 0;
}
.card_guide_pop .use_guide ol {
  visibility: hidden;
  overflow: hidden;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 0;
}
.card_guide_pop .use_guide .guide_sequence {
  margin-top: 15px;
}
.card_guide_pop .use_guide .guide_sequence .ir {
  position: static;
  overflow: hidden;
  width: 580px;
  height: 327px;
  background: url(#{$imgPath}/odr/bg_card_guide.gif) no-repeat;
}
.card_guide_pop .use_guide .guide_sequence02 {
  margin-top: 15px;
}
.card_guide_pop .use_guide .guide_sequence02 ul {
  visibility: hidden;
  overflow: hidden;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 0;
}
.card_guide_pop .use_guide .guide_sequence02 .ir {
  position: static;
  overflow: hidden;
  width: 580px;
  height: 327px;
  background: url(#{$imgPath}/odr/bg_card_guide02.gif) no-repeat;
}
.card_guide_pop .use_guide .data_table {
  margin: 15px 0 35px 0;
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
}
.card_guide_pop .use_guide .data_table thead th {
  height: auto;
  color: #272727;
  text-align: center;
  border-right: none;
  border-left: none;
}
.card_guide_pop .use_guide .data_table tbody td {
  height: auto;
  padding-left: 21px;
  border-right: none;
  border-left: none;
  text-align: left;
  color: #a1a1a1;
}
.card_guide_pop .use_guide .data_table tbody td:first-child {
  border-right: none;
  color: #272727;
}
.card_guide_pop .use_guide .card_txt {
  padding: 10px 0 20px 20px;
}
.card_guide_pop .use_guide .card_txt .data_list {
  margin-bottom: 10px;
}
.card_guide_pop .use_guide .card_txt .data_list li {
  font-size: 11px;
}
.card_guide_pop .use_guide .card_txt .data_list span {
  display: inline-block;
  width: 213px;
}
.card_guide_pop .use_guide .card_txt .data_list span a {
  color: #a6a6a6;
  text-decoration: none;
  display: inline-block;
  margin: -8px 0;
}

.lp_individual_selection .tbl_data_scroll .table_scroll {
  height: 190px;
}
.lp_individual_selection .tbl_data_scroll .table_scroll table tr td {
  font-size: 12px;
}
.lp_individual_selection .tbl_data_scroll .table_scroll table tr td.align_left {
  padding-left: 15px;
  color: #222;
}
.lp_individual_selection .tbl_data_scroll .table_scroll table tr td label {
  display: inline-block;
  margin-left: 10px;
  color: #888;
}
.lp_individual_selection .tbl_data_scroll .table_scroll table tr td label:first-child {
  margin-left: 0;
}
.lp_individual_selection .tbl_data_scroll .table_scroll table tr td label strong {
  color: #222;
}
