/* 상품레이아웃 - common */
.bn_osmu {
  margin: 0 auto;
  *zoom: 1;
}
.bn_osmu:after {
  display: block;
  content: '';
  clear: both;
}
.bn_osmu .top_style,
.bn_osmu .box_style {
  position: relative;
  float: left;
  width: 255px;
  height: 510px;
}
.bn_osmu .link_osmu {
  display: block;
  height: 100%;
  cursor: pointer;
}
.bn_osmu .sp_badge,
.bn_osmu .sp_btn {
  background: url(#{$imgPath}/common/sp_patten_img.png) no-repeat 0 0;
}
.bn_osmu .bn_txt .bn_txt_inner span,
.bn_osmu .bn_txt .bn_txt_inner span em {
  display: block;
}
.bn_osmu .sp_badge {
  position: absolute;
  z-index: 30;
  width: 69px;
  height: 69px;
  text-align: center;
}
.bn_osmu .sp_badge.fl_lf {
  left: 14px;
  top: 14px;
}
.bn_osmu .sp_badge.fl_rt {
  right: 14px;
  top: 14px;
}
.bn_osmu .sp_badge.fl_bm {
  right: 14px;
  bottom: 14px;
}
.bn_osmu .sp_badge .bn_coupun {
  display: inline-block;
  width: 29px;
  height: 21px;
  margin-top: 20px;
  padding: 0 14px 12px 10px;
  background: url(#{$imgPath}/common/sp_patten_img.png) 0 -2430px no-repeat;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -1px;
  color: #373737;
}
.bn_osmu .sp_badge .bn_coupun em {
  *position: relative;
  *top: -4px;
}
.bn_osmu .sp_badge .bn_more {
  display: inline-block;
  width: 33px;
  height: 17px;
  margin-top: 24px;
  background: url(#{$imgPath}/common/sp_patten_img.png) 0 -2470px no-repeat;
}
.bn_osmu .sp_badge .bn_gift {
  display: inline-block;
  width: 55px;
  height: 15px;
  margin-top: 25px;
  background: url(#{$imgPath}/common/sp_patten_img.png) -53px -2470px no-repeat;
}
.bn_osmu .bn_txt {
  z-index: 20;
  width: 255px;
  height: 255px;
  font-family: $fontR;
  font-weight: 400;
}
.bn_osmu .bn_txt .bn_txt_inner {
  padding: 59px 0 0 30px;
}
.bn_osmu .bn_txt .h1 {
  font-size: 25px;
  line-height: 32px;
  letter-spacing: -1px;
}
.bn_osmu .bn_txt .h2 {
  margin-top: 8px;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -1px;
}
.bn_osmu .bn_txt .btn_go {
  display: block;
  width: 108px;
  height: 13px;
  margin-top: 25px;
  background: url(#{$imgPath}/common/sp_patten_img.png) -1591px -402px no-repeat;
}
.bn_osmu .bn_img {
  display: block;
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.bn_osmu .bn_img .sp_btn {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
/* 상품레이아웃 - top_style */
.bn_osmu .top_style .link_osmu {
  color: #535353;
  text-decoration: none;
}
.bn_osmu .top_style .sp_badge.fl_lf {
  left: auto;
  right: 14px;
  top: 14px;
}
.bn_osmu .top_style .sp_badge.bd_st1 {
  background-position: -1312px -8px;
}
.bn_osmu .top_style .sp_badge.bd_st2 {
  background-position: -1312px -88px;
}
.bn_osmu .top_style .sp_badge.bd_st3 {
  background-position: -1312px -168px;
}
.bn_osmu .top_style .sp_badge.bd_st4 {
  background-position: -1312px -250px;
}
.bn_osmu .top_style .sp_badge.bd_st5 {
  background-position: -1312px -329px;
}
.bn_osmu .top_style .sp_badge.bd_st1.fl_bm {
  background-position: -1403px -8px;
}
.bn_osmu .top_style .sp_badge.bd_st2.fl_bm {
  background-position: -1403px -88px;
}
.bn_osmu .top_style .sp_badge.bd_st3.fl_bm {
  background-position: -1403px -168px;
}
.bn_osmu .top_style .sp_badge.bd_st4.fl_bm {
  background-position: -1403px -250px;
}
.bn_osmu .top_style .sp_badge.bd_st5.fl_bm {
  background-position: -1403px -329px;
}
.bn_osmu .top_style .bn_txt .ico_patten {
  position: relative;
  left: 4px;
  width: 40px;
  height: 13px;
  margin-bottom: 21px;
  background: url(#{$imgPath}/common/sp_patten_img.png) -1659px -1px no-repeat;
  font-size: 0;
}
.bn_osmu .top_style .bn_txt .btn_go {
  display: none;
}
.bn_osmu .top_style .bn_img .sp_btn.bn_pttn1,
.category_all .box_style .bn_img .sp_btn.bn_pttn1 {
  background-position: 0 0;
}
.bn_osmu .top_style .bn_img .sp_btn.bn_pttn2,
.category_all .box_style .bn_img .sp_btn.bn_pttn2 {
  background-position: -265px 0;
}
.bn_osmu .top_style .bn_img .sp_btn.bn_pttn3,
.category_all .box_style .bn_img .sp_btn.bn_pttn3 {
  background-position: 0 -264px;
}
.bn_osmu .top_style .bn_img .sp_btn.bn_pttn4,
.category_all .box_style .bn_img .sp_btn.bn_pttn4 {
  background-position: -265px -264px;
}
.bn_osmu .top_style .bn_img .sp_btn.bn_pttn5,
.category_all .box_style .bn_img .sp_btn.bn_pttn5 {
  background-position: -530px -264px;
}
.bn_osmu .top_style .bn_img .sp_btn.bn_pttn6,
.category_all .box_style .bn_img .sp_btn.bn_pttn6 {
  background-position: 0 -530px;
}
.bn_osmu .top_style .bn_img .sp_btn.bn_pttn7,
.category_all .box_style .bn_img .sp_btn.bn_pttn7 {
  background-position: -265px -530px;
}
.bn_osmu .top_style .bn_img .sp_btn.bn_pttn8,
.category_all .box_style .bn_img .sp_btn.bn_pttn8 {
  background-position: -530px -530px;
}
.bn_osmu .top_style .bn_img .sp_btn.bn_pttn9,
.category_all .box_style .bn_img .sp_btn.bn_pttn9 {
  background-position: 0 -795px;
}
.bn_osmu .top_style .bn_img .sp_btn.bn_pttn10,
.category_all .box_style .bn_img .sp_btn.bn_pttn10 {
  background-position: -265px -795px;
}
.bn_osmu .top_style .bn_img .sp_btn.bn_pttn11,
.category_all .box_style .bn_img .sp_btn.bn_pttn11 {
  background-position: -530px -795px;
}
/* 상품레이아웃 - box_style */
.bn_osmu .box_style .link_osmu {
  color: #fff;
}
.bn_osmu .box_style .sp_badge.fl_bm {
  right: 14px;
  top: 172px;
  bottom: auto;
}
.bn_osmu .box_style .bn_img .sp_btn {
  background-position: -530px 0;
}
.bn_osmu .box_style .sp_badge.bd_st1 {
  background-position: -1403px -8px;
}
.bn_osmu .box_style .sp_badge.bd_st2 {
  background-position: -1403px -88px;
}
.bn_osmu .box_style .sp_badge.bd_st3 {
  background-position: -1403px -168px;
}
.bn_osmu .box_style .sp_badge.bd_st4 {
  background-position: -1403px -250px;
}
.bn_osmu .box_style .sp_badge.bd_st5 {
  background-position: -1403px -329px;
}
.bn_osmu .box_style .bn_txt {
  position: absolute;
  bottom: 0;
  padding-top: 12px;
  background: url(#{$imgPath}/common/sp_patten_img.png) no-repeat;
}
.bn_osmu .box_style .bn_txt.fl_lt {
  left: 0;
}
.bn_osmu .box_style .bn_txt.fl_rt {
  right: 0;
}
.bn_osmu .box_style .st_high.bg1 .bn_txt {
  background-position: 0 -1065px;
}
.bn_osmu .box_style .st_high.bg2 .bn_txt {
  background-position: -258px -1065px;
}
.bn_osmu .box_style .st_high.bg3 .bn_txt {
  background-position: -516px -1065px;
}
.bn_osmu .box_style .st_high.bg4 .bn_txt {
  background-position: -774px -1065px;
}
.bn_osmu .box_style .st_high.bg5 .bn_txt {
  background-position: -1032px -1065px;
}
.bn_osmu .box_style .st_high.bg6 .bn_txt {
  background-position: -1291px -1065px;
}
.bn_osmu .box_style .st_high.bg7 .bn_txt {
  background-position: 0 -1336px;
}
.bn_osmu .box_style .st_low.bg1 .bn_txt {
  background-position: -258px -1336px;
}
.bn_osmu .box_style .st_low.bg2 .bn_txt {
  background-position: -516px -1336px;
}
.bn_osmu .box_style .st_low.bg3 .bn_txt {
  background-position: -774px -1336px;
}
.bn_osmu .box_style .st_low.bg4 .bn_txt {
  background-position: -1032px -1336px;
}
.bn_osmu .box_style .st_low.bg5 .bn_txt {
  background-position: -1291px -1336px;
}
.bn_osmu .box_style .st_low.bg6 .bn_txt {
  background-position: 0 -1605px;
}
.bn_osmu .box_style .st_low.bg7 .bn_txt {
  background-position: -258px -1605px;
}
.bn_osmu .box_style .st_low.bg8 .bn_txt {
  background-position: -516px -1605px;
}
.bn_osmu .box_style .st_low.bg9 .bn_txt {
  background-position: -774px -1605px;
}
/* 상품레이아웃 - box_style(w510) */
.bn_osmu .box_style.w510 {
  width: 510px;
}
.bn_osmu .box_style.w510 .st_high .bn_img .sp_btn {
  background-position: -793px -8px;
}
.bn_osmu .box_style.w510 .st_high .sp_badge {
  background-position: -1489px -8px;
}
.bn_osmu .box_style.w510 .st_high .sp_badge.bd_st1 {
  background-position: -1489px -8px;
}
.bn_osmu .box_style.w510 .st_high .sp_badge.bd_st2 {
  background-position: -1489px -88px;
}
.bn_osmu .box_style.w510 .st_high .sp_badge.bd_st3 {
  background-position: -1489px -168px;
}
.bn_osmu .box_style.w510 .st_high .sp_badge.bd_st4 {
  background-position: -1489px -250px;
}
.bn_osmu .box_style.w510 .st_high .sp_badge.bd_st5 {
  background-position: -1489px -329px;
}
.bn_osmu .box_style.w510 .st_low .bn_img .sp_btn {
  background-position: -793px -530px;
}
.bn_osmu .box_style.w510 .st_low .sp_badge {
  background-position: -1570px -8px;
}
.bn_osmu .box_style.w510 .st_low .sp_badge.bd_st1 {
  background-position: -1570px -8px;
}
.bn_osmu .box_style.w510 .st_low .sp_badge.bd_st2 {
  background-position: -1570px -88px;
}
.bn_osmu .box_style.w510 .st_low .sp_badge.bd_st3 {
  background-position: -1570px -168px;
}
.bn_osmu .box_style.w510 .st_low .sp_badge.bd_st4 {
  background-position: -1570px -250px;
}
.bn_osmu .box_style.w510 .st_low .sp_badge.bd_st5 {
  background-position: -1570px -329px;
}
.bn_osmu a.link_osmu .bn_img img {
  vertical-align: top;
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transition: All 1s linear;
  -webkit-transition: All 1s linear;
  -ms-transition: All 0.5s linear;
}
.bn_osmu a.link_osmu:hover .bn_img img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transition: All 2s linear;
  -webkit-transition: All 2s linear;
  -ms-transition: All 0.5s linear;
}
