.coupon_down {
  overflow: hidden;
  width: 799px;
  margin: 46px auto 50px;
}
.coupon_down .cp_area {
  margin-top: 30px;
}
.coupon_down .cp_area:first-child {
  position: relative;
  margin-top: 0;
}
.coupon_down h3 strong {
  font-size: 15px;
  line-height: 15px;
  color: #666;
}
.coupon_down h3 strong em {
  color: #fc3d72;
}
.coupon_down h3 img {
  vertical-align: -2px;
}
.coupon_down h3.stit {
  padding-top: 3px;
  margin-bottom: 14px;
  overflow: hidden;
}
.coupon_down .month_coupon {
  overflow: hidden;
  width: 800px;
  margin: 14px 0 0 -1px;
  padding-top: 30px;
  border-top: 1px solid #d3d3d3;
  text-align: center;
}
.coupon_down .month_coupon li {
  position: relative;
  float: left;
  width: 265px;
  padding: 15px 0;
  border-left: 1px dotted #d6d6d2;
}
.coupon_down .month_coupon li .coupon_wrapping {
  width: 195px;
  padding: 0 45px 0 28px;
}
.coupon_down .month_coupon li .coupon-list {
  display: inline-block;
  margin: 0 0 10px -14px;
  zoom: 1;
}
.coupon_down .month_coupon li .coupon-list li {
  width: 216px;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-family: $fontR;
  font-weight: 400;
  font-size: 12px;
  color: #b3b7b9;
}
.coupon_down .month_coupon .coupon_ticket {
  position: relative;
  width: 186px;
  height: 105px;
  margin: 0 auto 10px auto;
  padding: 0 51px 0px 0;
  background: url(#{$imgPath}/event/bg_sale01.gif) no-repeat 0 0;
}
.coupon_down .month_coupon .coupon_ticket .em {
  display: inline-block;
  width: 63px;
  margin: 17px 0 -8px 4px;
  font-size: 65px;
  color: #f3708c;
  font-weight: bold;
  font-style: Italic;
  line-height: 70px;
  *line-height: 80px;
  letter-spacing: -5px;
  text-align: center;
}
.coupon_down .month_coupon .coupon_ticket .coupon_img {
  position: absolute;
  bottom: 20px;
  left: 60px;
}
.coupon_down .month_coupon li .coupon_wrapping.sale_money .coupon_ticket {
  background: url(#{$imgPath}/event/bg_sale02.gif) no-repeat 0 0;
}
.coupon_down .month_coupon li .coupon_wrapping.sale_money .coupon_ticket .em {
  width: 162px;
  margin: 29px 0 0 -25px;
  font-size: 45px;
  line-height: 50px;
  *line-height: 58px;
  letter-spacing: -5px;
  color: #5cc6ed;
}
.coupon_down .month_coupon li .coupon_wrapping.free .coupon_ticket {
  background: url(#{$imgPath}/event/bg_free.gif) no-repeat 0 0;
}
.coupon_down .month_coupon li .icon_expiration {
  position: absolute;
  left: 0;
  top: -2px;
  width: 57px;
  height: 57px;
  background: url(#{$imgPath}/csc/bg_expiration.png) no-repeat;
  font-size: 0;
  line-height: 0;
}
.coupon_down .btn_down a {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 108px;
  padding-right: 10px;
  height: 25px;
  line-height: 25px;
  color: #666;
  border: 2px solid #c6c6c6;
  text-decoration: none;
  text-align: center;
  background: url(#{$imgPath}/mem/btn_down_arrow.png) 88px 4px no-repeat;
}
.coupon_down .button_area.large {
  margin: -21px 0 -7px;
}
.coupon_down .btn_down_link {
  position: absolute;
  top: 0;
  right: 0;
  background: url(#{$imgPath}/mem/bg_coupon_down.gif) no-repeat;
  width: 151px;
  height: 29px;
}
.coupon_down .btn_down_link a {
  display: block;
  padding-left: 10px;
  line-height: 29px;
  color: #666;
  text-decoration: none;
  letter-spacing: -1px;
}
.gift_card {
  margin: 22px 22px 70px;
  font-size: 14px;
  font-family: $fontR;
  font-weight: 400;
  color: #2e2e2e;
}
.gift_card .tx_point {
  color: get-color('primary');
}
.gift_card .gift_card_lst li {
  position: relative;
  margin-top: 14px;
  padding-left: 15px;
  line-height: 1.57;
}
.gift_card .gift_card_lst li:first-child {
  margin-top: 0;
}
.gift_card .gift_card_lst li .lst_num {
  position: absolute;
  top: 0;
  left: 0;
}
.gift_card .gift_card_img {
  margin-top: 50px;
  text-align: center;
}
.gift_card .gift_card_img img {
  vertical-align: top;
}
.regular_shipping {
  position: relative;
  margin-top: 20px;
}
.regular_shipping .data_table {
  width: 100%;
  border-top: 2px solid #000;
}
.regular_shipping .data_table table th {
  padding: 0;
  border-bottom: 1px solid #dee3e6;
  background: #ecf1f5;
}
.regular_shipping .data_table table td {
  padding: 26px 0 24px 20px;
  border-bottom: 1px solid #efefef;
  color: #666;
  font-size: 12px;
  line-height: 24px;
}
.regular_shipping .data_table table td.setting_go {
  padding: 0;
  border-left: 1px solid #efefef;
  text-align: center;
  vertical-align: middle;
}
.regular_shipping .data_table table td.setting_go a {
  display: inline-block;
  padding-top: 90px;
  background: url(#{$imgPath}/common/bg_gear.gif) no-repeat 0 0;
  color: #666;
  font-size: 11px;
  text-decoration: underline;
}
.regular_shipping .data_list {
  margin-top: 15px;
}
.regular_shipping .data_list.medium li {
  padding-left: 20px;
  background: none;
  color: #999;
  line-height: 16px;
  margin-top: 0;
}
.regular_shipping .view_item_list {
  margin-top: 35px;
}
.view_item_list.num ul li {
  padding: 37px 40px 47px;
  margin-top: 30px;
}
.view_item_list ul li .info_top {
  top: -20px;
}
.view_item_list .btn.go {
  position: absolute;
  top: -6px;
  right: 0;
  display: inline-block;
  height: 16px;
  padding: 2px 15px 0 4px;
  border: 1px solid #d8d8d8;
  background: #fcfcfc url(#{$imgPath}/common/bg_btn_arr01.gif) 115px 7px no-repeat;
  line-height: 17px;
  font-size: 11px;
  color: #666;
  letter-spacing: -0.1em;
  text-align: left;
  vertical-align: middle;
  text-align: left;
}
.recently_view {
  overflow-y: scroll;
  margin: 50px 0 50px;
  width: 900px;
  overflow-x: hidden;
  height: 596px;
}
.plz_co {
  overflow-y: scroll;
  height: 495px;
  margin: 50px 0 50px 50px;
}
.util_plsc.active .ch_box,
.util_plsc.lst_over .ch_box {
  background-color: #f9f9f9;
}
.util_plsc.active {
  border-color: #79c1cf;
}
.util_plsc.lst_over {
  border-color: #97cdcd;
}
.util_plsc.active.lst_over {
  border-color: #79c1cf;
}
.util_plsc.active .in_shadow,
.util_plsc.lst_over .in_shadow {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 6px;
  background: url(#{$imgPath}/mem/bg_chb_plsc_shadow.png) repeat-x 0 0;
}
.util_plsc.lst_over .arrow {
  background-position: -30px -54px;
}
.util_plsc.active .arrow {
  background-position: -60px -54px;
}
.util_plsc.active.lst_over .arrow {
  background-position: -60px -54px;
}
.util_plsc {
  position: relative;
  margin-top: 33px;
  padding: 4px 31px 4px 4px;
  border: 2px solid #bcbcbc;
  background: #fff;
  line-height: 16px;
  zoom: 1;
}
.util_plsc.wrap {
  padding: 4px;
}
.util_plsc textarea {
  overflow: auto;
  position: relative;
  z-index: 20;
  width: 100%;
  height: 16px;
  *margin: -1px 0;
  border: 0 none;
  background: transparent;
  font-size: 12px;
  color: #777;
  line-height: 16px;
  letter-spacing: -1px;
  vertical-align: top;
  resize: none;
}
.util_plsc .comment_txt {
  display: inline-block;
  position: absolute;
  left: 5px;
  top: 4px;
  padding-left: 22px;
  z-index: 10;
  font-size: 11px;
  background: url(#{$imgPath}/common/ico_label.png) no-repeat 0 0;
  color: #999;
  vertical-align: top;
  cursor: text;
}
.util_plsc .in_shadow {
  display: none;
}
.util_plsc .arrow {
  position: absolute;
  left: 14px;
  bottom: 100%;
  width: 27px;
  height: 16px;
  background-position: 0 -54px;
}
.util_plsc .pic_bor {
  display: inline-block;
  position: absolute;
  bottom: 4px;
  right: 0;
  z-index: 20;
  width: 31px;
  height: 16px;
  margin-top: -8px;
  text-align: center;
}
.util_plsc .pic_bor .bor {
  display: inline-block;
  overflow: hidden;
  position: absolute;
  right: 100%;
  top: 0;
  bottom: 0;
  width: 1px;
  height: 1px;
  background: #cecece;
}
.util_plsc .pic_area {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 16px;
  vertical-align: top;
}
.util_plsc .pic_area a.camera {
  width: 18px;
  height: 16px;
  background-position: 0 -36px;
}
.util_plsc .pic_area a.camera:hover {
  background-position: -20px -36px;
}
.util_plsc .pic_area.yes_f a.camera {
  background-position: -82px -84px;
}
.util_plsc.active {
  padding: 0;
}
.util_plsc.active .comment_txt {
  display: none;
}
.util_plsc .util_plsbt a {
  display: inline-block;
  min-width: 38px;
  height: 23px;
  margin-left: 1px;
  padding: 2px 0 0;
  border-radius: 0;
  border: 0;
  background: #c4c4c4;
  font-size: 11px;
  font-weight: bold;
  line-height: 26px;
  color: #fff;
  letter-spacing: -1px;
  vertical-align: top;
  text-align: center;
  text-decoration: none;
}
.util_plsc .util_plsbt a:hover {
  background: #8e8e8e;
}
.util_plsc .util_plsbt a.btn_type2 {
  background: #97cece;
}
.util_plsc .util_plsbt a.btn_type2:hover {
  background-color: #58adbc;
}
.util_plsbt {
  width: 100%;
  margin-top: 10px;
  line-height: 20px;
}
.util_plsbt:after {
  display: block;
  clear: both;
  content: '';
}
.util_plsbt .byte {
  display: inline;
  float: left;
  font-size: 11px;
  color: #747474;
}
.util_plsbt .util_pbr {
  display: inline;
  float: right;
}
.util_plsbt .util_pbr a {
  display: inline-block;
  min-width: 56px;
  height: 16px;
  margin-left: 3px;
  padding: 2px 0 0;
  border: 1px solid #ccc;
  border-radius: 2px;
  background: #eee;
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
  color: #666;
  letter-spacing: -1px;
  vertical-align: top;
  text-align: center;
  text-decoration: none;
}
.util_plsbt .util_pbr a.btn_type2 {
  border-color: #3f91a0;
  background-color: #5aadbc;
  color: #fff;
}
.util_plsbt .chb_pbr {
  display: inline;
  float: right;
}
.pic_area .num_wrap {
  display: none;
  position: absolute;
  right: -18px;
  top: 0;
  padding: 0 6px;
  vertical-align: top;
}
.pic_area.yes_f .num_wrap {
  display: block;
}
.pic_area .num_wrap .nw_c {
  display: block;
  width: 4px;
  height: 16px;
  background: url(#{$imgPath}/mem/sp_bg_picnum.png) repeat-x 0 -20px;
  vertical-align: top;
}
.pic_area .num_wrap .tx {
  display: inline-block;
  float: left;
  position: relative;
  z-index: 10;
  margin: 1px -2px 0 -2px;
  padding-top: 2px;
  font-size: 11px;
  font-weight: bold;
  line-height: 11px;
  color: #fff;
  vertical-align: top;
}
.pic_area .num_wrap .nw_l {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  width: 7px;
  height: 16px;
  background: url(#{$imgPath}/mem/sp_bg_picnum.png) no-repeat 0 0;
  vertical-align: top;
}
.pic_area .num_wrap .nw_r {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  width: 7px;
  height: 16px;
  background: url(#{$imgPath}/mem/sp_bg_picnum.png) no-repeat 100% -40px;
  vertical-align: top;
}
.plzComm_buy_write .pic_area a.camera {
  width: 18px;
  height: 16px;
  background-position: 0 -36px;
}
.plzComm_buy_write .pic_area a.camera:hover {
  background-position: -20px -36px;
}
.plzComm_buy_write .pic_area.yes_f a.camera,
.plzComm_buy_write .pic_area.yes_f a.camera:hover {
  background-position: -82px -84px;
}
.comment_wrap {
  border-top: 1px dotted #c9c9c9;
  margin-top: 11px;
}
.comment_wrap .cl_box .cl_tbl {
  width: 195px;
}

.service_bn {
  overflow-y: scroll;
  margin: 50px 0 50px 70px;
  width: 830px;
  overflow-x: hidden;
  height: 592px;
}
.service_bn ul {
  width: 762px;
  margin: 1px;
}
.service_bn ul li {
  border: 1px solid #d8d8d8;
  margin-left: -1px;
  margin-top: -1px;
}
.service_bn .view_item_list {
  margin-top: 30px;
}
.recently_exhibition ul li a {
  display: inline-block;
  position: relative;
  width: 253px;
  font-size: 0;
  line-height: 0;
}
.recently_exhibition ul li a.event_txt {
  width: 253px;
  height: 104px;
  padding-top: 42px;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  background-color: #fafafa;
}
.recently_exhibition ul li a.event_txt span {
  display: block;
  padding: 15px 10px 0;
  line-height: 18px;
  color: #747474;
}
.service_bn .no_data {
  position: relative;
  width: 253px;
  height: 146px;
}
.service_bn .no_data .blank {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
  width: 0;
  height: 146px;
  overflow: hidden;
}
.service_bn .no_data .cont {
  display: inline-block;
  width: 220px;
  vertical-align: middle;
  *display: inline;
  *zoom: 1;
  text-align: center;
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
  color: #222;
}
.service_bn .no_data a {
  display: block;
  width: 253px;
  height: 146px;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.view_item_list .btn.more span {
  padding-right: 12px;
  background: url(#{$imgPath}/common/bg_arrow3.gif) no-repeat 100% 16px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}
.regular_shipping_area {
  margin: 30px;
}
.regular_shipping .data_list.medium li {
  padding-left: 20px;
  background: none;
  color: #999;
  line-height: 20px;
  font-size: 12px;
}
.view_item_list {
  position: relative;
  width: 840px;
  margin: 0 auto;
}
.view_item_list.num {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}
.view_item_list ul li {
  position: relative;
  float: left;
  width: 140px;
  padding: 20px 34px 30px;
  border: 1px solid transparent;
}
.view_item_list ul li.lst_over {
  border: 1px solid black;
  z-index: 20;
}
.view_item_list.num ul li {
  padding: 20px 34px 20px;
}
.view_item_list .itembx .cart_btn {
  margin-top: 5px;
}
.view_item_list .itembx .cart_btn a {
  display: block;
  width: 134px;
  height: 26px;
  border: 2px solid #c6c6c6;
  text-align: center;
  line-height: 26px;
  color: #666;
  letter-spacing: -1px;
}
.recently_exhibition .view_item_list {
  width: 762px;
  margin: 30px 0 0;
}
.scroll_item_list {
  overflow-y: scroll;
  width: 840px;
  overflow-x: hidden;
  height: 317px;
}
.cs_btn {
  display: inline-block;
}
.cs_btn.large {
  min-width: 96px;
  padding: 11px 16px;
  text-align: center;
  font-size: 16px;
  font-family: $fontR;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: -1px;
  line-height: 18px;
}
.cs_btn.large.white {
  background: #f9f9f9;
  border: 1px solid #ccc;
  color: #222;
}
