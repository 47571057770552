/* 퀵뷰 동영상버튼 추가 */
.detail_product .item_image .viewport li .btn_qck_mov {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 89px;
  height: 89px;
  margin: -45px 0 0 -45px;
  background: url(#{$imgPath}/common/btn_movplay_b.png) no-repeat;
}
#videoView,
#qvVideoView {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 450px;
  text-align: center;
  line-height: 1.5em;
  z-index: -1;
}
#videoBox,
#qvVideoBox {
  display: inline-block;
  width: 450px;
  height: 450px;
}
