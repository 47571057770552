.pop_simple .tbl_data tr th {
  height: 42px;
}
.pop_simple .summary {
  margin-bottom: 5px;
  color: #222;
}
.pop_simple .table_scroll {
  height: 204px;
}
.pop_simple .table_scroll table tbody tr:first-child td {
  font-weight: normal;
}
.pop_simple .tbl_data tbody tr td,
.pop_simple .table_scroll table tbody tr td {
  font-size: 12px;
  color: #272727;
}
.pop_simple .tbl_data tbody tr td div,
.pop_simple .table_scroll table tbody tr td div {
  padding-left: 10px;
  color: #272727;
  font-size: 12px;
  text-align: left;
}
.pop_simple .tbl_data_scroll tr.heading td {
  background: #f9f9f9;
}
.pop_simple .tbl_data_scroll .table_scroll table tr th {
  border: 0;
}
.pop_change_dl {
  border-top: 1px solid #666;
  border-bottom: 1px solid #e9e9e9;
}
.pop_change_dl dt {
  border-bottom: 1px solid #e9e9e9;
  color: #222;
  padding: 12px 8px 11px;
}
.pop_change_dl dd {
  color: #222;
  padding: 12px 0 11px;
}
.pop_change_dl dd li {
  background: url(#{$imgPath}/common/bullet_square_01.gif) no-repeat 0 7px;
  padding-left: 8px;
}
.pop_change_dl dd li a {
  color: #f96a62;
  font-weight: bold;
}
.pop_change_inp {
  padding-top: 15px;
}
.pop_change_inp:after {
  content: '';
  display: block;
  clear: both;
}
.pop_change_inp dt {
  float: left;
  width: 75px;
  text-align: center;
  line-height: 0;
  padding-top: 18px;
}
.pop_change_inp dd {
  float: left;
  width: 367px;
}
.pop_change_inp dd textarea {
  border: 1px solid #b2b2b2;
  color: #888;
  font-family: $fontR;
  font-weight: 400;
  font-size: 12px;
  padding: 8px;
  line-height: 16px;
  width: 349px;
  height: 33px;
  resize: none;
  margin-bottom: 10px;
  display: block;
}
.pop_change_inp dd .pop_btn_area {
  text-align: left;
  padding: 0;
}
.pop_change_inp dd .pop_btn_area a:first-child {
  margin-left: 0;
}
.pop_change_inp dd .pop_btn_area a {
  min-width: 130px;
}
.pop_change_inp dd .pop_alert {
  margin-top: 10px;
  width: 294px;
}
.pop_change_inp dd .pop_alert:after {
  content: '';
  display: block;
  clear: both;
}
.pop_change_inp dd .pop_alert .chk_p {
  position: relative;
  width: 13px;
  height: 13px;
  margin: -1px 0 2px -20px;
  margin: -1px 0 1px -20px\9;
  padding: 0;
  vertical-align: middle;
}
.pop_change_inp dd .pop_alert label {
  float: left;
  width: 200px;
  color: #222;
  text-indent: 20px;
  line-height: 16px;
}
.pop_change_inp dd .pop_alert label span {
  display: block;
  text-align: left;
  text-indent: 18px;
}
.pop_change_inp dd .pop_alert .btn_user_edit {
  float: right;
}
.pop_change_desc {
  margin-top: 27px;
}
.pop_change_desc dt {
  border-bottom: 1px solid #d1d1d1;
  color: #222;
  font-weight: bold;
  background: url(#{$imgPath}/common/ico_notice02.gif) 0 0 no-repeat;
  padding: 0 0 7px 20px;
}
.pop_change_desc dd {
  font-size: 11px;
  color: #777;
  letter-spacing: -1px;
  padding-top: 13px;
}
.pop_change_desc dd li {
  background: url(#{$imgPath}/common/bullet_square_01.gif) no-repeat 0 6px;
  padding-left: 8px;
  line-height: 16px;
}
