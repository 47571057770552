/*딜구매count*/
.deal_count {
  margin: 10px 0;
  padding: 6px 0 0;
  color: #888;
  font-weight: bold;
  border-top: 1px dashed #d8d8d8;
  width: 100%;
  text-align: right;
}
.deal_count em {
  color: #ff9526;
  font-family: $fontM;
  font-weight: 500;
  font-size: 14px;
  vertical-align: -1px;
  font-weight: normal;
  padding-right: 2px;
}
.table_itemlist2 .deal_count {
  float: left;
  display: block;
  margin: 10px 0;
  padding: 6px 0 0;
  color: #888;
  font-weight: bold;
  border-top: none;
  width: 150px;
  text-align: left;
}
.table_itemlist2 .deal_count em {
  font-weight: normal;
}
