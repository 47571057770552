.ico_mall {
  display: inline-block;
  overflow: hidden;
  position: relative;
  height: 21px;
  *height: 22px;
  color: #666;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: -0.05em;
  vertical-align: middle;
  *zoom: 1;
  *display: inline;
}
.ico_mall .ir {
  background-color: #fff;
  background-image: url(#{$imgPath}/common/ico_mall_v2.png);
  background-repeat: no-repeat;
}
.ico_mall.small {
  width: 16px;
  height: 15px;
  vertical-align: middle;
}
.ico_mall.small.emart .ir {
  background-position: -200px 0;
}
.ico_mall.small.ssgmall .ir {
  background-position: -200px -50px;
}
.ico_mall.small.depart .ir {
  background-position: -200px -100px;
}
.ico_mall.small.traders .ir {
  background-position: -200px -150px;
}
.ico_mall.small.boons .ir {
  background-position: -200px -200px;
}
.ico_mall.small.ssg .ir {
  background-position: -200px -250px;
}
.ico_mall.normal.emart {
  width: 77px;
}
.ico_mall.normal.emart .ir {
  background-position: -480px 0;
}
.ico_mall.normal.ssgmall {
  width: 102px;
}
.ico_mall.normal.ssgmall .ir {
  background-position: -480px -40px;
}
.ico_mall.normal.depart {
  width: 79px;
}
.ico_mall.normal.depart .ir {
  background-position: -480px -80px;
}
.ico_mall.normal.traders {
  width: 59px;
}
.ico_mall.normal.traders .ir {
  background-position: -480px -120px;
}
.ico_mall.normal.boons {
  width: 52px;
}
.ico_mall.normal.boons .ir {
  background-position: -480px -160px;
}
.ico_mall.normal.ssg {
  width: 64px;
}
.ico_mall.normal.ssg .ir {
  background-position: -480px -200px;
}
.ico_mall.normal.howdy {
  width: 54px;
}
.ico_mall.normal.howdy .ir {
  background-position: -480px -240px;
}
.ico_mall.normal.boots {
  width: 51px;
}
.ico_mall.normal.boots .ir {
  background-position: -480px -280px;
}
.ico_mall.normal.tv {
  width: 82px;
}
.ico_mall.normal.tv .ir {
  background-position: -480px -320px;
}
.ico_mall.normal.si {
  width: 86px;
}
.ico_mall.normal.si .ir {
  background-position: -480px -360px;
}
.ico_mall.normal.outlet {
  width: 87px;
}
.ico_mall.normal.outlet .ir {
  background-position: -480px -400px;
}
.ico_mall span {
  display: block;
  overflow: hidden;
  height: 21px;
  *height: 22px;
  background-image: url(#{$imgPath}/common/ico_mall_v2.png);
  text-indent: -9999px;
  white-space: nowrap;
  background-repeat: no-repeat;
}
.ico_mall .emart {
  width: 77px;
  background-position: -480px 0;
}
.ico_mall .ssgmall {
  width: 102px;
  background-position: -480px -40px;
}
.ico_mall .depart {
  width: 79px;
  background-position: -480px -80px;
}
.ico_mall .traders {
  width: 59px;
  background-position: -480px -120px;
}
.ico_mall .boons {
  width: 52px;
  background-position: -480px -160px;
}
.ico_mall .ssg {
  width: 64px;
  background-position: -480px -200px;
}
.ico_mall .howdy {
  width: 54px;
  background-position: -480px -240px;
}
.ico_mall .boots {
  width: 51px;
  background-position: -480px -280px;
}
.ico_mall .tv {
  width: 82px;
  background-position: -480px -320px;
}
.ico_mall .si {
  width: 86px;
  background-position: -480px -360px;
}
.ico_mall .outlet {
  width: 87px;
  background-position: -480px -400px;
}
.ico_small {
  width: 16px;
  height: 15px;
}
.ico_small .emart {
  background-position: -200px 0;
}
.ico_small .ssgmall {
  background-position: -200px -50px;
}
.ico_small .depart {
  background-position: -200px -100px;
}
.ico_small .traders {
  background-position: -200px -150px;
}
.ico_small .boons {
  background-position: -200px -200px;
}
.ico_delivery {
  display: inline-block;
  overflow: hidden;
  position: relative;
  color: #666;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: -0.05em;
  *zoom: 1;
  *display: inline;
}
.ico_delivery .ir {
  background-color: #fff;
  background-image: url(#{$imgPath}/common/ico_mall_v2.png);
  background-repeat: no-repeat;
}
.ico_delivery.store {
  width: 27px;
  height: 20px;
}
.ico_delivery.store.emart .ir {
  background-position: -250px 0;
}
.ico_delivery.store.ssgmall .ir {
  background-position: -250px -50px;
}
.ico_delivery.store.depart .ir {
  background-position: -250px -100px;
}
.ico_delivery.store.traders .ir {
  background-position: -250px -150px;
}
.ico_delivery.store.boons .ir {
  background-position: -250px -200px;
}
.ico_delivery.parcel {
  width: 28px;
  height: 20px;
}
.ico_delivery.parcel.emart .ir {
  background-position: -350px 0;
}
.ico_delivery.parcel.ssgmall .ir {
  background-position: -350px -50px;
}
.ico_delivery.parcel.depart .ir {
  background-position: -350px -100px;
}
.ico_delivery.parcel.traders .ir {
  background-position: -350px -150px;
}
.ico_delivery.parcel.boons .ir {
  background-position: -350px -200px;
}
.ico_magicpick {
  display: inline-block;
  width: 47px;
  height: 21px;
  background: url(#{$imgPath}/common/ico_magicpick.gif) no-repeat;
  vertical-align: top;
}
.ico_quick {
  display: inline-block;
  width: 47px;
  height: 21px;
  background: url(#{$imgPath}/common/ico_quick.gif) no-repeat;
  vertical-align: top;
}
.ico_today_d {
  display: inline-block;
  width: 55px;
  height: 21px;
  background: url(#{$imgPath}/common/ico_today_d.png) no-repeat;
  vertical-align: top;
}
.ico_ssg {
  display: inline-block;
  width: 34px;
  height: 21px;
  background: url(#{$imgPath}/common/ico_ssgcon.gif) no-repeat;
  vertical-align: top;
}
/* 상품유닛 부츠 몰아이콘(로고이미지) */
.itembx .ico_mall .boots {
  width: 35px;
  background-position: -415px -280px;
}
.tb_itembx .ico_mall .boots {
  width: 35px;
  background-position: -415px -280px;
}
.itembx .soldout_link {
  position: absolute;
  right: 4px;
  bottom: 3px;
  font-size: 0;
  line-height: 0;
}
.itembx .soldout_link button {
  display: inline-block;
  overflow: hidden;
  margin-left: 2px;
}
.itembx .soldout_link .btn_enter {
  width: 29px;
  height: 29px;
  background: url(#{$imgPath}/common/btn_enter.png) no-repeat;
}
.itembx .soldout_link .btn_change {
  width: 29px;
  height: 29px;
  background: url(#{$imgPath}/common/btn_change.png) no-repeat;
}
.option_select.small.choice_agr {
  text-align: left !important;
}
.option_select.small.choice_agr label {
  line-height: 27px;
  font-size: 11px;
  font-weight: normal;
  color: #666;
}
.option_select.small.choice_agr label input {
  margin-top: -2px;
}
.sel_magicpick {
  position: relative;
}
.magicpick_tt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(203, 140, 94, 0.95);
}
