/* board */
.board_comment {
  clear: both;
  position: relative;
  width: 100%;
  min-width: 700px;
  margin: 87px 0 48px;
}
.board_comment .emo {
  position: absolute;
  top: -69px;
  right: 28px;
  width: 105px;
  height: 80px;
  background: url(#{$imgPath}/common_layout/img_board.png) 0 0 no-repeat;
}
.board_comment table {
  width: 100%;
  border: 0;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}
.board_comment .board_write {
  border-top: 2px solid #33353e;
}
.board_comment .board_write .board_write_inner {
  padding: 20px;
  border: 1px solid #dadada;
  border-top: 0;
  background: #f7f7f7;
}
.board_comment .board_write .board_tit {
  margin-bottom: 9px;
  color: #666;
  font-weight: bold;
  line-height: 15px;
}
.board_comment .board_write td {
  border: 0 none;
  vertical-align: top;
}
.board_comment .board_write td.btn_area {
  text-align: right;
}
.board_comment .board_write textarea {
  display: block;
  overflow: auto;
  *position: relative;
  *top: -1px;
  width: 100%;
  height: 46px;
  padding: 12px 14px;
  border: 1px solid #c8c8c8;
  color: #959595;
  line-height: 17px;
  resize: none;
}
.board_comment .board_lst td {
  padding: 10px 0 8px;
  border: 0;
  border-bottom: 1px solid #eaeaea;
  color: #888;
  line-height: 17px;
  text-align: center;
  vertical-align: top;
}
.board_comment .board_lst td p {
  text-align: left;
  word-break: break-all;
  word-wrap: break-word;
}
.board_comment .board_lst td .btn_board_del {
  width: 13px;
  height: 17px;
  margin-left: 3px;
  background: url(#{$imgPath}/common_layout/btn_board_del.gif) 0 1px no-repeat;
  vertical-align: middle;
}
.board_comment_img .board_img_tit {
  position: absolute;
  top: -58px;
  left: 0;
  width: 818px;
  height: 58px;
  background: url(#{$imgPath}/emall_plan/tit_img_reply.gif) 0 0 no-repeat;
}
.board_comment_img .ip_img_tit {
  width: 790px;
  height: 31px;
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #959595;
  line-height: 31px\9;
  text-indent: 13px;
  margin-bottom: 6px;
}
.board_comment_img .ip_img_file {
  height: 27px;
  padding: 4px 13px 0;
  border: 1px solid #c8c8c8;
  background: #fff;
}
.board_comment_img .ip_img_file input {
  width: 100%;
  height: 23px;
  border: 0 none;
  color: #959595;
  line-height: 23px\9;
  background: #fff;
}
.board_comment_img .ip_img_file input::-ms-value {
  border: 0;
  background: #fff;
  color: #959595;
}
.board_comment_img .board_write td {
  padding: 0;
}
.board_comment_img .board_write td.btn_area {
  padding-bottom: 0;
}
.board_comment_img .ip_img_desc {
  display: block;
  margin-top: 6px;
  color: #666;
  font-size: 12px;
}
.board_comment_img .board_lst {
  overflow: hidden;
  width: 1020px;
}
.board_img_lst {
  width: 1032px;
  padding-top: 40px;
}
.board_img_lst:after {
  display: block;
  clear: both;
  content: '';
}
.board_img_lst li {
  float: left;
  width: 210px;
  height: 352px;
  margin: 0 12px 12px 0;
  padding: 0 15px;
  border: 3px solid #e9e9e9;
}
.board_img_lst .num {
  display: block;
  height: 17px;
  padding-top: 8px;
  font-weight: bold;
  text-align: right;
}
.board_img_lst .thmb {
  width: 210px;
  height: 210px;
}
.board_img_lst .thmb img {
  width: 210px;
  height: 210px;
  vertical-align: top;
}
.board_img_lst .title {
  overflow: hidden;
  position: relative;
  height: 42px;
  margin-top: 12px;
  padding-right: 20px;
  border-bottom: 1px solid #e9e9e9;
}
.board_img_lst .title p {
  overflow: hidden;
  height: 34px;
  color: #222;
  line-height: 17px;
}
.board_img_lst .title .btn_board_del {
  position: absolute;
  top: 0;
  right: 0;
  width: 13px;
  height: 14px;
  background: url(#{$imgPath}/common_layout/btn_board_del.gif) 0 1px no-repeat;
}
.board_img_lst .desc {
  overflow: hidden;
  position: relative;
  width: 200px;
  padding: 11px 5px 0;
  color: #888;
  zoom: 1;
}
.board_img_lst .desc dt {
  float: left;
  clear: both;
  width: 44px;
  width: auto;
  margin-bottom: 3px;
  white-space: nowrap;
}
.board_img_lst .desc dd {
  overflow: hidden;
  clear: none;
  margin-bottom: 3px;
}
