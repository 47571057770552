.ty_sfc .aside_myssg {
  position: absolute;
  z-index: 20;
  top: 117px;
  left: 0;
  background: url(#{$imgPath}/mem/bg_myssg_left.gif) repeat-y;
}
.aside_myssg {
  position: absolute;
  z-index: 20;
  top: 178px;
  left: 0;
  background: url(#{$imgPath}/mem/bg_myssg_left.gif) repeat-y;
}
.aside_myssg.v3 {
  top: 118px;
}
.aside_myssg .my_grade {
  width: 194px;
  height: 129px;
  background: url(#{$imgPath}/mem/bg_myssg_grade.gif) no-repeat;
}
.aside_myssg .my_grade ul {
  padding-top: 6px;
}
.aside_myssg .my_grade ul li {
  vertical-align: top;
  text-align: right;
  padding: 0 20px;
}
.aside_myssg .my_grade ul li a {
  display: block;
  text-decoration: none;
}
.aside_myssg .my_grade ul li em {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  font-family: $fontR;
  font-weight: 400;
  color: #ff5855;
  font-size: 17px;
  vertical-align: top;
  line-height: 40px;
  *line-height: 37px;
}
.aside_myssg .my_grade ul li span {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  font-family: $fontR;
  font-weight: 400;
  color: #555;
  font-size: 12px;
  vertical-align: top;
  line-height: 40px;
}
.aside_myssg .myssg_lnb {
  width: 194px;
  padding-bottom: 30px;
  background: url(#{$imgPath}/mem/bg_myssg_left.gif) repeat-y;
}
.aside_myssg .myssg_lnb .control li {
  font-size: 0;
}
.aside_myssg .myssg_lnb .control li a {
  display: block;
  height: 50px;
  line-height: 50px;
  background: url(#{$imgPath}/csc/bg_lnb_v2.gif) 0 0 no-repeat;
  padding-top: 1px;
}
.aside_myssg .myssg_lnb.v3 .control li a {
  background: url(#{$imgPath}/csc/bg_lnb_v2.png) 0 0 no-repeat;
  text-decoration: none;
}
.aside_myssg .myssg_lnb .control li.order a {
  background-position: 0 0;
}
.aside_myssg .myssg_lnb .control li.account a {
  background-position: 0 -51px;
}
.aside_myssg .myssg_lnb .control li.product a {
  background-position: 0 -102px;
}
.aside_myssg .myssg_lnb .control li.activity a {
  background-position: 0 -153px;
}
.aside_myssg .myssg_lnb .control li.gift a {
  background-position: 0 -204px;
}
.aside_myssg .myssg_lnb .control li.info a {
  background-position: 0 -255px;
  height: 51px;
  line-height: 51px;
}
.aside_myssg .myssg_lnb .control li.order a:hover {
  background-position: -200px 0;
}
.aside_myssg .myssg_lnb .control li.account a:hover {
  background-position: -200px -51px;
}
.aside_myssg .myssg_lnb .control li.product a:hover {
  background-position: -200px -102px;
}
.aside_myssg .myssg_lnb .control li.activity a:hover {
  background-position: -200px -153px;
}
.aside_myssg .myssg_lnb .control li.gift a:hover {
  background-position: -200px -204px;
}
.aside_myssg .myssg_lnb .control li.info a:hover {
  background-position: -200px -255px;
}
.aside_myssg .myssg_lnb .control li.order.active a {
  background-position: -400px 0;
}
.aside_myssg .myssg_lnb .control li.account.active a {
  background-position: -400px -51px;
}
.aside_myssg .myssg_lnb .control li.product.active a {
  background-position: -400px -102px;
}
.aside_myssg .myssg_lnb .control li.activity.active a {
  background-position: -400px -153px;
}
.aside_myssg .myssg_lnb .control li.gift.active a {
  background-position: -400px -204px;
}
.aside_myssg .myssg_lnb .control li.info.active a {
  background-position: -400px -255px;
}
.aside_myssg .myssg_lnb .control li li a span {
  content: none;
  display: inline;
  position: inherit;
  width: auto;
  height: auto;
  line-height: inherit;
  visibility: inherit;
}
.aside_myssg .myssg_lnb .control li ul {
  display: none;
  width: 194px;
}
.aside_myssg .myssg_lnb .control li.active ul {
  display: block;
  padding-top: 24px;
  padding-bottom: 24px;
  background: url(#{$imgPath}/csc/bg_aside.gif);
}
.aside_myssg .myssg_lnb .control li.active ul ul {
  display: block;
  padding-top: 0;
  padding-bottom: 0;
  background: url(#{$imgPath}/csc/bg_aside.gif);
}
.aside_myssg .myssg_lnb .control li.active ul ul li a {
  display: block;
  width: 151px;
  padding: 0;
  line-height: 24px;
  margin-left: 1px;
  padding-left: 41px;
  background: url(#{$imgPath}/csc/bg_dot02.gif) 0 1px no-repeat;
}
.aside_myssg .myssg_lnb .control li.active ul ul li a:hover {
  display: block;
  width: 151px;
  line-height: 24px;
  margin-left: 1px;
  padding-left: 41px;
  background: url(#{$imgPath}/csc/bg_dot02.gif) 0 -23px no-repeat;
}
.aside_myssg .myssg_lnb .control li.info.active ul {
  border-bottom: 1px dotted #a0a0a0;
}
.aside_myssg .myssg_lnb .control li ul li a {
  display: block;
  padding-left: 30px;
  height: 24px;
  line-height: 24px;
  background: url(#{$imgPath}/csc/bg_dot.gif) 18px 10px no-repeat;
  color: #666;
  font-size: 12px;
  text-decoration: none;
}
.aside_myssg .myssg_lnb .control li ul li p a {
  display: inline;
  padding-left: 0;
  height: 24px;
  line-height: 24px;
  background: none;
  color: #666;
  font-size: 12px;
  text-decoration: none;
}
.aside_myssg .myssg_lnb .control li ul li p a:hover {
  color: #000;
}
.aside_myssg .myssg_lnb .control li ul li p {
  color: #666;
}
.aside_myssg .myssg_lnb .control li ul li.myssg_l_clip {
  position: relative;
}
.aside_myssg .myssg_lnb .control li ul li.myssg_l_clip p {
  position: absolute;
  left: 72px;
  top: 1px;
  z-index: 10;
  font-size: 12px;
}
.aside_myssg .myssg_lnb .control li ul li.myssg_l_clip p a {
  margin: 0 2px 0 3px;
  letter-spacing: -1px;
}
.aside_myssg .myssg_lnb .control li.info ul li a {
  display: block;
  padding: 6px 0 5px 30px;
  height: auto;
  line-height: 14px;
  background: url(#{$imgPath}/csc/bg_dot.gif) 18px 10px no-repeat;
  color: #666;
  font-size: 12px;
  text-decoration: none;
}
.aside_myssg .myssg_lnb .control li.active li a {
  background: url(#{$imgPath}/csc/bg_dot.gif) 1px 0 no-repeat;
  background-position: 20px 11px;
}
.aside_myssg .myssg_lnb .control li.active li a:hover {
  background: url(#{$imgPath}/csc/bg_aside_hover.gif) 1px 0 no-repeat;
}
.aside_myssg .myssg_lnb .control li.active li p a {
  background: none;
}
.aside_myssg .myssg_lnb .control li.active li p a:hover {
  background: none;
}
.aside_myssg .myssg_lnb .control li ul {
  display: none;
}
.aside_myssg .myssg_lnb .control li a span {
  content: '';
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  line-height: 0;
  visibility: hidden;
}
.aside_myssg .myssg_lnb.v3 .control li a span {
  font-size: 15px;
  padding-left: 44px;
  letter-spacing: -1px;
  color: #747474;
  visibility: visible;
  line-height: 50px;
  height: 50px;
  position: static;
  width: auto;
  height: auto;
}
.aside_myssg .myssg_lnb.v3 .control li a:hover span,
.aside_myssg .myssg_lnb.v3 .control li.active a span {
  color: #fff;
}
:lang(en) .aside_myssg .myssg_lnb.v3 .control li a span {
  font-size: 14px;
  padding-left: 40px;
}
.aside_myssg .myssg_nav {
  position: absolute;
  left: 0;
  top: 194px;
  border-top: 3px solid #7ca4bf;
}
.aside_myssg .myssg_lnb .control li ul li.active > a {
  background: url(#{$imgPath}/csc/bg_aside_hover.gif) 1px 0 no-repeat !important;
}
.aside_myssg .myssg_lnb .control li ul ul li.active > a {
  background: url(#{$imgPath}/csc/bg_dot02.gif) 0 -23px no-repeat !important;
}
:lang(en) .aside_myssg .myssg_lnb .control li ul li a,
:lang(zh) .aside_myssg .myssg_lnb .control li ul li a {
  display: block;
  padding: 6px 0 5px 30px;
  height: auto;
  line-height: 14px;
  background: url(#{$imgPath}/csc/bg_dot.gif) 18px 10px no-repeat;
  color: #666;
  font-size: 12px;
  text-decoration: none;
}

.content_myssg {
  position: relative;
  float: right;
  z-index: 1;
  width: 790px;
  min-height: 690px;
  padding-left: 230px;
  background: url(#{$imgPath}/mem/bg_myssg_left.gif) repeat-y;
}
.content_myssg,
.content_myssg button,
.content_myssg input,
.content_myssg textarea {
  font-family: $fontR;
  font-weight: 400;
}
