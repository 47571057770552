/* cs 버튼 */
.btn_cs {
  padding-left: 7px;
  font-size: 11px;
  display: inline-block;
  font-family: $fontR;
  font-weight: 400;
  line-height: 0.95em;
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
  -webkit-appearance: none;
  background-image: url(#{$imgPath}/common/bg_btn_slightly2.png);
  background-repeat: no-repeat;
}
.btn_cs span {
  display: inline-block;
  padding: 10px 9px 0 2px;
  height: 18px;
  letter-spacing: -1px;
  background-image: url(#{$imgPath}/common/bg_btn_slightly2.png);
  background-repeat: no-repeat;
  background-color: #fff;
}
.btn_cs.ty1 {
  background-position: 1px 0;
  color: #fff !important;
}
.btn_cs.ty1 span {
  background-position: 100% 0;
}
.btn_cs.ty2 {
  background-position: 0 -34px;
  color: #666 !important;
}
.btn_cs.ty2 span {
  background-position: 100% -34px;
}
.btn_cs.ty3 {
  background-position: 0 -68px;
  color: #fff !important;
}
.btn_cs.ty3 span {
  background-position: 100% -68px;
}
.btn_cs.ty4 {
  background-position: 0 -102px;
  color: #666 !important;
}
.btn_cs.ty4 span {
  background-position: 100% -102px;
}
.btn_cs_ty1 {
  display: inline-block;
  line-height: 26px;
  font-size: 12px;
  border: 1px solid #000;
  border-radius: 3px;
  text-decoration: none !important;
  padding: 0 7px 0 6px;
  background: #4a4643;
  color: #fff;
  letter-spacing: -0.5px;
}
.btn_cs_ty2 {
  display: inline-block;
  line-height: 26px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-decoration: none !important;
  padding: 0 7px 0 6px;
  background: #eee;
  color: #666;
  letter-spacing: -0.5px;
}
.btn_cs_ty3 {
  display: inline-block;
  line-height: 26px;
  font-size: 12px;
  border: 1px solid #ff6161;
  border-radius: 3px;
  text-decoration: none !important;
  padding: 0 7px 0 6px;
  background: #ff6161;
  color: #fff;
  letter-spacing: -0.5px;
}
.btn_cs_ty4 {
  display: inline-block;
  line-height: 26px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-decoration: none !important;
  padding: 0 7px 0 6px;
  background: #fff;
  color: #666;
  letter-spacing: -0.5px;
}
