.thumbnail.plz_line {
  border: 1px solid #ccc;
}
.thumbplz {
  float: left;
  position: relative;
}
.thumbplz p {
  padding-top: 4px;
  width: 40px;
  height: 32px;
  font-family: $fontR;
  font-weight: 400;
  background: url(#{$imgPath}/@temp/ico_comment.png) no-repeat left top;
}
.thumbplz .sale {
  height: 28px;
  padding-top: 20px;
}
.thumbplz .gift {
  background-position: 0 -161px;
}
.thumbplz .plus {
  height: 33px;
  padding-top: 15px;
  background-position: 0 -81px;
}
.thumbplz .plus2 {
  height: 33px;
  padding-top: 15px;
  background-position: 0 -240px;
}
.thumbplz .plz_comment {
  position: absolute;
  top: -14px;
  left: -27px;
  color: #5aadbc;
  text-align: center;
}
.thumbplz .plz_comment span {
  font-size: 15px;
}
