.sp_notice {
  padding: 50px 50px 20px;
}
.cp_list {
  position: relative;
  overflow: hidden;
  width: 800px;
}
.cp_list ul {
  margin-left: -62px;
  width: 861px;
}
.cp_list ul li {
  position: relative;
  float: left;
  width: 225px;
  height: 125px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 31px;
  margin-left: 30px;
  background: url(#{$imgPath}/mem/bg_coupon.gif) 31px 25px no-repeat;
  border-left: 1px dotted #d1d1d1;
}
.cp_list ul li .cont_date {
  position: absolute;
  bottom: 30px;
  right: 10px;
  font-size: 11px;
  color: #6d777d;
}
.cp_list .blank {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
  width: 0;
  height: 125px;
  overflow: hidden;
}
.cp_list .cont {
  display: inline-block;
  width: 175px;
  padding: 0 25px;
  vertical-align: middle;
  *display: inline;
  *zoom: 1;
  text-align: center;
  font-size: 21px;
  line-height: 23px;
  font-weight: bold;
  color: #222;
}
.cp_list ul li a {
  display: block;
  width: 225px;
  height: 125px;
  text-decoration: none;
}
.view_item_list li .thumbnail .layer_panel {
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 126px;
  margin-left: -63px;
  background-position: -35px 0;
}
.view_item_list li.lst_over .thumbnail.layer_panel {
  display: block;
}
.intro_content {
  position: relative;
}
.intro_content .on_delivery {
  overflow: hidden;
  margin: 0 auto;
  width: 665px;
  padding-bottom: 30px;
  padding-top: 10px;
}
.intro_content .on_delivery li {
  float: left;
  padding: 0 10px 0 23px;
  background: url(#{$imgPath}/mem/bg_arrow03.png) no-repeat 0 50px;
}
.intro_content .on_delivery li:first-child {
  background: none;
}
.intro_content .on_delivery li span {
  display: block;
  width: 100px;
  height: 25px;
  padding: 103px 0 0 0;
  background: url(#{$imgPath}/mem/bg_current_pic.png) no-repeat 0 19px;
  text-align: center;
}
.intro_content .on_delivery li span img {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  margin-top: 5px;
  vertical-align: top;
}
.intro_content .on_delivery li.step1 span {
  background-position: 20px 19px;
}
.intro_content .on_delivery li.step2 span {
  background-position: -105px 19px;
}
.intro_content .on_delivery li.step3 span {
  background-position: -232px 19px;
}
.intro_content .on_delivery li.step4 span {
  background-position: -355px 19px;
}
.intro_content .on_delivery li.step5 span {
  background-position: -460px 19px;
}
.intro_content .on_delivery li.step1.on span {
  background-position: 20px -101px;
}
.intro_content .on_delivery li.step2.on span {
  background-position: -105px -101px;
}
.intro_content .on_delivery li.step3.on span {
  background-position: -232px -101px;
}
.intro_content .on_delivery li.step4.on span {
  background-position: -355px -101px;
}
.intro_content .on_delivery li.step5.on span {
  background-position: -460px -101px;
}
.intro_content .on_delivery li.on span {
  padding-top: 103px;
}
.intro_content .on_delivery li span.ssg_num,
.intro_content .on_delivery li.on span.ssg_num {
  float: none;
  display: inline-block;
  margin-top: 0;
  width: 25px;
  height: 25px;
  margin-left: 5px;
  padding-top: 0;
  background: url(#{$imgPath}/mem/bg_pink_num.png) no-repeat 0 0;
}
.on_delivery_second {
  overflow: hidden;
  margin: 30px;
  padding-left: 18px;
  background: url(#{$imgPath}/mem/step_5.gif) 47px 0 no-repeat;
}
.on_delivery_second li {
  position: relative;
  float: left;
  height: 110px;
  width: 134px;
}
.on_delivery_second .ssg_num {
  position: absolute;
  top: 7px;
  right: 27px;
  display: block;
  font-size: 11px;
  color: #fff;
  font-weight: bold;
  line-height: 27px;
  vertical-align: middle;
  text-align: center;
  width: 25px;
  height: 25px;
  background: url(#{$imgPath}/mem/bg_pink_num.png) no-repeat 0 0;
  text-decoration: none;
}
.on_delivery_second .ssg_num {
  display: none;
}
.on_delivery_second .on .ssg_num {
  display: block;
}
.customer_comment.none_data {
  width: 900px;
  height: 377px;
  background: url(#{$imgPath}/mem/img_myssg_pop_01.png) 260px 70px no-repeat;
}
.inpbx {
  display: inline-block;
  background: #fff;
}
.inpbx input {
  width: 100%;
  padding: 0;
  height: 16px;
  border: 0;
  vertical-align: top;
}
.itembx .thumbnail {
  position: static;
}
.itembx .thumbnail.lst_over .layer_panel {
  display: block;
}
.itembx .thumbnail .layer_panel {
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 126px;
  margin-left: -63px;
  background-position: -35px 0;
  z-index: 100;
}
.itembx .thumbnail .layer_panel a {
  float: left;
  z-index: 150;
}
.itembx .thumbnail .thumask {
  position: absolute;
  top: 219px;
  left: 0;
  width: 100%;
  height: 4px;
  background: url(#{$imgPath}/common/itembx_thumask.gif) repeat-x;
}
.itembx .thumcircle .thumask {
  top: 0;
  width: 223px;
  height: 223px;
  background: url(#{$imgPath}/common/itembx_thumask_circle.png) no-repeat;
}
.itembx .display_store {
  text-align: left;
  padding: 8px 0;
  border-bottom: 1px dashed #d8d8d8;
  white-space: nowrap;
  *zoom: 1;
}
.itembx .display_store:after {
  display: block;
  clear: both;
  content: '';
}
.itembx .display_store .ico_mall {
  vertical-align: top;
}
.itembx .display_store span span {
  margin-left: 0 !important;
}
.itembx .display_store .store_mark {
  display: inline-block;
  height: 15px;
  margin-left: 5px;
  padding: 2px 7px 0 7px;
  border: 1px solid #e1dfde;
  font-size: 11px;
  font-style: normal;
  line-height: 15px;
  letter-spacing: -1px;
  vertical-align: middle;
}
.itembx .display_store .sales_count {
  float: right;
  margin: 0;
  padding: 2px 0 0;
  color: #888;
  font-weight: bold;
}
.itembx .display_store .sales_count em {
  color: #ff9526;
  font-family: $fontR;
  font-weight: 400;
  font-size: 14px;
  vertical-align: -1px;
}
.special .list_style1 .itembx .summary {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 42px;
  color: #666;
  text-decoration: none;
}
.special .itembx .summary a {
  max-height: 50px;
}
.itembx .summary {
  padding: 8px 0 6px;
  font-family: $fontR;
  font-weight: 400;
  line-height: 17px;
  font-size: 13px;
}
.itembx .summary a {
  overflow: hidden;
  display: block;
  width: 100%;
  max-height: 33px;
  color: #666;
  text-decoration: none;
  word-break: break-all;
  word-wrap: break-word;
}
.itembx .summary a .subject {
  word-break: break-all;
}
.itembx .summary a:hover {
  text-decoration: underline;
}
.itembx.item_size140 .price_unit .help_sale_info {
  height: 30px;
  background: url(#{$imgPath}/common/sp_togle.png) no-repeat 0 5px;
}
.plz_comment .price_unit.style .help_sale_info.active {
  background-position: 0 -18px;
}
.itembx .price_unit .help_sale_info.active {
  background-position: 0 -21px;
}
.itembx.itembx.item_size140 .price_unit .help_sale_info.active {
  background-position: 0 -21px;
}
.itembx .cmtbx {
  position: relative;
  margin: -2px 0 0;
  padding-top: 11px;
  *zoom: 1;
}
.itembx .cmtbx dl {
  padding: 8px 13px 2px;
  border: 1px solid #bdbdbd;
  font-size: 11px;
  color: #999;
  line-height: 14px;
}
.itembx .cmtbx dt {
  margin-bottom: -5px;
  color: #3077b8;
}
.itembx .cmtbx p {
  padding: 9px 0 7px;
  border-top: 1px dotted #bdbdbd;
  word-break: break-all;
}
.itembx .cmtbx p:first-child {
  border-top: 0;
}
.itembx .cmtbx span {
  position: absolute;
  width: 2px;
  height: 2px;
  background: url(#{$imgPath}/common/sp_cmtbx.gif) no-repeat;
}
.itembx .cmtbx a {
  color: #999;
  text-decoration: none;
}
.itembx .cmtbx a:hover {
  text-decoration: underline;
}
.itembx .cmtbx .arr {
  top: 0;
  left: 50%;
  width: 15px;
  height: 13px;
  margin-left: -7px;
  background-position: 0 0;
}
.itembx .cmtbx .tl {
  top: 11px;
  left: 0;
  background-position: -18px 0;
}
.itembx .cmtbx .tr {
  top: 11px;
  right: 0;
  background-position: -23px 0;
}
.itembx .cmtbx .bl {
  bottom: 0;
  left: 0;
  background-position: -18px -5px;
}
.itembx .cmtbx .br {
  bottom: 0;
  right: 0;
  background-position: -23px -5px;
}
.itembx .spzbx {
  width: 100%;
  max-height: 15px;
  margin-top: 4px;
  *zoom: 1;
}
.itembx .spzbx:after {
  display: block;
  clear: both;
  content: '';
}
.itembx .star_rating {
  float: left;
  width: 53%;
  height: 15px;
  background: none;
}
.itembx .star_rating .star {
  float: left;
  width: 50px;
  height: 10px;
  margin: 1px 5px 0 0;
  background: url(#{$imgPath}/common/bg_star_rating.png) no-repeat 0 0;
}
.itembx .star_rating .star span {
  display: inline-block;
  height: 10px;
  background: url(#{$imgPath}/common/bg_star_rating.png) no-repeat 0 -20px;
  vertical-align: top;
}
.itembx .star_rating strong {
  float: left;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
}
.itembx .plz_number {
  float: right;
  padding-left: 20px;
  background: url(#{$imgPath}/common/ico_ellipsis02.gif) no-repeat 0 0;
  font-size: 11px;
  line-height: 15px;
}
.itembx .item_promotion {
  overflow: hidden;
  margin-top: 3px;
}
.itembx .item_promotion span {
  float: left;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 99px;
  height: 16px;
  margin: 2px -1px 0 0;
  font-size: 11px;
  color: #666;
  line-height: 19px;
  vertical-align: middle;
}
.itembx .item_promotion span.sale {
  background: none;
  padding-top: 0;
}
.itembx .item_promotion span strong,
.itembx .item_promotion span em {
  float: left;
  height: 16px;
}
.itembx .item_promotion span strong {
  margin-right: 5px;
}
.itembx .show_all_store {
  display: none;
  margin-top: 12px;
  text-align: center;
}
.itembx .show_all_store a {
  display: inline-block;
  width: 142px;
  height: 26px;
  text-indent: 7px;
  background: url(#{$imgPath}/display/btn_showall_store.gif) no-repeat;
  color: #666;
  line-height: 26px;
  text-decoration: none;
  text-align: left;
}
.itembx .show_all_store a.active {
  background: url(#{$imgPath}/display/btn_showall_store_close.gif) no-repeat;
}
.itembx .notit {
  display: block;
  height: 83px;
  padding-top: 13px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 18px;
  color: #222;
  line-height: 24px;
  text-align: center;
}
.itembx .period {
  display: block;
  margin-top: 8px;
  color: #666;
  text-align: center;
}
.itembx .today_buy {
  position: absolute;
  top: 182px;
  right: -56px;
  width: 140px;
  height: 161px;
  background: url(#{$imgPath}/service/bg_today_buy.png) no-repeat left top;
}
.itembx .today_buy .count {
  display: block;
  padding-top: 40px;
  width: 100%;
  text-align: center;
  text-indent: -2px;
  color: #fff;
}
.itembx .today_buy .count .em {
  display: inline-block;
  margin-right: 6px;
  font-size: 54px;
  line-height: 60px;
  *line-height: 54px;
  font-style: italic;
  letter-spacing: -2px;
  font-family: $fontR;
  font-weight: 400;
}
.itembx_thm a {
  color: #666;
  text-decoration: none;
}
.itembx .price_unit {
  position: relative;
  margin: 0 auto;
  z-index: 5;
  text-align: center;
}
.itembx .price_unit del {
  display: block;
  max-height: 16px;
  font-size: 12px;
  color: #888;
  font-family: $fontR;
  font-weight: 400;
  letter-spacing: -0.5px;
}
.itembx .price_unit del + .layer_wrap a {
  display: inline-block;
}
.itembx .price_unit .layer_wrap a {
  display: none;
}
.itembx .price_unit .layer_wrap {
  margin-top: -5px;
}
.itembx .price_unit .applied {
  display: inline-block;
  height: 30px;
  color: #3b3b3b;
  line-height: 30px;
  vertical-align: middle;
  font-family: $fontR;
  font-weight: 400;
}
.itembx .price_unit .applied em {
  font-size: 20px;
  letter-spacing: -0.5px;
}
.itembx .price_unit .applied .ssg_tx {
  vertical-align: 2px;
}
.itembx .price_unit .help_sale_info,
.tb_itembx .price_unit .help_sale_info {
  display: inline-block;
  width: 23px;
  height: 30px;
  background: url(#{$imgPath}/common/sp_togle.png) no-repeat 0 5px;
  vertical-align: middle;
}
.itembx .price_unit .unit_price {
  display: block;
  color: #888;
  text-align: center;
  font-family: $fontR;
  font-weight: 400;
  font-size: 11px;
}
.tb_itembx .price_unit .price_date {
  top: 100%;
}
.itembx.item_size350 .price_unit {
  height: 80px;
}
.itembx.item_size350 .price_unit .applied {
  height: 55px;
}
.itembx.item_size350 .price_unit .help_sale_info {
  margin-top: 11px;
}
.category_best .itembx .item_promotion {
  overflow: hidden;
  height: 37px;
}
.lst_over .itembx .thumbnail .layer_panel {
  display: block;
}
.list_style1 {
  padding: 1px 0 0 1px;
  *zoom: 1;
  padding-top: 30px;
}
.discount_collect .lst_over .itembx .thumbnail .layer_panel {
  display: none;
}
.special .list_style1 {
  padding: 1px 0 0 1px;
  *zoom: 1;
}
.list_style1:after {
  display: block;
  clear: both;
  content: '';
}
.list_style1 li {
  float: left;
  position: relative;
  width: 252px;
  margin: -1px 0 0 -1px;
}
.list_style1 li .itembx {
  padding: 20px;
}
.list_style1 li:first-child {
  margin-left: 0;
  border-left: 0;
}
.list_style1 li.lst_over {
  position: relative;
  z-index: 25;
}
.list_style1 li.lst_over .itembx {
  padding: 19px;
  border: 1px solid #222;
}
.table_itemlist {
  *zoom: 1;
  width: 100%;
}
.best_section {
  width: 1020px;
}
.table_itemlist.mgt30 {
  margin-top: 10px !important;
}
.table_itemlist.pdt60 {
  padding-top: 40px !important;
}
.table_itemlist table {
  width: 100%;
  table-layout: fixed;
}
.table_itemlist td {
  background: url(#{$imgPath}/common/bg_dotted.gif) repeat-y 0 0;
  vertical-align: top;
}
.tabtog_sec .table_itemlist td {
  width: 255px;
}
.tabtog_sec .table_itemlist .list_content {
  width: 255px;
}
.tabtog_sec .table_itemlist_noline td {
  width: 340px;
}
.tabtog_sec .table_itemlist_noline .list_content {
  width: 340px;
}
.search_section .table_itemlist td {
  width: 255px;
}
.search_section .table_itemlist td .list_content {
  width: 255px;
}
.search_section .table_itemlist td .list_content .item_size202 {
  width: 202px;
}
.search_section .itembx .display_store span,
.search_section .itembx .display_store img {
  float: none;
  margin: 0;
}
.search_section .itembx .display_store .sales_count {
  display: none;
}
.table_itemlist td .list_content {
  position: relative;
  float: left;
  width: 100%;
}
.table_itemlist td .list_content.mgt_rank {
  margin-top: 40px;
}
.table_itemlist td .itembx {
  padding: 19px 26px 10px 27px;
  *zoom: 1;
}
.table_itemlist td .item_size290 {
  padding-left: 25px;
  padding-right: 25px;
}
.table_itemlist td.lst_over .itembx {
  position: relative;
  padding: 18px 25px 9px 26px;
  border: 1px solid #222;
}
.table_itemlist td.checked .itembx .inp_dtype {
  border-color: #d9d9d9;
  background-color: #fbfbfb;
}
.table_itemlist td.lst_over .item_size290 {
  padding-left: 24px;
  padding-right: 24px;
}
.table_itemlist td:first-child {
  background: none;
}
.table_itemlist td.clicked .click_effect {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 2px #ed2323 solid;
  border-width: 3px 2px;
  background: url(#{$imgPath}/service/bg_clicked_tag.png) no-repeat 100% 100%;
  *zoom: 1;
}
.table_itemlist td .list_content .itembx .item_ord .item_promotion {
  overflow: hidden;
  max-height: 37px;
  width: 100%;
}
.table_itemlist2 {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-top: solid 1px #666;
  color: #666;
  *zoom: 1;
}
.table_itemlist2 .w85 {
  width: 85px;
}
.table_itemlist2 .w110 {
  width: 110px;
}
.table_itemlist2 .w140 {
  width: 140px;
}
.table_itemlist2 .w180 {
  width: 180px;
}
.table_itemlist2 .w504 {
  width: 504px;
}
.table_itemlist2 .w_chk {
  width: 13px;
}
.table_itemlist2 .w_item {
  width: 472px;
}
.table_itemlist2 .w_price {
  width: 180px;
}
.table_itemlist2 .w_info {
  width: 125px;
}
.table_itemlist2 .w_bt {
  width: 61px;
}
.table_itemlist2 .w_bt2 {
  width: 100px;
}
.table_itemlist2 .w_del {
  width: 0;
}
.table_itemlist2 td {
  padding: 20px 0;
  border-bottom: solid 1px #e9e9e9;
  text-align: center;
  background: url(#{$imgPath}/display/bg_dot_02.gif) 0 0 repeat-y;
}
.table_itemlist2 td:first-child {
  background: none;
  text-align: left;
}
.table_itemlist2 td.itemtb_view {
  padding: 0;
  border-bottom: none;
}
.table_itemlist2 td.itemtb_chk {
  vertical-align: top;
}
.table_itemlist2 td.itemtb_chk + td {
  background: none;
  text-align: left;
}
.table_itemlist2 td.itemtb_del {
  position: relative;
}
.table_itemlist2 .thumbnail2 {
  float: left;
  position: relative;
  line-height: 0;
  width: 140px;
  margin-right: 54px;
  margin-left: 20px;
}
.table_itemlist2 .thumbnail2 .ico_left {
  position: absolute;
  left: -6px;
  top: -3px;
  z-index: 5;
}
.table_itemlist2 .thumbnail2 .ico_right {
  position: absolute;
  left: 106px;
  top: -10px;
  z-index: 5;
}
.table_itemlist2 .thumbnail2 .layer_panel {
  position: absolute;
  left: 147px;
  top: 1px;
  height: 126px;
  z-index: 9;
}
.table_itemlist2 .thumbnail2 .layer_panel a {
  margin: -1px 0 0;
}
.table_itemlist2 .hide_layer_panel .thumbnail2 {
  margin-right: 15px;
}
.table_itemlist2 .infobx {
  float: left;
  width: 263px;
}
.table_itemlist2 .display_store span,
.table_itemlist2 .display_store img {
  vertical-align: middle;
}
.table_itemlist2 .display_store span span {
  margin-left: 0 !important;
}
.table_itemlist2 .display_store span:first-child,
.table_itemlist2 .display_store img:first-child {
  margin-left: 0;
}
.table_itemlist2 .display_store .ico_mall {
  vertical-align: middle;
}
.table_itemlist2 .display_store .store_mark {
  display: inline-block;
  height: 15px;
  margin-left: 5px;
  padding: 2px 7px 0 7px;
  border: 1px solid #e1dfde;
  font-size: 11px;
  font-style: normal;
  line-height: 15px;
  letter-spacing: -1px;
  vertical-align: middle;
}
.table_itemlist2 .summary {
  overflow: hidden;
  height: 28px;
  padding: 8px 0;
  font-family: $fontR;
  font-weight: 400;
  line-height: 17px;
}
.table_itemlist2 .summary a {
  color: #666;
  text-decoration: none;
  font-family: $fontR;
  font-weight: 400;
}
.table_itemlist2 .summary a:hover {
  text-decoration: underline;
}
.table_itemlist2 .spzbx {
  overflow: hidden;
  height: 15px;
  margin-top: 4px;
}
.table_itemlist2 .star_rating {
  float: left;
  height: 15px;
  background: none;
}
.table_itemlist2 .star_rating .star {
  float: left;
  width: 50px;
  height: 10px;
  margin: 1px 5px 0 0;
  background: url(#{$imgPath}/common/bg_star_rating.png) no-repeat 0 0;
}
.table_itemlist2 .star_rating .star span {
  display: inline-block;
  height: 10px;
  background: url(#{$imgPath}/common/bg_star_rating.png) no-repeat 0 -20px;
  vertical-align: top;
}
.table_itemlist2 .star_rating strong {
  float: left;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
}
.table_itemlist2 .itemtb_view .plz_number {
  float: right;
}
.table_itemlist2 .itemtb_view td .list_content .itembx .item_promotion {
  height: 37px;
}
.table_itemlist2 .itemtb_view .item_promotion span {
  margin-left: 0;
}
.table_itemlist2 .show_all_store {
  display: none;
  margin-top: 12px;
}
.table_itemlist2 .show_all_store a {
  display: inline-block;
  width: 142px;
  height: 26px;
  padding-left: 8px;
  background: url(#{$imgPath}/display/btn_showall_store.gif) no-repeat;
  color: #666;
  line-height: 26px;
  text-decoration: none;
  text-align: left;
}
.table_itemlist2 .regular_deliver {
  float: left;
  margin-top: 12px;
}
.table_itemlist2 .regular_deliver a.ssg_toggle {
  display: inline-block;
  width: 198px;
  height: 26px;
  padding-left: 8px;
  background: url(#{$imgPath}/display/btn_regular_deliver.gif) no-repeat;
  color: #666;
  line-height: 26px;
  text-decoration: none;
  text-align: left;
  font-size: 11px;
}
.table_itemlist2 .regular_deliver .tooltop_wrap {
  position: relative;
}
.table_itemlist2 .regular_deliver .tooltop_wrap .tooltip_box {
  position: absolute;
  top: -1px;
  left: 0;
  width: 184px;
  padding: 3px 10px 8px;
  text-align: left;
  line-height: 21px;
  border: 1px solid #f1f0f0;
  background-color: #fff;
  z-index: 100;
}
.table_itemlist2 .regular_deliver .tooltop_wrap .tooltip_box a {
  display: block;
  height: 21px;
  line-height: 21px;
  color: #666;
  text-decoration: none;
  padding-left: 0;
}
.table_itemlist2 .regular_deliver .tooltop_wrap .tooltip_box a:hover {
  text-decoration: underline;
}
.table_itemlist2 .price_unit {
  text-align: center;
}
.table_itemlist2 .price_unit del {
  display: block;
  font-size: 12px;
  color: #888;
  font-family: $fontR;
  font-weight: 400;
  letter-spacing: -0.5px;
}
.table_itemlist2 .price_unit .applied {
  display: inline-block;
  font-family: $fontR;
  font-weight: 400;
  height: 30px;
  color: #3b3b3b;
  line-height: 30px;
}
.table_itemlist2 .price_unit .applied em {
  font-size: 20px;
}
.table_itemlist2 .price_unit .applied .ssg_price {
  vertical-align: -1px;
  letter-spacing: -0.5px;
}
.table_itemlist2 .price_unit .help_sale_info {
  display: inline-block;
  margin-top: -4px;
  margin-left: -2px;
  vertical-align: middle;
}
.table_itemlist2 .price_unit .unit_price {
  display: block;
  font-family: $fontR;
  font-weight: 400;
  color: #888;
  text-align: center;
}
.table_itemlist2 .item_promotion span {
  display: block;
  margin-left: 20px;
  text-align: left;
  font-size: 11px;
  font-family: $fontR;
  font-weight: 400;
  color: #666;
}
.table_itemlist2 .item_promotion span.sale {
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 0;
  background: none;
}
.table_itemlist2 .item_promotion span.enuri .liaison {
  color: #e53838;
  text-decoration: none;
  padding-right: 10px;
  background: url(#{$imgPath}/common/bg_btn_arr06.gif) right 3px no-repeat;
}
.table_itemlist2 .delivery_info span {
  display: block;
  text-align: center;
  color: #222;
  font-family: $fontR;
  font-weight: 400;
}
.table_itemlist2 .item_cart .item_quantity {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 40px;
  height: 21px;
  vertical-align: middle;
}
.table_itemlist2 .item_cart .item_quantity .inpbx {
  width: 28px;
  height: 17px;
  padding: 2px 0 0;
  border-color: #dedede;
}
.table_itemlist2 .item_cart .item_quantity .inpbx input {
  color: #777;
  text-align: center;
}
.table_itemlist2 .item_cart .item_quantity .btn {
  position: absolute;
  right: 0;
  width: 11px;
  height: 11px;
}
.table_itemlist2 .item_cart .item_quantity .btn span {
  overflow: hidden;
  display: block;
  width: 11px;
  height: 11px;
  background: url(#{$imgPath}/common/btn_item_quantity.gif) no-repeat;
  font-size: 0;
  line-height: 100px;
  white-space: nowrap;
}
.table_itemlist2 .item_cart .item_quantity .increase {
  top: 0;
}
.table_itemlist2 .item_cart .item_quantity .increase span {
  background-position: 0 0;
}
.table_itemlist2 .item_cart .item_quantity .decrease {
  bottom: 0;
}
.table_itemlist2 .item_cart .item_quantity .decrease span {
  background-position: 0 -10px;
}
.table_itemlist2 .item_cart .bnbox {
  display: block;
  margin: 5px 0;
}
.table_itemlist2 .item_cart .bnbox .btn {
  background: none;
  border: solid 1px #b9b9b9;
  border-radius: 3px;
  width: 85px;
}
.table_itemlist2 .item_cart .bnbox .btn.weightiest {
  background-color: #ff5657;
  border-color: #ff5657;
}
.table_itemlist2 .item_cart .bnbox .btn span {
  background: none;
  color: #555;
  font-size: 14px;
  line-height: 27px;
}
.table_itemlist2 .item_cart .bnbox .btn.weightiest span {
  color: #fff;
}
.table_itemlist2 .item_cart .bnbox .btn.enter {
  background-color: #ff5657;
  border-color: #ff5657;
}
.table_itemlist2 .item_cart .bnbox .btn.enter span {
  color: #fff;
}
.table_itemlist2 .item_cart .bnbox .btn.change {
  background-color: #ff9b56;
  border-color: #ff9b56;
}
.table_itemlist2 .item_cart .bnbox .btn.change span {
  color: #fff;
}
.table_itemlist2 .item_cart .bnbox .btn.soldout {
  background-color: #989898;
  border-color: #989898;
}
.table_itemlist2 .item_cart .bnbox .btn.soldout span {
  color: #fff;
}
.table_itemlist2 .item_check {
  position: relative;
}
.table_itemlist2 .item_check input {
  position: absolute;
  top: 0;
  left: 10px;
  width: 14px;
  height: 14px;
}
.table_itemlist2 .item_store {
  padding: 0 10px;
}
.table_itemlist2 .item_store span {
  display: block;
  color: #222;
  letter-spacing: -1px;
  line-height: 16px;
  word-break: break-all;
  word-wrap: break-word;
}
.table_itemlist2 .item_opt {
  position: relative;
  margin: -10px 0;
  padding: 0 10px;
}
.table_itemlist2 .item_opt .tx_opt {
  color: #00aac4;
  font-size: 11px;
  letter-spacing: -1px;
  line-height: 16px;
  word-break: break-all;
  word-wrap: break-word;
}
.table_itemlist2 .item_opt .tx_opt_set {
  color: #ff5d54;
  font-weight: bold;
}
.table_itemlist2 .item_bt {
  text-align: right;
}
.table_itemlist2 .item_bt a {
  display: inline-block;
}
.table_itemlist2 .item_bt .bt_gift_send {
  width: 85px;
  height: 29px;
  background: #3fbec7;
  color: #fff;
  font-size: 13px;
  font-family: $fontR;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 29px;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
}
.table_itemlist2 .bt_item_del {
  position: absolute;
  top: 10px;
  right: -5px;
  padding: 5px;
  cursor: pointer;
}
.table_itemlist2 .bt_item_del .ic_item_del {
  display: inline-block;
  overflow: hidden;
  width: 13px;
  height: 13px;
  background: url(#{$imgPath}/order/sp_case.png) -140px -28px no-repeat;
  line-height: 999px;
  vertical-align: top;
}
.item_disabled .item_opt .tx_opt {
  color: #888;
}
.price_date {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  width: 174px;
  margin-left: -94px;
  padding: 0;
  border: 1px solid #666;
  background: #fff;
  font-size: 11px;
  text-align: left;
}
