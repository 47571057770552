.direction button,
.direction a {
  position: absolute;
  top: 50%;
  width: 31px;
  height: 50px;
  margin-top: -25px;
  margin-left: 0;
  background: url(#{$imgPath}/common/sp_p24.png) no-repeat;
  cursor: pointer;
}
.direction .prev {
  left: 0;
  background-position: 0 0;
}
.direction .next {
  right: 0;
  background-position: -33px 0;
}
.pagination {
  clear: both;
  width: 100%;
  height: 24px;
  text-align: center;
}
.search_section .pagination {
  margin-top: 12px;
}
.pagination .dot,
.pagination .dot_on {
  display: inline-block;
  width: 20px;
  height: 24px;
  background: url(#{$imgPath}/common/sp_p24.png) no-repeat 0 -54px;
  vertical-align: top;
  cursor: pointer;
}
.pagination .dot .blind,
.pagination .dot_on .blind {
  width: 1px !important;
  height: 1px !important;
}
.pagination .dot_on {
  background-position: -22px -54px;
}
