.delivery_notice {
  position: relative;
  width: 381px;
  height: 230px;
  margin: 0 auto;
  padding: 20px 399px 0 0;
  background: url(#{$imgPath}/csc/bg_delivery.gif) no-repeat 464px 32px;
}
.delivery_notice h3 {
  margin-top: 38px;
  margin-left: 42px;
  width: 434px;
  height: 106px;
  background: url(#{$imgPath}/csc/txt_delivery.gif) no-repeat 0 0;
}
.delivery_notice ul {
  width: 251px;
  height: 72px;
  background: url(#{$imgPath}/csc/bg_txt_delivery.gif) no-repeat;
  margin-left: 40px;
  margin-top: 146px;
}
.delivery_notice .cs_btn.large {
  position: absolute;
  bottom: 29px;
  right: 93px;
  font-weight: bold;
}
.deliver_info .tab li {
  font-size: 12px;
}
.deliver_info .prim_contarea {
  clear: both;
  width: 100%;
}
.deliver_info .prim_contarea .content {
  display: none !important;
}
.deliver_info .prim_contarea .active {
  display: block !important;
}
.deliver_info .prim_contarea .content .sorting_area {
  float: none;
  height: 40px;
  height: 60px;
  padding: 0;
  border-bottom: 1px dashed #d8d8d8;
}
.deliver_info .prim_contarea .content .sorting_area li {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  float: left;
}
.deliver_info .prim_contarea .content .sorting_area li a {
  line-height: 60px;
  color: #666;
  text-decoration: none;
  font-size: 12px;
}
.deliver_info .prim_contarea .content .sorting_area li.active a {
  letter-spacing: -1px;
  font-weight: bold;
  color: #000;
  text-decoration: underline;
}
.deliver_info .sorting_area li {
  margin-left: 16px;
  padding-left: 17px;
  background: url(#{$imgPath}/display/bg_line.gif) 0 48% no-repeat;
}
.deliver_info .sorting_area li:first-child {
  margin: 0;
  padding: 0;
  background: transparent;
}
.deliver_info h3 {
  clear: both;
  padding: 30px 0 15px;
  font-size: 20px;
  color: #222;
  font-family: $fontR;
  font-weight: 400;
}
.deliver_info .data_list {
  padding: 15px;
  border-top: 1px solid #666;
  border-bottom: 1px solid #e5e5e5;
}
.deliver_info .data_list.delivery_sum {
  height: 20px;
}
.deliver_info .data_list.small {
  overflow: hidden;
}
.deliver_info .data_list.small li {
  line-height: 16px;
  margin-top: 15px;
  padding-left: 9px;
  font-size: 12px;
  color: #666;
  background: url(#{$imgPath}/common/bullet_square_05.gif) no-repeat 0 6px;
}
.deliver_info .data_list.small li:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.deliver_info .data_list.small li:first-child {
  margin-top: 0;
}
.deliver_info .data_list.small li.last:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.deliver_info .data_list.small li.last strong {
  display: block;
  font-weight: bold;
  color: #272727;
}
.deliver_info .data_list.small li.last strong span {
  display: inline;
  font-size: 12px;
  font-weight: normal;
  color: #666;
}
.deliver_info .data_list.small li.last div {
  float: left;
  padding-top: 6px;
  padding-right: 40px;
  color: #222;
}
.deliver_info .data_list.small li.last div strong {
  padding-left: 11px;
}
.deliver_info .data_list.small li.last div strong.common {
  padding-left: 0;
}
.deliver_info .data_list.small li.last div .em.weightiest {
  display: inline;
  color: #e24f4f;
}
.deliver_info .data_list.small li.last p {
  clear: both;
  padding-top: 5px;
}
.deliver_info .data_list.small li.last p.standard {
  color: #666;
  padding-top: 7px;
}
.deliver_info .data_list.small li.last .data_list.small {
  padding: 5px 0 0 0;
  border: 0 none;
}
.deliver_info .data_list.small li.last .data_list.small li {
  margin-top: 0;
  line-height: 16px;
  font-size: 11px;
  color: #777;
  background: url(#{$imgPath}/common/bullet_square_01.gif) left 6px no-repeat;
}
.deliver_info .data_list.small li.last .data_list.payment {
  padding-left: 12px;
}
.deliver_info .data_list.small li span {
  clear: both;
  display: block;
  padding-top: 4px;
  color: #666;
  font-size: 11px;
}
.deliver_info .data_list.small li .tx_point {
  color: #e24f4f;
}
.deliver_info .data_list.small li .deliver_table {
  margin-top: 10px;
}
.deliver_info .data_list.small li .deliver_table span {
  clear: both;
  display: inline;
  padding-top: 4px;
  color: inherit;
  font-size: 12px;
}
.deliver_info .data_list.small li .deliver_table {
  width: 100%;
  table-layout: fixed;
  color: #010101;
}
.deliver_info .data_list.small li .deliver_table th,
.deliver_info .data_list.small li .deliver_table td {
  padding: 10px;
  border: 1px solid #222;
}
.deliver_info .data_list.small li .deliver_table thead th {
  background: #f9f9f9;
}
.deliver_info .data_list.small li .deliver_table thead th,
.deliver_info .data_list.small li .deliver_table thead td {
  text-align: center;
}
.deliver_info .data_list.small li .deliver_table tbody th,
.deliver_info .data_list.small li .deliver_table tbody td {
  text-align: left;
}
.deliver_info .data_list.small li .deliver_table tbody th {
  font-weight: bold;
}
.deliver_info .data_list.small li .deliver_table tbody .txt_light {
  color: #666;
}
.deliver_info .data_list.small li .deliver_table tbody .txt_strong {
  color: #ff4646;
}
.deliver_info .data_list.small li span.ssg_tx {
  display: inline;
  color: #e24f4f;
  font-size: 12px;
}
.deliver_info .data_list.medium:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.deliver_info .data_list.medium li {
  float: left;
  padding-right: 30px;
  color: #272727;
  background-position: 0 7px;
}
