// =============================================================================
// PC scss -> css variable 변환
// =============================================================================
@use 'sass:meta';

@mixin set-colors($colors) {
  @each $color-name, $color-value in $colors {
    $variable-name: str-replace($color-name, '.', '-');
    --m-colors-#{$variable-name}: #{$color-value};
  }
}

// font-family
@mixin set-fonts($fonts) {
  @each $prefix, $map in $fonts {
    @each $name, $value in $map {
      --m-#{$prefix}-#{$name}: #{$value};
    }
  }
}

// font icon
@mixin set-fonts-icon($iconFonts) {
  --m-font-icon: #{$iconFonts};
}

:root {
  @if meta.variable-exists('eshop-colors') {
    @include set-colors($merged-eshop-colors);
    @include set-fonts($merged-eshop-fonts);
    @include set-fonts-icon($eshop-iconFonts);
  } @else {
    @include set-colors($colors);
    @include set-fonts($fonts);
    @include set-fonts-icon($iconFonts);
  }
}
