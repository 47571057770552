/* 휴대폰 상품고시 */
.layer_pop .ph_noti_detail {
  padding-top: 8px;
}
.layer_pop .ph_noti_detail .ph_noti {
  height: 207px;
  border: 1px solid #dadada;
  background: #f9f9f9 url(#{$imgPath}/product/tx_ph_noti.png) no-repeat 50% 50%;
}
.layer_pop .ph_noti_detail .button_area {
  margin-top: 7px;
  zoom: 1;
}
.layer_pop .ph_noti_detail .button_area:after {
  content: '';
  display: block;
  clear: both;
}
.layer_pop .ph_noti_detail .button_area .btn_detail {
  float: left;
  width: 388px;
  height: 53px;
  margin: 0 5px 0 0;
  padding: 0 !important;
  background: #fff url(#{$imgPath}/product/btn_go_detail.gif) no-repeat 50% 50%;
}
.layer_pop .button_area .cm_clip_dtl {
  float: left;
}
.layer_pop .button_area .cm_clip_dtl a.sel_clip {
  margin: 0;
}
.layer_pop .ph_noti_detail .button_area .btn_clip {
  margin-top: 0 !important;
}
