/* location - 삭제예정(s) */
.common_location {
  position: relative;
  z-index: 280;
  width: 1020px;
  height: 19px;
  padding-left: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  background: url(#{$imgPath}/common_layout/bg_location2.gif) repeat-x 0 0 #fff;
}
.body_ssg .common_location {
  position: relative;
  z-index: 9;
  width: 1280px;
  height: 19px;
  padding-left: 0;
  padding-top: 15px;
  padding-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  background: none;
}
.common_location.other {
  background: 0 none;
  border: 0 none;
}
.common_location .lo_depth_01 {
  position: relative;
  float: left;
}
.common_location .lo_depth_01.ico {
  position: relative;
  float: left;
  overflow: hidden;
}
.common_location .lo_depth_01:first-child a.lo_ico {
  height: 19px;
  padding-left: 27px;
  background: url(#{$imgPath}/common_layout/ico_mall.gif) 0 0 no-repeat;
}
.common_location .lo_depth_01 .lo_menu {
  display: inline-block;
  *display: inline;
  height: 20px;
  padding: 0 4px 0 7px;
  font-size: 12px;
  color: #666;
  line-height: 20px;
  text-decoration: none;
}
.common_location .lo_depth_01 a.lo_menu:hover {
  text-decoration: underline;
}
.common_location .lo_depth_01 a.lo_menu.lo_arr {
  padding: 0 21px 9px 7px;
  background: url(#{$imgPath}/common/sp_togle.png) right -2px no-repeat;
  *padding-top: 4px;
}
.common_location .lo_depth_01 a.lo_menu.lo_ico {
  display: block;
  float: left;
  padding-right: 10px;
}
.common_location .lo_depth_01 a.lo_menu.active {
  text-decoration: underline;
  background-position: right -28px;
}
.common_location .lo_depth_01 .lo_line {
  color: #000;
  line-height: 20px;
}
.common_location .main_ico a {
  position: absolute;
  top: 22px;
  left: 0px;
  width: 19px;
  height: 19px;
  background: url(#{$imgPath}/common_layout/ico_mall.gif) 0 0 no-repeat;
}
.common_location .lo_depth_02 {
  display: none;
  position: absolute;
  top: 25px;
  left: 0;
  z-index: 9999;
  width: 182px;
  padding: 4px 0;
  border: 1px solid #d8d8d8;
  border-top: 1px solid #222;
  background-color: #fff;
}
.common_location .lo_depth_02.active {
  display: block;
}
.common_location .lo_depth_02 ul li {
  margin-bottom: 1px;
}
.common_location .lo_depth_02 ul li a {
  display: block;
  width: 168px;
  padding: 0px 7px;
  text-decoration: none;
  color: #666;
  font-size: 12px;
  line-height: 18px;
}
.common_location .lo_depth_02 ul li a.active {
  background-color: #f2f2f2;
}
.common_location .lo_depth_02 ul li a:focus,
.common_location .lo_depth_02 ul li a:active,
.common_location .lo_depth_02 ul li a:hover {
  color: #fff;
  background-color: #f15065;
}
/* location - 삭제예정(e) */
