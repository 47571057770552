.icon_mobile {
  display: block;
  margin-top: 1px;
  width: 10px;
  height: 16px;
  background: url(#{$imgPath}/display/icon_gray_mobile.gif) no-repeat left top;
  text-indent: -9999px;
}
.icon_mall {
  float: left;
  width: 17px;
  height: 17px;
  margin-right: 1px;
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) no-repeat 0 -190px;
  font-size: 0;
  line-height: 0;
}
.icon_mall.ssgmall {
  background-position: 0 -219px;
}
.icon_mall.depart {
  background-position: 0 -248px;
}
.icon_mall.emart {
  background-position: 0 -277px;
}
.icon_mall.traders {
  background-position: 0 -306px;
}
.icon_mall.boons {
  background-position: 0 -335px;
}
.icon_mall.howdy {
  background-position: 0 -364px;
}
.icon_mall.boots {
  background-position: -38px -161px;
}
.icon_mall.tv {
  background-position: 0 -161px;
}
.icon_mall.si {
  background-position: -38px -364px;
}
.icon_mall.outlet {
  background-position: -65px -190px;
}
.icon_mall.mnmorning {
  background-position: -65px -219px;
}
