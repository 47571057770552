.data_list {
  position: relative;
}
.data_list li {
  color: #666;
}
.data_list li a.wrap {
  display: block;
  color: #333;
  text-decoration: none;
}
ol.data_list {
  padding-left: 2em;
}
ol.data_list.small {
  padding-left: 26px;
}
ol.data_list.small li {
  position: relative;
  padding-left: 10px;
  line-height: 16px;
  vertical-align: top;
}
ol.data_list.small li {
  margin-top: 12px;
}
ol.data_list.small li:first-child {
  margin-top: 0;
}
ol.data_list.small li a {
  display: inline-block;
  position: relative;
  min-height: 16px;
  line-height: 16px;
  text-decoration: none;
}
ol.data_list.small li li a {
  display: inline;
  position: inherit;
  min-height: 0;
  line-height: inherit;
  text-decoration: none;
}
ol.data_list.small li .num {
  display: block;
  position: absolute;
  top: 0;
  left: -26px;
  width: 20px;
  height: 16px;
  background: #fff url(#{$imgPath}/common/ico_num_list.gif) no-repeat center top;
  line-height: 16px;
}
ul.data_list.small li {
  padding-left: 8px;
  background: url(#{$imgPath}/common/bullet_square_01.gif) no-repeat 0 6px;
  line-height: 16px;
  color: #777777;
  font-size: 11px;
}
ul.data_list.small li {
  margin-top: 2px;
}
ul.data_list.small li:first-child {
  margin-top: 0;
}
ul.data_list.small li a {
  text-decoration: none;
}
ul.data_list.medium li {
  padding-left: 8px;
  background: url(#{$imgPath}/common/bullet_square_07.gif) no-repeat 0 6px;
  line-height: 16px;
  color: #666;
  font-family: $fontR;
  font-weight: 400;
}
ul.data_list.medium li {
  margin-top: 2px;
}
ul.data_list.medium li:first-child {
  margin-top: 0;
}
ul.data_list.medium li a {
  text-decoration: none;
}
.item_list {
  overflow: hidden;
}
.item_list:after {
  display: block;
  visibility: hidden;
  clear: both;
  width: 0;
  height: 0;
  content: '';
}
.item_list li {
  position: relative;
}
.item_list li:after {
  display: block;
  visibility: hidden;
  clear: both;
  width: 0;
  height: 0;
  content: '';
}
.item_list .wrap {
  line-height: 25px;
  text-decoration: none;
}
.item_list .thumbnail a {
  display: block;
}
.item_list .thumbnail a img {
  display: block;
}
.item_list,
.item_list li,
.item_list .wrap {
  *zoom: 1;
}
.content_intro {
  position: relative;
}
.content_intro .button_area {
  position: absolute;
  right: 0;
  bottom: 2px;
}
.slidings {
  position: relative;
  padding: 0 30px;
}
.slidings:after {
  display: block;
  visibility: hidden;
  clear: both;
  width: 0;
  height: 0;
  content: '';
}
.item_display,
.item_display li {
  position: relative;
}
.item_display:after,
.item_display li:after {
  display: block;
  clear: both;
  content: '';
}
.item_display,
.item_display li {
  *zoom: 1;
}
ul.item_display.simple li {
  float: left;
}
ul.item_display.simple li .thumbnail {
  float: left;
  position: relative;
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
ul.item_display.simple li .thumbnail img {
  display: block;
}
ul.item_display.simple li .thumbnail a {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(#{$imgPath}/@temp/btn_zoom_01.gif) 100% 100% no-repeat;
  text-indent: -999em;
}
ul.item_display.simple li .item_decision {
  position: absolute;
  top: 75px;
  left: 0;
  width: 100px;
  height: 25px;
}
ul.item_display.simple li .item_decision .btn {
  display: block;
  overflow: hidden;
  width: 100px;
  height: 25px;
  padding: 0;
  border: 1px solid #e9e9e9;
  background-color: #fff;
}
ul.item_display.simple li .item_decision .btn img {
  display: block;
}
ul.item_display.simple li .summary a {
  text-decoration: none;
}
ul.item_display.simple li .item_info {
  overflow: hidden;
  float: none;
}
ul.item_display.simple li .item_info .price_unit {
  line-height: 16px;
}
ul.item_display.simple li .item_info .price_unit del {
  font-size: 11px;
  color: #a1a1a1;
}
ul.item_display.simple li .item_info .price_unit span {
  font-size: 11px;
}
ul.item_display.simple li .item_info .price_unit ins {
  display: block;
  font-weight: bold;
}
ul.item_display.simple li .item_decision {
  *left: -4px;
}
