.sp_pc {
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: 10px;
  background: url(#{$imgPath}/mem/sp_pcomment.png) no-repeat 0 0;
  line-height: 999px;
  vertical-align: top;
}
.content_search {
  *zoom: 1;
  float: right;
  width: 205px;
  height: 36px;
  background-image: url(#{$imgPath}/layout/bg_content_search.gif);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  vertical-align: top;
}
.content_search:after {
  content: '';
  display: block;
  clear: both;
  width: 0;
  height: 0;
  visibility: hidden;
}
.content_search label {
  display: block;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  font-size: 1px;
  line-height: 0;
  text-indent: -999em;
  white-space: nowrap;
}
.content_search button {
  float: left;
  position: relative;
  top: 3px;
  width: 30px;
  height: 30px;
}
.content_search button .ir {
  padding: 0;
  margin: 0;
  background-image: url(#{$imgPath}/layout/bg_btn_search.gif);
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  overflow: visible;
  background-position: 0 0;
  cursor: pointer;
}
.content_search input {
  float: left;
  width: 169px;
  height: 30px;
  padding: 0 10px;
  margin: 3px 0 0 3px;
  font-size: 12px;
  border: 0 none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  vertical-align: middle;
}
.explorer .content_search input {
  line-height: 30px;
}
.content_search input {
  *width: 149px;
}
