.myssg_nav.active {
  display: block;
}
.myssg_nav {
  display: none;
  position: absolute;
  z-index: 9999;
  top: 77px;
  left: -1px;
  width: 1018px;
  background: white;
  border: solid #203a4d;
  border-width: 4px 1px 1px;
}
.myssg_nav .mn_btn_area {
  position: relative;
  height: 32px;
  padding: 7px 0;
  background: #f6f6f6;
  text-align: center;
}
.myssg_nav.setting .mn_btn_area {
  text-align: center;
}
.myssg_nav .mn_btn_area .mn_btn {
  display: none;
}
.myssg_nav .mn_btn_area .btn_myssg_close {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  padding: 14px;
  border-left: 1px solid #fff;
}
.myssg_nav .mn_btn_area .btn_myssg_close img {
  vertical-align: top;
}
.myssg_nav .mn_btn_area .mn_btn.type01.set {
  display: inline-block;
}
.myssg_nav.setting .mn_btn_area .mn_btn {
  display: inline-block;
}
.myssg_nav.setting .mn_btn_area .mn_btn.type02 {
  margin-left: 1px;
}
.myssg_nav.setting .mn_btn_area .mn_btn.set,
.myssg_nav.setting .mn_btn_area .txt_set_ad {
  display: none;
}
.myssg_nav .mn_btn_area .txt_set_ad {
  margin-left: 8px;
}
.myssg_nav .mn_list_area {
  width: 1018px;
}
.myssg_nav .mn_list_area .mn_list_all {
  width: 1018px;
  zoom: 1;
  overflow: hidden;
}
.myssg_nav .mn_list_area .mn_list_all:after {
  display: block;
  clear: both;
  content: '';
}
.myssg_nav .mn_list_area .mn_list_all .mn_list_01 {
  float: left;
  width: 168px;
}
.myssg_nav .mn_list_area .mn_list_all .mn_list_02,
.myssg_nav .mn_list_area .mn_list_all .mn_list_03,
.myssg_nav .mn_list_area .mn_list_all .mn_list_04,
.myssg_nav .mn_list_area .mn_list_all .mn_list_05,
.myssg_nav .mn_list_area .mn_list_all .mn_list_06 {
  float: left;
  width: 170px;
}
.myssg_nav .mn_list_area .mn_list_all .mn_list_tit {
  display: block;
  width: 149px;
  height: 23px;
  padding: 18px 0 17px 20px;
  border-left: 1px solid #c4d9e9;
  background: #e0effd;
  text-align: left;
  color: #203a4d;
  font-size: 17px;
  font-weight: bold;
  line-height: 26px;
  position: relative;
}
.myssg_nav .mn_list_area .mn_list_all .mn_list_01 .mn_list_tit {
  width: 148px;
  border-left: 0;
}
.myssg_nav .mn_list_area .mn_list_all .mn_list_01 .depth2 {
  border-left: 0;
}
.myssg_nav .mn_list_area .mn_list_all .mn_list_01 .mn_list_tit:after {
  content: '';
  position: absolute;
  top: 19px;
  right: 12px;
  width: 28px;
  height: 23px;
  background: url(#{$imgPath}/mem/img_mm_01_v2.png) 100% 0 no-repeat;
}
.myssg_nav .mn_list_area .mn_list_all .mn_list_02 .mn_list_tit:after {
  content: '';
  position: absolute;
  top: 19px;
  right: 12px;
  width: 28px;
  height: 23px;
  background: url(#{$imgPath}/mem/img_mm_02_v2.png) 100% 0 no-repeat;
}
.myssg_nav .mn_list_area .mn_list_all .mn_list_03 .mn_list_tit:after {
  content: '';
  position: absolute;
  top: 19px;
  right: 12px;
  width: 28px;
  height: 23px;
  background: url(#{$imgPath}/mem/img_mm_03_v2.png) 100% 0 no-repeat;
}
.myssg_nav .mn_list_area .mn_list_all .mn_list_04 .mn_list_tit:after {
  content: '';
  position: absolute;
  top: 19px;
  right: 12px;
  width: 28px;
  height: 23px;
  background: url(#{$imgPath}/mem/img_mm_04_v2.png) 100% 0 no-repeat;
}
.myssg_nav .mn_list_area .mn_list_all .mn_list_05 .mn_list_tit:after {
  content: '';
  position: absolute;
  top: 19px;
  right: 12px;
  width: 28px;
  height: 23px;
  background: url(#{$imgPath}/mem/img_mm_05_v2.png) 100% 0 no-repeat;
}
.myssg_nav .mn_list_area .mn_list_all .mn_list_06 .mn_list_tit:after {
  content: '';
  position: absolute;
  top: 19px;
  right: 12px;
  width: 28px;
  height: 23px;
  background: url(#{$imgPath}/mem/img_mm_06_v2.png) 100% 0 no-repeat;
}
:lang(en) .myssg_nav .mn_list_area .mn_list_all .mn_list_tit {
  width: auto;
  font-size: 14px;
  line-height: 16px;
  height: 58px;
  padding: 0 18px;
  position: relative;
}
:lang(zh) .myssg_nav .mn_list_area .mn_list_all .mn_list_tit {
  line-height: 18px;
}
:lang(zh) .myssg_nav .mn_list_area .mn_list_all .mn_list_tit:after {
  top: 16px;
}
:lang(en) .myssg_nav .mn_list_area .mn_list_all .mn_list_tit em {
  position: absolute;
  top: 50%;
  left: 18px;
  right: 18px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
:lang(en) .myssg_nav .mn_list_area .mn_list_all .mn_list_tit:after {
  display: none;
}
:lang(en) .myssg_nav .mn_list_area .mn_list_all .depth2,
:lang(zh) .myssg_nav .mn_list_area .mn_list_all .depth2 {
  height: auto;
  border-left: none;
  position: relative;
}
:lang(en) .myssg_nav .mn_list_area .mn_list_all .depth2:after,
:lang(zh) .myssg_nav .mn_list_area .mn_list_all .depth2:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 500%;
  width: 1px;
  background-color: #eef0f2;
}
:lang(en) .myssg_nav .mn_list_area .mn_list_all .depth2:first-child:after,
:lang(zh) .myssg_nav .mn_list_area .mn_list_all .depth2:first-child:after {
  display: none;
}

.myssg_nav .mn_list_area .mn_list_all .depth2 {
  padding-top: 16px;
  border-left: 1px solid #eef0f2;
}
.myssg_nav .mn_list_area .mn_list_all .depth2 li {
  overflow: hidden;
  float: none;
  margin-bottom: 10px;
  padding-left: 20px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -1px;
  text-align: left;
}
.myssg_nav .mn_list_area .mn_list_all .depth2 li.rel {
  position: relative;
}
.myssg_nav .mn_list_area .mn_list_all .depth2 li a {
  display: block;
  float: left;
  width: 130px;
  color: #778793;
  text-decoration: none;
}
.myssg_nav .mn_list_area .mn_list_all .depth2 li a:hover,
.myssg_nav .mn_list_area .mn_list_all .depth2 li a:active,
.myssg_nav .mn_list_area .mn_list_all .depth2 li a:focus {
  color: #e24f4f;
  text-decoration: underline;
}
.myssg_nav .mn_list_area .mn_list_all .depth2 li.active a {
  font-weight: bold;
  color: #e24f4f;
  text-decoration: none;
}
.myssg_nav .mn_list_area .mn_list_all .depth3 {
  position: relative;
  float: left;
  margin: 4px 0 -4px 5px;
  padding-left: 10px;
  background: url(#{$imgPath}/mem/bg_bul.gif) 0 8px no-repeat;
  zoom: 1;
}
.myssg_nav .mn_list_area .mn_list_all .depth3:after {
  display: block;
  clear: both;
  content: '';
}
.myssg_nav .mn_list_area .mn_list_all .depth3 li {
  overflow: inherit;
  float: left;
  margin: 0 0 0 5px;
  padding-left: 7px;
  background: url(#{$imgPath}/mem/bg_bar_04.gif) 0 3px no-repeat;
}
.myssg_nav .mn_list_area .mn_list_all .depth3 li:first-child {
  margin-left: 0;
  padding-left: 0;
  background: none;
}
.myssg_nav .mn_list_area .mn_list_all .depth3 li a {
  display: inline-block;
  float: none;
  width: auto;
  font-size: 12px;
  color: #8e9397;
  text-decoration: none;
  vertical-align: top;
}
.myssg_nav .mn_list_area .mn_list_all .depth3 li a:hover {
  color: #8e9397;
}
.myssg_nav.setting .mn_list_area .mn_list_all .depth2 li {
  padding-left: 10px;
}
.myssg_nav.setting .mn_list_area .mn_list_all .depth2 li .st_toggle {
  display: block;
  float: left;
  width: 18px;
  height: 12px;
  margin-top: 2px;
  background: url(#{$imgPath}/mem/bg_btn_star.png) no-repeat;
}
.myssg_nav.setting .mn_list_area .mn_list_all .depth2 li .st_toggle.none {
  background: none;
}
.myssg_nav.setting .mn_list_area .mn_list_all .depth2 li.active .st_toggle {
  background-position: 0 -15px;
}
.myssg_nav.setting .mn_list_area .mn_list_all .depth3 {
  margin-left: 15px;
}
.myssg_nav.setting .mn_list_area .mn_list_all .depth3 li {
  padding-left: 7px;
}
.myssg_nav.setting .mn_list_area .mn_list_all .depth3 li:first-child {
  padding-left: 0;
}
