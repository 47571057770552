.bn_ar {
  overflow: hidden;
  text-align: center;
  zoom: 1;
  margin-top: 20px;
}
.bn_ar.mobile {
  padding: 0 0 80px 0;
}
.bn_ar .bn {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  min-width: 140px;
  border: 1px solid #3b3b3b;
  background-color: #3b3b3b;
  color: #fff;
  line-height: 38px;
  height: 40px;
  border-radius: 4px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  margin-left: 5px;
}
.bn_ar .bn:first-child {
  margin-left: 0;
}
.bn_ar .bn.btn_login {
  width: 110px;
  min-width: 110px;
  height: 90px;
  line-height: 90px;
}
.bn_ar .bn.btn_login_large {
  width: 110px;
  min-width: 110px;
  height: 140px;
  line-height: 140px;
}
.bn_ar .bn.xsmall {
  width: 150px;
  height: 36px;
  line-height: 36px;
  font-size: 15px;
  letter-spacing: -1px;
}
.bn_ar .bn.small {
  width: 160px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  letter-spacing: -1px;
}
.bn_ar .bn.medium {
  width: 190px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  letter-spacing: -1px;
}
.bn_ar .bn.large {
  width: 260px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  letter-spacing: -1px;
}
.bn_ar button.bn {
  line-height: 38px;
  cursor: pointer;
}
.bn_ar button.bn.xsmall {
  line-height: 31px;
}
.bn_ar button.bn.small {
  line-height: 35px;
}
.bn_ar button.bn.medium,
.bn_ar button.bn.large {
  line-height: 45px;
}
.bn_ar .bn.color1 {
  background-color: #3b3b3b;
  border-color: #3b3b3b;
  color: #fff;
}
.bn_ar .bn.color2 {
  background-color: #f9f9f9;
  border-color: #ccc;
  color: #222;
}
.bn_ar .bn.color3 {
  background-color: #f14f4f;
  border-color: #f14f4f;
}
.bn_ar .bn.txt_ty2 {
  font-family: $fontR;
  font-weight: 400;
}
.login .bn_ar {
  margin-top: 13px;
}
.login .bn_ar .btn_login {
  position: absolute;
  top: 0;
  right: 21px;
}
.nonmember .bn_ar {
  text-align: center;
}
.nonmember .section_wrap .bn_ar {
  margin: 10px 0 0 92px;
  text-align: left;
}
.login .bn_ar .bn.xsmall {
  width: 123px;
  margin-left: 0;
}
.member_certification .bn_ar .btn_login {
  position: absolute;
  top: 0;
  right: 0px;
}
.b2e_member .bn_ar .btn_login_large {
  position: absolute;
  top: 20px;
  right: 0px;
}
.adult_certify .bn_ar .btn_login {
  position: absolute;
  top: 0;
  right: 0px;
}
.layer_pop .bn_ar {
  margin-top: 0;
  padding-bottom: 30px;
  text-align: center;
  zoom: 1;
}
.login .content_support .bn_ar {
  margin: 0;
  text-align: right;
  padding-top: 0;
}
.login .content_support .bn_ar .xsmall {
  color: #666;
}
.login .content_support .bn_ar .xsmall.color1 {
  color: #fff;
}
.please_comment .bn_ar .bn.large,
.content_main .section .clip_data_nbox .bn_ar .bn.large {
  height: 40px;
  font-size: 17px;
  line-height: 40px;
}
