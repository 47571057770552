.myssg_profile_wrap {
  width: 88px;
  height: 88px;
  color: #222;
  font-size: 13px;
  font-family: $fontR;
  font-weight: 400;
  .myssg_profile_thmbimg {
    position: absolute;
    top: 18px;
    left: 14px;
    display: block;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
  }

  .myssg_profile {
    position: absolute;
    top: 53px;
    left: 48px;
    width: 30px;
    height: 30px;

    &.on .myssg_profile_tooltip {
      display: block;
    }
  }

  .myssg_btn_profile {
    display: block;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    overflow: hidden;
    .ico_profile {
      display: block;
      background: url(#{$imgPath}/mem/ico_profile.png) no-repeat;
      width: 30px;
      height: 30px;
      @media screen and (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(#{$imgPath}/mem/ico_profile@2x.png);
        background-size: 30px auto;
      }
    }
  }

  .myssg_profile_tooltip {
    position: absolute;
    top: 100%;
    left: -53px;
    display: none;
    margin-top: 10px;
    background: #fff;
    width: 377px;
    padding: 30px 20px;
    border: 1px solid #e7e7e7;
    border-radius: 6px;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;

    &:before {
      position: absolute;
      top: -9px;
      left: 58px;
      display: block;
      width: 0;
      height: 0;
      border: solid transparent;
      border-width: 0 8px 10px 8px;
      border-bottom-color: #fff;
      content: '';
    }
  }

  .myssg_profile_thmb {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      vertical-align: top;
      border-radius: 50%;
    }

    .myssg_btn_change_thmb {
      position: absolute;
      bottom: -4px;
      right: 0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      overflow: hidden;

      .ico_profile {
        display: block;
        background: url(#{$imgPath}/mem/ico_profile.png) no-repeat;
        width: 30px;
        height: 30px;
        @media screen and (-webkit-min-device-pixel-ratio: 2) {
          background-image: url(#{$imgPath}/mem/ico_profile@2x.png);
          background-size: 30px auto;
        }
      }
    }
  }

  .myssg_profile_titarea {
    margin-top: 20px;
    text-align: center;

    .myssg_profile_tit {
      font-size: 20px;
      letter-spacing: -0.5px;
      font-weight: bold;
    }
    .myssg_profile_subtit {
      margin-top: 9px;
      font-size: 13px;
      color: #888;
      line-height: 1.38;
      letter-spacing: -0.32px;
    }
  }

  .myssg_profile_sec {
    margin: 30px 10px 0;
  }

  .myssg_form_tit {
    margin-top: 15px;
    margin-bottom: 4px;
    font-size: 13px;
    letter-spacing: -0.32px;
  }

  .myssg_inp_txt input {
    width: 100%;
    height: 35px;
    padding: 11px 15px 9px;
    letter-spacing: -0.35px;
    line-height: 1.29;
    font-size: 13px;
    font-family: $fontR;
    font-weight: 400;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    color: #222;
    outline: 0;
    box-shadow: none;
  }
  .myssg_inp_txtarea textarea {
    width: 100%;
    height: 56px;
    padding: 10px 15px;
    border: 1px solid #e0e0e0;
    letter-spacing: -0.35px;
    line-height: 1.29;
    font-size: 13px;
    font-family: $fontR;
    font-weight: 400;
    color: #222;
    background-color: #fff;
    box-sizing: border-box;
    outline: 0;
    resize: none;
    box-shadow: none;
  }

  .myssg_tooltip_btnarea {
    margin-top: 30px;
    font-size: 0;

    .myssg_tooltip_btn {
      width: 165px;

      & + .myssg_tooltip_btn {
        margin-left: 5px;
      }
    }
  }
  .myssg_tooltip_btn {
    padding: 14px 10px;
    font-size: 13px;
    color: #222;
    letter-spacing: -0.24px;

    &.ty_gray {
      background-color: #e5e5e5;
    }

    &.ty_red {
      background-color: get-color('primary');
      color: #fff;
    }
  }
}
