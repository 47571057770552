.layer_pos {
  position: absolute;
  z-index: 999999;
}
.layer_pos.w270 {
  width: 270px;
}
.layer_pos.w360 {
  width: 360px;
}
.layer_pos.w415 {
  width: 415px;
}
.layer_pos.w540 {
  width: 540px;
}
.layer_area {
  position: relative;
  padding: 30px 0 20px;
  border: 1px solid #666;
  border-top: 2px solid #000;
  background: #fff;
  text-align: center;
}
.layer_area .lp_tit {
  color: #666;
  font-size: 13px;
}
.layer_area .lp_tit .point {
  color: #f14f4f;
  font-size: 16px;
  font-family: $fontR;
  font-weight: 400;
}
.layer_area .lp_tit .point02 {
  color: #f4a600;
  font-size: 16px;
  font-family: $fontR;
  font-weight: 400;
}
.layer_area .btn_area {
  margin-top: 25px;
  text-align: center;
}
.layer_area .btn_area a {
  display: inline-block;
  min-width: 78px;
  border: 1px solid #3b3b3b;
  border-radius: 3px;
  background: #3b3b3b;
  font-weight: bold;
  line-height: 26px;
  color: #fff;
  vertical-align: top;
  text-decoration: none;
}
.layer_area .btn_area a.lp_clip_close {
  border: 1px solid #b0b0b0;
  border-radius: 3px;
  background: #b0b0b0;
}
.layer_area2 {
  position: relative;
  border: 1px solid #666;
  border-top: 2px solid #000;
  background: #fff;
  text-align: left;
}
.layer_area2 .lp_close {
  display: inline-block;
  overflow: hidden;
  position: absolute;
  right: 20px;
  top: 15px;
  width: 19px;
  height: 19px;
  background: url(#{$imgPath}/common/btn_close_common.png) no-repeat 0 0;
  line-height: 999px;
}
.cont_pd {
  padding: 47px 20px 0;
}
.rbtn_pd {
  padding: 20px 140px 20px 0;
  border-top: 1px dotted #666;
  zoom: 1;
}
.type2 .rbtn_pd {
  padding: 15px 140px 15px 0;
  border-top: 1px dotted #666;
  zoom: 1;
}
.type3 .rbtn_pd {
  padding: 20px 140px 10px 0;
  border-top: 1px dotted #666;
  zoom: 1;
}
.type3 .rbtn_pd .lp_tit {
  top: 60%;
}
.type3 .rbtn_pd .btn_abs {
  top: 60%;
}
.rbtn_pd .btn_abs {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -10px;
}
.rbtn_pd .lp_tit {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -10px;
}
.rbtn_pd .btn_abs a {
  display: inline-block;
  min-width: 80px;
  border: 1px solid #3b3b3b;
  border-radius: 3px;
  background: #3b3b3b;
  font-family: $fontR;
  font-weight: 400;
  font-size: 15px;
  line-height: 38px;
  color: #fff;
  vertical-align: top;
  text-decoration: none;
  text-align: center;
}
.v_tbl,
.v_tbl th,
.v_tbl td {
  margin: 0;
  padding: 0;
  border: 0 none;
  border-spacing: 0;
}
.v_tbl {
  width: 100%;
  height: 70px;
}
.v_tbl td .lp_img {
  display: inline-block;
  border: 1px solid #e5e5e5;
  vertical-align: middle;
}
.v_tbl td .lp_img img {
  vertical-align: middle;
}
.v_tbl td .lp_tit {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  margin-left: 100px;
  font-size: 16px;
  line-height: 24px;
  vertical-align: middle;
}
.type2 .v_tbl td .lp_tit,
.type3 .v_tbl td .lp_tit {
  margin-left: 20px;
}
.layer_area2 .v_tbl td .lp_ab.yellow {
  position: absolute;
  top: 11px;
  left: 20px;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding-left: 25px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  vertical-align: middle;
  background: url(#{$imgPath}/common/ico_heart_yellow.gif) no-repeat 0 3px;
}
.layer_area2 .v_tbl td .lp_ab.red {
  position: absolute;
  top: 11px;
  left: 20px;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding-left: 25px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  vertical-align: middle;
  background: url(#{$imgPath}/common/ico_heart_red.gif) no-repeat 0 3px;
}
.v_tbl td .lp_ab .point {
  color: #e24f4f;
}
.v_tbl td .lp_tit .point {
  color: #e24f4f;
}
.v_tbl td .lp_tit .p_tip {
  display: block;
  font-size: 12px;
  color: #666;
}
.ftip_area {
  padding: 8px 20px;
  border-top: 1px solid #e9e9e9;
  background: #f9f9f9;
  font-size: 11px;
  color: #747474;
  letter-spacing: -1px;
}
.ftip_area .ico_tip {
  display: inline-block;
  overflow: hidden;
  width: 30px;
  height: 16px;
  margin-right: 10px;
  background: url(#{$imgPath}/common/ico_clip_tip.png) no-repeat 0 0;
  line-height: 999px;
  vertical-align: middle;
}
.pop_btn_area {
  padding-bottom: 30px;
  text-align: center;
  zoom: 1;
}
.pop_btn_area:after {
  display: block;
  clear: both;
  content: '';
}
.pop_btn_area a,
.pop_btn_area button {
  display: inline-block;
  min-width: 148px;
  margin: 0 5px;
  padding: 0 5px;
  border: 1px solid #000;
  border-radius: 3px;
  background: #3b3b3b;
  font-family: $fontR;
  font-weight: 400;
  font-size: 16px;
  line-height: 38px;
  color: #fff;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
}
.pop_btn_area a.color2,
.pop_btn_area button.color2 {
  border-color: #cc2929;
  background-color: #f14f4f;
}
.pop_btn_area a.color3,
.pop_btn_area button.color3 {
  border-color: #ccc;
  background-color: #f9f9f9;
  color: #000;
  box-shadow: inset 0 1px 0 0 #fefefe;
}
.pop_btn_area a.color4,
.pop_btn_area button.color4 {
  border: 1px solid #3b3b3b;
  background: #3b3b3b;
  color: #fff;
}
.pop_btn_area a.color5,
.pop_btn_area button.color5 {
  border: none;
  background: #f14f4f;
  color: #fff;
  line-height: 40px;
}
.pop_btn_area a.color6,
.pop_btn_area button.color6 {
  border: none;
  background: #4faaf1;
  color: #fff;
  line-height: 40px;
}
.pop_btn_area a.color7,
.pop_btn_area button.color7 {
  border: none;
  background: #ff6015;
  color: #fff;
  line-height: 40px;
}
.pop_btn_area .ico_prev {
  width: 7px;
  height: 13px;
  margin-right: 9px;
  background-position: -72px -92px;
  vertical-align: middle;
}
.pop_btn_area .btn_l {
  display: inline;
  float: left;
}
.pop_btn_area .btn_l a {
  margin: 0 10px 0 0;
}
.pop_btn_area .btn_r {
  display: inline;
  float: right;
}
.pop_btn_area .btn_r a {
  margin: 0 0 0 10px;
}
.pop_btn_area.margin {
  margin-top: 20px;
}

.layer_pop .section {
  color: #666;
}
.layer_pop .advice {
  font-size: 12px;
  text-align: center;
  color: #222;
}
.layer_pop .advice.marginT {
  margin-top: 30px;
  color: #666;
}
.layer_pop .advice.marginB {
  margin-bottom: 30px;
}
.layer_pop .notification {
  margin-bottom: 5px;
  padding-left: 20px;
  background: url(#{$imgPath}/common/ico_notice02.gif) no-repeat 0 1px;
  line-height: 20px;
  color: #222;
  font-size: 12px;
}
.layer_pop .notification.small {
  margin: 13px 0 8px;
  padding-left: 17px;
  background: url(#{$imgPath}/common/ico_notice05.gif) no-repeat 0 1px;
  line-height: 17px;
  font-size: 11px;
  color: #747474;
}
.layer_pop .tbl_data_scroll .heading th {
  padding: 10px 0;
  background: #f2f2f2;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  border-top: 1px solid #666;
  border-bottom: solid 1px #d1d1d1;
  color: #222;
  line-height: 20px;
}
.layer_pop .tbl_data_scroll .heading td {
  background: #f2f2f2;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  border-bottom: solid 1px #d1d1d1;
  color: #222;
}
.layer_pop .tbl_data thead td {
  background: #f9f9f9;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  border-bottom: solid 1px #e5e5e5;
  height: 42px;
}
.layer_pop .tbl_data td {
  color: #222;
}
.layer_pop .lp_allcancel .tbl_data td {
  font-size: 11px;
}
.layer_pop .tbl_data dl {
  zoom: 1;
}
.layer_pop .tbl_data dl:after {
  display: block;
  clear: both;
  content: '';
}
.layer_pop .tbl_data dl dt {
  float: left;
  padding-left: 4px;
  color: #888;
  background: url(#{$imgPath}/common/bg_bullet04.gif) no-repeat 0 6px;
}
.layer_pop .tbl_data dl dd {
  float: left;
}
.layer_pop .tbl_data .dt {
  padding-left: 4px;
  background: url(#{$imgPath}/common/bg_bullet04.gif) no-repeat 0 6px;
}
.layer_pop .tbl_data ul.txt_list li {
  padding-left: 4px;
  background: url(#{$imgPath}/common/bg_bullet04.gif) no-repeat 0 6px;
}
.layer_pop .num_list {
  font-family: $fontR;
  font-weight: 400;
  color: #666;
}
.layer_pop .num_list li {
  position: relative;
  padding: 13px 0 12px 38px;
  border-bottom: 1px solid #ededed;
}
.layer_pop .num_list li:first-child {
  padding-top: 0;
}
.layer_pop .num_list li:first-child .ir {
  top: 4px;
}
.layer_pop .num_list li .ir {
  position: absolute;
  left: 0;
  top: 18px;
  width: 27px;
  height: 17px;
  background: url(#{$imgPath}/common/ranking_large.png) no-repeat;
  text-indent: -9999px;
}
.layer_pop .num_list li.num_02 .ir {
  background-position: 0 -20px;
}
.layer_pop .num_list li.num_03 .ir {
  background-position: 0 -40px;
}
.layer_pop .num_list li.num_04 .ir {
  background-position: 0 -60px;
}
.layer_pop .num_list li.num_05 .ir {
  background-position: 0 -80px;
}
.layer_pop .data_list.medium.line {
  padding-bottom: 30px;
  border-bottom: 1px solid #ebebeb;
}
.deliver_info .data_list.medium li {
  color: #222 !important;
}
.layer_pop .data_list.small.line {
  padding-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
}
.change_option .prod_name {
  margin-bottom: 30px;
  padding: 11px 10px;
  border-top: 1px solid #666;
  border-bottom: 1px solid #e9e9e9;
  font-size: 12px;
  font-weight: bold;
}
.change_option .select {
  width: 202px;
}
.lp_deposit .summary {
  margin-bottom: 25px;
  font-size: 12px;
  color: #272727;
}
.lp_return .select {
  width: 207px;
}
.lp_return .lp_ibx {
  width: 207px;
  height: 28px;
  padding: 0 10px;
}
.lp_return .lp_ibx .i_text {
  width: 202px;
  height: 26px;
  padding: 0 0 0 5px;
  line-height: 26px\9;
  border: 1px solid #9e9e9e;
  background: transparent;
}
.lp_return .lp_ibx .i_text2 {
  width: 130px;
  height: 26px;
  padding: 0 0 0 5px;
  line-height: 26px\9;
  border: 1px solid #9e9e9e;
  border-right: 0;
  background: transparent;
  vertical-align: middle;
}
.lp_return .lp_ibx .i_submit {
  width: 30px;
  height: 28px;
  margin: 0 0 0 -4px;
  padding: 0;
  border: 1px solid #9e9e9e;
  background: url(#{$imgPath}/common/btn_open_calendar02.gif) no-repeat -1px 0;
  vertical-align: middle;
  text-indent: -9999em;
}
.lp_guide h3 {
  background: none;
  font-size: 18px;
  text-align: left;
  color: #222;
}
.layer_pop .guide_tbx {
  margin: 10px 0 20px;
  padding: 16px 10px;
  border-top: 1px solid #666;
  border-bottom: 1px solid #e9e9e9;
}
.layer_pop p.guide_tbx {
  background: url(#{$imgPath}/common/bullet_square_01.gif) no-repeat 12px 24px;
  color: #666;
}
.layer_pop .desc_list {
  margin-top: 20px;
  color: #666;
  font-family: $fontR;
  font-weight: 400;
  line-height: 16px;
}
.layer_pop .desc_list:first-child {
  margin: 0;
}
.layer_pop .desc_list .point {
  color: #00aac4;
}
.layer_pop .desc_list dd {
  padding-top: 5px;
}
.layer_pop .data_list.medium li {
  margin-top: 8px;
}
.layer_pop .data_list.medium li:first-child {
  margin-top: 0;
}
.layer_pop .guide_tbx .order_addr {
  font-size: 11px;
  color: #777;
  font-family: $fontR;
  font-weight: 400;
}
.layer_pop .guide_tbx .order_addr strong {
  color: #222;
}
.layer_pop .bx_clear {
  zoom: 1;
}
.layer_pop .bx_clear:after {
  display: block;
  clear: both;
  content: '';
}
.layer_pop .bx_clear .fl {
  float: left;
}
.layer_pop .bx_clear .fl.pdT {
  padding-top: 10px;
  color: #222;
}
.layer_pop .bx_clear .fr {
  float: right;
}
.layer_pop .bx_clear .fr.pop_btn_area {
  padding-bottom: 0;
  text-align: right;
}
.layer_pop .lp_giftcard .bx_clear .fl,
.layer_pop .lp_giftcard .bx_clear .fr {
  width: 49%;
}
.layer_pop .lp_giftcard .bx_clear .fl table,
.layer_pop .lp_giftcard .bx_clear .fr table {
  margin-top: 10px;
}
.layer_pop .section.overseas_purchase {
  position: relative;
  font-size: 12px;
  color: #666;
}
.layer_pop .section.overseas_purchase strong {
  display: block;
  margin-bottom: 20px;
  margin-top: 15px;
  padding-left: 9px;
  background: url(#{$imgPath}/odr/bg_dot3.gif) no-repeat 0 8px;
  font-size: 12px;
  font-weight: bold;
  color: #666;
}
.layer_pop .section.overseas_purchase p {
  color: #949494;
  margin-bottom: 22px;
}
.layer_pop .section.overseas_purchase .process {
  width: 598px;
  height: 133px;
}
.layer_pop .section.overseas_purchase .process li {
  float: left;
  margin-right: 20px;
}
.layer_pop .section.overseas_purchase .ir {
  position: absolute;
  background: url(#{$imgPath}/odr/img_order_abroad.gif) 30px 118px no-repeat;
}
.layer_pop .section.overseas_purchase .content {
  clear: both;
  margin-top: 40px;
}
.layer_pop .section.overseas_purchase .check_purchase h4,
.layer_pop .section.overseas_purchase .check_customs h4 {
  height: 14px;
  margin-bottom: 17px;
  background: url(#{$imgPath}/common/ranking_large.png) no-repeat 0 0;
  vertical-align: middle;
  padding-left: 35px;
}
.layer_pop .section.overseas_purchase .check_customs h4 {
  background-position: 0 -20px;
  margin-bottom: 15px;
}
.layer_pop .section.overseas_purchase .check_purchase ul li {
  letter-spacing: -0.5px;
  line-height: 18px;
}
.layer_pop .section.overseas_purchase .check_customs {
  position: relative;
  height: 70px;
}
.layer_pop .section.overseas_purchase .check_customs ul li {
  font-size: 10px;
}
.layer_pop .section.overseas_purchase .check_customs .ir {
  position: absolute;
  background: url(#{$imgPath}/odr/txt_order_abroad.gif) no-repeat 0 33px;
}
.layer_pop .tab.large {
  margin-top: 0;
  margin-bottom: 20px;
}
.layer_pop .tab.large.t_dep3 li {
  width: 200px;
}
.layer_pop .tab.large.t_dep3 li:first-child {
  width: 201px;
}
