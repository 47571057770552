/*myssg header*/
.myssg_header {
  position: relative;
  z-index: 21;
  height: 94px;
  border-bottom: 3px solid #203a4d;
  margin-bottom: 20px;
  background-color: #f3fafe;
}
.myssg_header a {
  text-decoration: none;
}
.myssg_header.v3 {
  margin-top: 20px;
  border-top: 1px solid #d1dadd;
}
.myssg_header h2 {
  float: left;
  margin: 8px 0 8px;
  padding: 32px 15px 9px 18px;
  border-right: 1px solid #ccd6da;
}
.myssg_header h2.mysfc_logo {
  float: left;
  padding: 15px 23px 0 28px;
}
.myssg_header .myssg_name {
  position: absolute;
  top: 16px;
  left: 22px;
  color: #203a4d;
}
.myssg_header .myssg_name em {
  font-weight: bold;
}
.myssg_header .myssg_level {
  float: left;
  position: relative;
  overflow: hidden;
  width: 262px;
  height: 94px;
  background: url(#{$imgPath}/mem/myssg_level_v2.png) no-repeat 0 -400px;
}
.myssg_header .myssg_level.vip_level {
  background-position: 0 0;
}
.myssg_header .myssg_level.gold_level {
  background-position: 0 -100px;
}
.myssg_header .myssg_level.silver_level {
  background-position: 0 -200px;
}
.myssg_header .myssg_level.bronze_level {
  background-position: 0 -300px;
}
.myssg_header .myssg_level.family_level {
  background-position: 0 -400px;
}
.myssg_header .myssg_level a {
  position: absolute;
  top: 53px;
  left: 79px;
}
.myssg_header .myssg_level a.btn_coupon {
  position: absolute;
  top: 45px;
  left: 95px;
}
.myssg_header .myssg_level a.btn_benefit {
  position: absolute;
  top: 63px;
  left: 95px;
}
.myssg_header .myssg_level a.btn_next {
  position: absolute;
  top: 19px;
  left: 159px;
}
.myssg_header .myssg_level a img {
  vertical-align: top;
}

.myssg_header .myssg_lv {
  float: left;
  position: relative;
  overflow: hidden;
  width: 262px;
  height: 94px;
}
.myssg_header .myssg_lv .ic {
  position: absolute;
  top: 0;
  left: 0;
  width: 88px;
  height: 88px;
  background: url(#{$imgPath}/mem/myssg_level_v2.png) no-repeat 0 -400px;
}
.myssg_header .myssg_lv .tt {
  position: absolute;
  top: 15px;
  left: 95px;
  font-family: NanumBarunGothic;
  color: #203a4d;
  font-size: 23px;
  line-height: 30px;
}
.myssg_header .myssg_lv a {
  font-family: $fontR;
  font-weight: 400;
  color: #203a4d;
}
.myssg_header .myssg_lv a.btn_coupon {
  position: absolute;
  top: 45px;
  left: 95px;
}
.myssg_header .myssg_lv a.btn_coupon:after {
  display: inline-block;
  content: '';
  width: 4px;
  height: 8px;
  background: url(#{$imgPath}/mem/txt_level_cp.gif) 100% -5px no-repeat;
  margin-left: 5px;
}
.myssg_header .myssg_lv a.btn_benefit {
  position: absolute;
  top: 63px;
  left: 95px;
}
.myssg_header .myssg_lv a.btn_benefit:after {
  display: inline-block;
  content: '';
  width: 4px;
  height: 8px;
  background: url(#{$imgPath}/mem/txt_level_cp.gif) 100% -5px no-repeat;
  margin-left: 5px;
}
.myssg_header .myssg_lv a.btn_next {
  position: absolute;
  top: 19px;
  right: 20px;
  font-size: 11px;
  color: #5e7587;
  font-family: $fontR;
  font-weight: 400;
  letter-spacing: -1px;
  border: 1px solid #bacdd5;
  line-height: 17px;
  padding: 1px 4px 0;
}
.myssg_header .myssg_lv.vip_level .ic {
  background-position: 0 0;
}
.myssg_header .myssg_lv.gold_level .ic {
  background-position: 0 -100px;
}
.myssg_header .myssg_lv.silver_level .ic {
  background-position: 0 -200px;
}
.myssg_header .myssg_lv.bronze_level .ic {
  background-position: 0 -300px;
}
.myssg_header .myssg_lv.family_level .ic {
  background-position: 0 -400px;
}

.myssg_header .myssg_gnb {
  float: right;
  overflow: hidden;
  width: 583px;
  background: url(#{$imgPath}/mem/bg_myssg_heading.gif) no-repeat 172px 10px;
}
.myssg_header .myssg_gnb a.btn_myssg_showall {
  display: block;
  width: 55px;
  height: 31px;
  margin-top: 8px;
  background: url(#{$imgPath}/mem/btn_myssg01.gif) no-repeat;
  float: left;
  font-family: $fontR;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  padding: 50px 26px 0 0;
  color: #fff;
  line-height: 10em;
}
.myssg_header .myssg_gnb a.btn_myssg_showall.active,
.myssg_header .myssg_gnb a.btn_myssg_showall:hover {
  background: url(#{$imgPath}/mem/btn_myssg02.gif) 0 0 no-repeat;
}
:lang(en) .myssg_header .myssg_gnb a.btn_myssg_showall,
:lang(zh) .myssg_header .myssg_gnb a.btn_myssg_showall {
  background: url(#{$imgPath}/mem/btn_myssg01.png) no-repeat;
  line-height: 18px;
}
:lang(en) .myssg_header .myssg_gnb a.btn_myssg_showall.active,
:lang(zh) .myssg_header .myssg_gnb a.btn_myssg_showall.active,
:lang(en) .myssg_header .myssg_gnb a.btn_myssg_showall:hover,
:lang(zh) .myssg_header .myssg_gnb a.btn_myssg_showall:hover {
  background: url(#{$imgPath}/mem/btn_myssg02.png) 0 0 no-repeat;
}

.myssg_header .myssg_gnb .myssg_f_item {
  float: left;
  width: 502px;
}
.myssg_header .myssg_gnb .myssg_f_item li:first-child {
  margin-left: 9px;
}
.myssg_header .myssg_gnb li {
  float: left;
  width: 82px;
  text-align: right;
}
.myssg_header .myssg_gnb li.myssg_f_clip {
  position: relative;
}
.myssg_header .myssg_gnb li.myssg_f_clip a.f_clip_01 {
  position: absolute;
  top: 73px;
  left: 1px;
  width: 41px;
  height: 20px;
}
.myssg_header .myssg_gnb li.myssg_f_clip a.f_clip_02 {
  position: absolute;
  top: 73px;
  left: 43px;
  width: 41px;
  height: 20px;
}
.myssg_header .myssg_gnb li li {
  float: none;
  width: auto;
  text-align: inherit;
}
.myssg_header .myssg_gnb li li:first-child {
  margin-right: inherit;
  background: inherit;
}
.myssg_header .myssg_stemp_info {
  display: none;
  border: 1px solid #203a4d;
  background: #fff;
  position: absolute;
  top: 37px;
  left: 332px;
  padding: 18px 21px;
  -moz-box-shadow: 2px 2px 5px #ededed;
  -webkit-box-shadow: 2px 2px 5px #ededed;
  box-shadow: 2px 2px 5px #ededed;
  z-index: 10;
}
.myssg_header .myssg_stemp_info .txt {
  line-height: 17px;
  background: url(#{$imgPath}/mem/bu_arr.gif) 0 2px no-repeat;
  padding-left: 12px;
  font-family: $fontR;
  font-weight: 400;
  color: #777;
}
.myssg_header .myssg_stemp_info .txt em {
  font-weight: bold;
  text-decoration: underline;
}
.myssg_header .myssg_stemp_info .txt strong {
  color: #00bcf0;
}
.myssg_header .myssg_stemp_info .tx_next {
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
  line-height: 17px;
}
.myssg_header .myssg_stemp_info .tx_next img {
  vertical-align: top;
}
.myssg_header .myssg_stemp_info .tx_next em {
  color: #ff0048;
}
.myssg_header .myssg_stemp_info .tx_next strong {
  color: #00bcf0;
}
.myssg_header .myssg_lv {
  overflow: visible;
}
