/*GNB 개편(1280) S*/
.common_nav_wide {
  position: relative;
  z-index: 200;
  border-bottom: 1px solid #e5e5e5;
  font-family: $fontR;
  font-weight: 400;
}
.common_nav_wide button {
  font-family: inherit;
}
.common_nav_bar {
  width: 1280px;
  margin: 0 auto;
}
.common_nav_bar:after {
  display: block;
  clear: both;
  content: '';
}
.common_nav_bar a {
  text-decoration: none;
}
.common_nav_bar img {
  vertical-align: middle;
}
.common_nav_all {
  position: relative;
  float: left;
  margin-left: 18px;
}
/*통합 카테고리*/
.sp_mall_txt_ssg {
  display: inline-block;
  vertical-align: top;
  margin-top: 4px;
  width: 66px;
  height: 11px;
  background-position: 0 0;
  background-image: url('#{$imgPath}/common_layout/sp_mall_cate_ssg.png');
  background-repeat: no-repeat;
}

.ctg_open_btn {
  display: block;
  width: 35px;
  height: 35px;
  margin-top: 9px;
  text-align: center;
  border-radius: 100%;
  outline: none;
  background: #222;
}
.ctg_open_btn:focus {
  box-shadow: 0 0 0 1px #2793e6;
}
.ctg_open_btn:hover,
.ctg_open_btn.on {
  background: get-color('primary');
}
.ctg_ico_menu {
  position: relative;
  display: block;
  width: 19px;
  height: 14px;
  margin: 0 auto;
  transition: all 0.4s ease;
}
.ctg_ico_menu:before,
.ctg_ico_menu:after,
.ctg_ico_menu em {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  transition: all 0.4s ease;
}
.ctg_ico_menu:before {
  top: 0px;
}
.ctg_ico_menu .l1 {
  top: 4px;
}
.ctg_ico_menu .l2 {
  top: 8px;
}
.ctg_ico_menu:after {
  top: 12px;
}
.ctg_open_btn.on .ctg_ico_menu {
  transform: rotate(135deg);
}
.ctg_open_btn.on .ctg_ico_menu:before,
.ctg_open_btn.on .ctg_ico_menu:after {
  top: 6px;
  transform: rotate(90deg);
}
.ctg_open_btn.on .ctg_ico_menu .l1,
.ctg_open_btn.on .ctg_ico_menu .l2 {
  top: 6px;
}

.ctg_total_wrap {
  display: none;
  position: absolute;
  top: 54px;
  left: 0;
  z-index: 110;
  background: #fff;
}
.ctg_total {
  position: relative;
  min-height: 508px;
  padding-bottom: 5px;
  border: 1px solid #d1d1d1;
  border-width: 0 1px;
}
.ctg_total:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  height: 5px;
  margin: 0 -1px;
  background: #222;
}

.ctg_top_mn {
  width: 170px;
  height: 46px;
  margin-top: -1px;
}
.ctg_top_mn:first-child .ctg_lnk_txt {
  border-top-color: transparent;
}
.ctg_top_mn a {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}

.ctg_top_lnk {
  display: block;
  margin-left: -1px;
  border-left: 3px solid transparent;
}
.ctg_lnk_label {
  display: block;
  border: 1px solid transparent;
}
.ctg_lnk_txt {
  display: block;
  height: 20px;
  line-height: 22px;
  padding: 12px 0;
  margin: -1px 20px 0 20px;
  border-top: 1px dotted #d8d8d8;
  font-size: 13px;
  color: #777;
  letter-spacing: -0.3px;
}
.ctg_lnk_txt .sp_mall_type_s {
  padding-right: 7px;
}
.ctg_lnk_txt .cm_mall_ic.ty_circle_s {
  padding-right: 7px;
}

.ctg_top_mn.on .ctg_top_lnk {
  position: relative;
  z-index: 10;
  border-color: #222;
}
.ctg_top_mn.on .ctg_lnk_label {
  border-top-color: #d8d8d8;
  border-bottom-color: #d8d8d8;
  border-right-color: #fff;
}
.ctg_top_mn.on:first-child .ctg_lnk_label {
  border-top-color: transparent;
}

.ctg_a11y_btn {
  display: none;
  position: relative;
  z-index: 10;
  float: right;
  width: 20px;
  height: 25px;
  overflow: hidden;
  margin-top: -25px;
  line-height: 0;
  background: transparent;
}
.ctg_a11y_btn:after {
  content: '';
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-left: -2px;
  border-top: 1px solid #888;
  border-right: 1px solid #888;
  transform: rotate(45deg);
}
.ctg_top_mn > .ctg_a11y_btn {
  height: 45px;
  margin-top: -45px;
}

.ctg_sub_area {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 169px;
  border-left: 1px dotted #d8d8d8;
}
.ctg_sub_lst {
  width: 170px;
}
.ctg_sub_lnk {
  display: block;
  margin-left: 10px;
  padding: 1px 10px 0 10px;
  line-height: 23px;
  border: 1px solid transparent;
  color: #777;
  font-size: 13px;
  letter-spacing: -0.3px;
}
.ctg_sub_mn.on > .ctg_sub_lnk,
.ctg_sub_mn > .ctg_sub_lnk:focus {
  color: #222;
  font-weight: bold;
}
.ctg_sub_mn.ctg_sub_child.on > .ctg_sub_lnk {
  position: relative;
  z-index: 110;
  border-color: #d8d8d8;
  border-right-color: #fff;
}

.ctg_sub_colgroup:after {
  clear: both;
  display: block;
  content: '';
}
.ctg_sub_col {
  float: left;
  height: 100%;
  border-left: 1px dotted #d8d8d8;
}
.ctg_sub_col:first-child {
  border-left: 0;
}

.ctg_context_mn.on {
  position: relative;
  width: 161px;
}
.ctg_sub_context {
  top: -15px;
  left: 160px;
  bottom: auto;
  z-index: 100;
  border: 1px solid #d8d8d8;
  background: #fff;
  box-shadow: 4px 4px rgba(0, 0, 0, 0.1);
}
.ctg_sub_context .ctg_sub_lst {
  padding: 10px 0;
}
.ctg_sub_context > .ctg_sub_lst {
  width: 200px;
}
.ctg_sub_context .ctg_sub_col {
  border-left: 0;
}

.ctg_top_mn.on .ctg_sub_lv2 {
  display: block;
  top: 20px;
  bottom: 20px;
}
.ctg_sub_lv2 .on .ctg_sub_lv3 {
  display: block;
}
.ctg_sub_lv3 .on .ctg_sub_lv4 {
  display: block;
}
.ctg_sub_lv4 .on .ctg_sub_lv5 {
  display: block;
}
.ctg_sub_lv5 .on .ctg_sub_lv6 {
  display: block;
}
.ctg_sub_lv6 .on .ctg_sub_lv7 {
  display: block;
}
.ctg_sub_lv7 .on .ctg_sub_lv8 {
  display: block;
}
.ctg_sub_lv8 .on .ctg_sub_lv9 {
  display: block;
}
.ctg_sub_lv9 .on .ctg_sub_lv10 {
  display: block;
}

/*SSG GNB*/

/*먼데이문(뷰티)카테고리 추가 S*/
.common_nav_wide.v2,
.common_nav_wide.v3 {
  .nav_top_lnk {
    letter-spacing: -0.46px;
  }
  .nav_top_mn.nav_beauty {
    > .nav_top_lnk {
      font-weight: bold;
      color: #437560;
      // OPEN 표기 딱지
      // &:before {
      // 	content: "";
      // 	position: absolute;
      // 	top: 4px;
      // 	right: -2px;
      // 	z-index: 10;
      // 	margin: 0;
      // 	width: 30px;
      // 	height: 14px;
      // 	background: url(#{$imgPath}/common_layout/sp_mallnew_ico.png) 0 0 no-repeat;
      // 	background-size: 30px auto;
      // 	@media screen and (-webkit-min-device-pixel-ratio:2){
      // 		background-image: url(#{$imgPath}/common_layout/sp_mallnew_ico@2x.png);
      // 		background-size: auto 14px;
      // 	}
      // }
    }
    .nav_sub_inner {
      padding-left: 30px;
      background: url(#{$imgPath}/common_layout/bg_nav_sub_inner_mndmoon.png) repeat-y 100% 0;
      @media screen and (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(#{$imgPath}/common_layout/bg_nav_sub_inner_mndmoon@2x.png);
        background-size: 250px auto;
      }
    }
    .nav_sub_mndmoon {
      position: relative;
      float: left;
      width: 274px;
      height: 460px;
      margin-top: -10px;
      padding-right: 116px;
      background: #475a52 url(#{$imgPath}/common_layout/bg_nav_sub_mndmoon.jpg);
      @media screen and (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(#{$imgPath}/common_layout/bg_nav_sub_mndmoon@2x.jpg);
        background-size: 274px auto;
      }
    }
    .nav_service_lst {
      padding-left: 22px;
    }
    .nav_service_mn {
      overflow: hidden;
      margin-top: 14px;
      font-size: 0;
      line-height: 18px;
      color: #fff;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:first-child {
        margin-top: 0;
      }
    }
    .nav_service_lnk {
      position: relative;
      padding-left: 8px;
      font-weight: bold;
      font-size: 14px;
      color: #fff;
      vertical-align: top;
      &:before {
        position: absolute;
        top: 4px;
        left: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 5px solid #fff;
        content: '';
      }
    }
    .nav_service_badge {
      margin-left: 5px;
      font-size: 14px;
      vertical-align: top;
      img {
        height: 18px;
        vertical-align: top;
      }
    }
    .nav_sub_shopbnr {
      width: 274px;
      height: 130px;
    }
    .nav_sub_decobnr {
      position: absolute;
      top: 0;
      right: 0;
      width: 116px;
      height: 460px;
    }
    .nav_sub_colgroup {
      width: 760px;
    }
    .nav_sub_col {
      padding-top: 2px;
    }
    .nav_sub_lst {
      padding-top: 27px;
      &:first-child {
        padding-top: 0;
      }
    }
    .nav_sub_lnk:hover,
    .nav_sub_lnk:focus {
      color: #ae8a67;
      border-bottom: 1px solid #ae8a67;
    }
    .nav_sub_tit {
      padding-bottom: 15px;
      .nav_sub_lnk {
        font-size: 14px;
      }
    }
  }

  .ctg_total {
    min-height: 623px;
    box-sizing: border-box;
  }
  .ctg_mall_ssg .ctg_sub_lv2 > .ctg_sub_lst > .ctg_sub_mn > .ctg_sub_lnk {
    padding-left: 28px;
  }
  .ctg_sub_mn.ctg_beauty {
    // 뷰티 카테고리
    > .ctg_sub_lnk {
      &:before {
        content: '';
        display: inline-block;
        vertical-align: top;
        margin: 2px 5px 0 -23px;
        width: 18px;
        height: 18px;
        background: url(#{$imgPath}/common_layout/sp_mndmoon_mall_ic.png) no-repeat 0 -30px;
        @media screen and (-webkit-min-device-pixel-ratio: 2) {
          background-image: url(#{$imgPath}/common_layout/sp_mndmoon_mall_ic@2x.png);
          background-size: 100px auto;
        }
      }
    }
    &.on > .ctg_sub_lnk {
      border-color: transparent;
      background-color: #475a52;
      color: #fff;
      &:before {
        background-position: 0 0;
      }
    }
    .ctg_sub_area.ctg_sub_mndmoon {
      left: 170px;
      bottom: -15px;
      border-left: 0;
      background: #475a52 url(#{$imgPath}/common_layout/bg_ctg_sub_area_mndmoon.png) 100% 100%
        no-repeat;
      @media screen and (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(#{$imgPath}/common_layout/bg_ctg_sub_area_mndmoon@2x.png);
        background-size: 41px auto;
      }
      > .ctg_sub_lst {
        > .ctg_sub_mn {
          // 뷰티 카테고리 > 대카
          > .ctg_sub_lnk {
            margin-left: 15px;
            padding-right: 20px;
            padding-left: 14px;
            border-right: 0;
            color: #fff;
          }
          &.on > .ctg_sub_lnk {
            background: #fff;
            color: #475a52;
          }
          > .ctg_sub_area {
            border-left-color: transparent;
          }
        }
        > .ctg_sub_mn .ctg_sub_mn {
          // 뷰티 카테고리 > 대카 > 중카 이하
          &.on > .ctg_sub_lnk,
          > .ctg_sub_lnk:focus {
            color: #475a52;
          }
        }
      }
      .ctg_sub_shopbnr {
        width: 154px;
        height: 189px;
        padding: 8px 8px 10px;
        margin-top: -20px;
        background-color: #475a52;
        a {
          display: block;
        }
      }
      .ctg_service_lst {
        width: 140px;
        margin: 6px 15px 0;
        padding-top: 6px;
        border-top: 1px dotted #d8d8d8;
      }
      .ctg_service_mn {
        line-height: 25px;
      }
      .ctg_service_lnk {
        display: inline-block;
        position: relative;
        max-width: 100%;
        padding: 1px 0 0 15px;
        box-sizing: border-box;
        font-size: 13px;
        color: #fff;
        vertical-align: top;
        &:before {
          position: absolute;
          top: 9px;
          left: 8px;
          border-top: 4px solid transparent;
          border-bottom: 3px solid transparent;
          border-left: 4px solid #fff;
          content: '';
        }
        &:hover,
        &:focus {
          font-weight: bold;
        }
      }
    }
  }
}
:lang(en) .nav_sub_mndmoon .nav_service_lst .nav_service_badge,
:lang(zh) .nav_sub_mndmoon .nav_service_lst .nav_service_badge {
  display: none;
}
/*먼데이문(뷰티)카테고리 추가 E*/
.common_nav_menu {
  float: left;
  margin-left: 4px;
}
.common_nav_util {
  float: right;
  margin-right: 9px;
}
.common_nav_util .nav_top_lnk {
  font-size: 13px;
}
.common_nav_util .nav_top_lnk:after {
  position: absolute;
  top: 20px;
  left: 0px;
  height: 13px;
  content: '';
  border-left: 1px dotted #b8b8b8;
  transform: rotate(15deg);
  -ms-transform: rotate(15deg);
}
.common_nav_util .nav_top_mn:first-child .nav_top_lnk:after {
  display: none;
}
.nav_top_mn {
  float: left;
}
.nav_top_mn.on .nav_sub_area {
  display: block;
}
.nav_top_mn.on .nav_top_lnk {
  border-bottom-color: get-color('primary');
}
.nav_top_lnk {
  position: relative;
  display: block;
  height: 16px;
  line-height: 16px;
  padding: 19px 8px 13px 8px;
  text-align: center;
  color: #222;
  font-size: 13px;
  border-bottom: 5px solid transparent;
  -webkit-transition: border 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: border 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
:lang(en) .common_nav_util {
  margin-top: -16px;
}
:lang(en) .common_nav_util .nav_top_lnk {
  position: static;
}
.common_nav_util .nav_top_lnk {
  padding: 19px 7px 13px 7px;
}

.nav_sub_area {
  display: none;
  position: absolute;
  top: 53px;
  left: 0;
  right: 0;
  z-index: 110;
  width: 100%;
  border-bottom: 5px solid #222;
  background: #fff;
}
.nav_sub_inner {
  width: 1280px;
  margin: 0 auto;
  padding: 31px 0 34px;
  border-top: 1px solid #e5e5e5;
}
.nav_sub_inner:after {
  display: block;
  clear: both;
  content: '';
}

.nav_sub_tit {
  display: block;
  padding-bottom: 5px;
}
.nav_sub_tit .nav_sub_lnk {
  color: #222;
  font-size: 13px;
  font-weight: bold;
  word-break: break-all;
  word-wrap: break-word;
}
.nav_sub_mn {
  padding-top: 6px;
}
.nav_sub_mn:first-child {
  padding-top: 0;
}
.nav_sub_lnk {
  display: inline-block;
  line-height: 14px;
  color: #777;
  font-size: 12px;
  letter-spacing: -0.3px;
}
.nav_sub_lnk:hover,
.nav_sub_lnk:focus {
  color: get-color('primary');
  border-bottom: 1px solid get-color('primary');
}

.nav_sub_colgroup {
  position: relative;
  float: left;
  display: table;
  table-layout: fixed;
  width: 100%;
  min-height: 400px;
}
.nav_sub_lst {
  width: 172px;
  padding: 40px 0 0 20px;
}
.nav_sub_col .nav_sub_lst:first-child {
  padding-top: 0;
}
.nav_sub_col {
  display: table-cell;
  vertical-align: top;
  width: 192px;
  height: 100%;
  background-image: linear-gradient(to bottom, #bbb 30%, rgba(255, 255, 255, 0) 0%);
  background-position: left;
  background-size: 1px 4px;
  background-repeat: repeat-y;
}
.nav_sub_col:first-child {
  background: none;
}

.nav_sub_bnr {
  float: right;
  width: 290px;
  margin-right: 20px;
}
.nav_sub_bnr .cmplan_unit .cmplan_badge_area + .cmplan_detailbx {
  padding-bottom: 0;
}

@-webkit-keyframes spin_arr {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(180deg);
  }
}
@keyframes spin_arr {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
@-webkit-keyframes spin_arr2 {
  from {
    -webkit-transform: rotate(180deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin_arr2 {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes ic_open {
  from {
    opacity: 0;
    top: 15px;
  }
  to {
    opacity: 1;
    top: 6px;
  }
}
@keyframes ic_open {
  from {
    opacity: 0;
    top: 15px;
  }
  to {
    opacity: 1;
    top: 6px;
  }
}
.spr_menu {
  position: absolute;
  top: 0;
  right: 50%;
  height: 53px;
  overflow: hidden;
  margin-right: -640px;
}
.spr_menu ul {
  text-align: right;
  white-space: nowrap;
  padding-right: 30px;
}
.spr_menu li {
  display: inline-block;
}
.spr_menu .nav_lnk {
  display: block;
  padding: 19px 7px 0 11px;
  font-size: 13px;
  height: 34px;
  position: relative;
  line-height: 16px;
  text-align: center;
  color: #222;
}
.spr_menu .nav_lnk:focus {
  display: inline;
  height: auto;
  padding-top: 0;
  position: static;
}
.spr_menu .nav_lnk:after {
  position: absolute;
  top: 20px;
  left: 0px;
  height: 13px;
  content: '';
  border-left: 1px dotted #b8b8b8;
  transform: rotate(15deg);
  -ms-transform: rotate(15deg);
}
.spr_menu li:first-child .nav_lnk:after {
  display: none;
}
.spr_menu .ic_arr {
  width: 13px;
  height: 13px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  position: absolute;
  top: 18px;
  right: 17px;
}
.spr_menu .ic_arr:before {
  content: '';
  width: 9px;
  height: 4px;
  position: absolute;
  top: 5px;
  left: 2px;
  background: url(#{$imgPath}/common_layout/ic_arr.png) 0 0 no-repeat;
  -webkit-animation: spin_arr2 0.4s;
  animation: spin_arr2 0.4s;
}
.spr_menu .nav_lnk.ic_open {
  color: get-color('primary');
  font-weight: bold;
  position: relative;
}
.spr_menu .nav_lnk.ic_open:before {
  content: '';
  position: absolute;
  top: 6px;
  right: -4px;
  z-index: 10;
  margin: 0;
  width: 30px;
  height: 14px;
  background: url(#{$imgPath}/common_layout/sp_mallnew_ico.png) 0 0 no-repeat;
  background-size: 30px auto;
  opacity: 0;
}
.spr_menu .nav_lnk:focus:before,
.spr_menu .nav_lnk:focus:after {
  display: none;
}

.spr_menu.hover {
  height: auto;
  background: #fff;
  padding: 0 0 20px 4px;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s;
  z-index: 110;
}
.spr_menu.hover .bx-viewport {
  height: auto !important;
}
.spr_menu.hover .bx-clone {
  display: none;
}
.spr_menu.hover .spr_slider {
  transform: translate3d(0px, 0px, 0px) !important;
  top: 0 !important;
}
.spr_menu.hover ul {
  height: 34px;
  text-align: center;
  padding-right: 15px;
}
.spr_menu.hover .ic_arr:before {
  top: 4px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-animation: spin_arr 0.4s;
  animation: spin_arr 0.4s;
}
.spr_menu.hover .nav_lnk.ic_open:before {
  opacity: 1;
  top: 6px;
  right: -4px;
}
.spr_menu ul.active .nav_lnk.ic_open:before {
  -webkit-animation: ic_open 0.4s;
  animation: ic_open 0.4s;
  opacity: 1;
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .sp_mall_txt_ssg {
    background-position-x: -66px;
    background-size: 132px 11px;
  }
  .sp_mall_type_s {
    background-position-x: -20px;
    background-size: 40px 180px;
  }
  .ctg_mall_lst.v2 .sp_mall_type_s {
    background-position-x: -20px;
    background-size: 40px 300px;
  }
  .spr_menu .ic_arr:before {
    background-image: url(#{$imgPath}/common_layout/ic_arr@2x.png);
    background-size: 9px 4px;
  }
  .spr_menu .nav_lnk.ic_open:before {
    background-image: url(#{$imgPath}/common_layout/sp_mallnew_ico@2x.png);
    background-size: auto 14px;
  }
}
/*GNB 개편(1280) E*/

/* 몰탭 개편-202103(s) */
.common_nav_wide.v3 {
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.07);
  .spr_menu {
    .nav_lnk {
      font-size: 14px;
      color: #888;
      font-weight: bold;
      padding: 18px 7px 0 11px;
      &:after {
        display: none;
      }
    }
    &.hover {
      box-shadow: 4px ​4px 10px 0 rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }
  }
  .nav_top_lnk {
    padding: 18px ​10px 19px 10px;
    font-size: 14px;
    font-weight: bold;
  }
  .ctg_open_btn {
    background: transparent;
    width: 28px;
    height: 28px;
    border-radius: 0;
    margin-top: 12px;
    &:hover {
      background: transparent;
    }
  }
  .ctg_ico_menu:before,
  .ctg_ico_menu:after,
  .ctg_ico_menu em {
    background-color: #000;
  }
}
/* 몰탭 개편-202103(e) */
/*유아동카테고리 추가 S*/
.common_nav_wide {
  .nav_top_lnk {
    letter-spacing: -0.46px;
  }
  .nav_top_mn.nav_baby {
    .nav_sub_inner {
      padding-left: 30px;
    }
    .nav_sub_baby {
      position: relative;
      float: left;
      width: 274px;
      height: 460px;
      margin-top: -10px;
      background-color: #ff9600;
    }
    .nav_service_lst {
      padding-left: 22px;
    }
    .nav_service_mn {
      overflow: hidden;
      margin-top: 14px;
      font-size: 0;
      line-height: 18px;
      color: #222;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:first-child {
        margin-top: 0;
      }
    }
    .nav_service_lnk {
      position: relative;
      padding-left: 8px;
      font-weight: bold;
      font-size: 14px;
      color: #222;
      vertical-align: top;
      &:before {
        position: absolute;
        top: 4px;
        left: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 5px solid #222;
        content: '';
      }
    }
    .nav_service_badge {
      margin-left: 5px;
      font-size: 14px;
      vertical-align: top;
      img {
        height: 18px;
        vertical-align: top;
      }
    }
    .nav_sub_shopbnr {
      width: 274px;
      height: 130px;
    }
    .nav_sub_col {
      padding-top: 2px;
    }
    .nav_sub_colgroup {
      width: 900px;
    }
    .nav_sub_lst {
      padding-top: 27px;
      &:first-child {
        padding-top: 0;
      }
    }
    .nav_sub_lnk:hover,
    .nav_sub_lnk:focus {
      color: #ae8a67;
      border-bottom: 1px solid #ae8a67;
    }
    .nav_sub_tit {
      padding-bottom: 15px;
      .nav_sub_lnk {
        font-size: 14px;
      }
    }
  }

  .nav_sub_mybaby {
    position: absolute;
    bottom: 30px;
    left: 22px;
  }

  .ctg_total {
    min-height: 623px;
    box-sizing: border-box;
  }
  .ctg_mall_ssg .ctg_sub_lv2 > .ctg_sub_lst > .ctg_sub_mn > .ctg_sub_lnk {
    padding-left: 28px;
  }
  .ctg_sub_mn.ctg_baby {
    // 유아동 카테고리
    .ctg_sub_area.ctg_sub_baby {
      left: 169px;
      bottom: -15px;
      background: #fff;
      > .ctg_sub_lst {
        > .ctg_sub_mn {
          // 유아동 카테고리 > 대카
          > .ctg_sub_lnk {
            margin-left: 15px;
            padding-right: 20px;
            padding-left: 14px;
            border-right: 0;
            color: #777;
          }
          &.on > .ctg_sub_lnk {
            background: #fff;
            color: #777;
          }
        }
      }
      .ctg_sub_shopbnr {
        width: 154px;
        height: 189px;
        padding: 8px 8px 10px;
        margin-top: -20px;
        a {
          display: block;
        }
      }
      .ctg_service_lst {
        width: 140px;
        margin: 6px 15px 0;
        padding-top: 6px;
        border-top: 1px dotted #d8d8d8;
      }
      .ctg_service_mn {
        line-height: 25px;
      }
      .ctg_service_lnk {
        display: inline-block;
        position: relative;
        max-width: 100%;
        padding: 1px 0 0 15px;
        box-sizing: border-box;
        font-size: 13px;
        color: #777;
        vertical-align: top;
        &:hover,
        &:focus {
          font-weight: bold;
        }
      }
    }
  }
}
:lang(en) .nav_sub_baby .nav_service_lst .nav_service_badge,
:lang(zh) .nav_sub_baby .nav_service_lst .nav_service_badge {
  display: none;
}
/*유아동카테고리 추가 E*/
