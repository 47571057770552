.datepicker.dropdown-menu {
  right: 0;
  top: 28px;
}
.datepicker_panel {
  position: relative;
  display: inline-block;
}
.datepicker_panel .btn.open_calendar {
  display: inline-block;
  margin: 0 0 0 -5px;
  border: 1px solid #999;
  background-color: #fff;
  background-image: url(#{$imgPath}/common/btn_open_calendar.gif);
  background-repeat: no-repeat;
  background-position: center center;
}
.datepicker_panel .datepicker-days {
  position: absolute;
  left: 0;
  top: -1px;
}
.btn.open_calendar.small {
  width: 26px;
  height: 26px;
  *vertical-align: middle;
}
.btn.open_calendar.medium {
  width: 38px;
  height: 38px;
}
.calendar.small {
  position: relative;
  width: 214px;
  border: 1px solid #999;
  font-size: 11px;
  background: #fff;
  overflow: hidden;
}
.calendar.small table {
  width: 100%;
  margin-bottom: 3px;
  border: 0 none;
}
.calendar.small table thead tr:first-child th {
  height: 30px;
  line-height: 30px;
  color: #444;
  border-bottom: 1px solid #dfdfdf;
  background: #fcfcfc;
  text-align: center;
}
.calendar.small table thead tr:first-child th.month {
  width: 90px;
}
.calendar.small table thead tr:first-child th.prev_year a,
.calendar.small table thead tr:first-child th.prev_month a,
.calendar.small table thead tr:first-child th.next_month a,
.calendar.small table thead tr:first-child th.next_year a {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 30px;
  color: transparent;
}
.calendar.small table thead tr:first-child th.prev_year a .ir,
.calendar.small table thead tr:first-child th.prev_month a .ir,
.calendar.small table thead tr:first-child th.next_month a .ir,
.calendar.small table thead tr:first-child th.next_year a .ir {
  display: block;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 1px;
  left: 1px;
  background-color: #fcfcfc;
  background-image: url(#{$imgPath}/common/btn_calendar_small_01.png);
  background-repeat: no-repeat;
  cursor: pointer;
}
.calendar.small table thead tr:first-child th.prev_year a .ir {
  background-position: 8px 8px;
}
.calendar.small table thead tr:first-child th.prev_month a .ir {
  background-position: -22px 8px;
}
.calendar.small table thead tr:first-child th.next_month a .ir {
  background-position: -52px 8px;
}
.calendar.small table thead tr:first-child th.next_year a .ir {
  background-position: -82px 8px;
}
.calendar.small table thead tr:first-child th.prev_year a:hover .ir {
  background-position: 8px -22px;
}
.calendar.small table thead tr:first-child th.prev_month a:hover .ir {
  background-position: -22px -22px;
}
.calendar.small table thead tr:first-child th.next_month a:hover .ir {
  background-position: -52px -22px;
}
.calendar.small table thead tr:first-child th.next_year a:hover .ir {
  background-position: -82px -22px;
}
.calendar.small table thead tr th {
  padding-top: 5px;
}
.calendar.small table thead tr:first-child th {
  padding-top: 0;
}
.calendar.small table th,
.calendar.small table td {
  width: 30px;
  padding: 0;
  border: 0;
  letter-spacing: 0;
}
.calendar.small table .sunday {
  width: 32px;
}
.calendar.small table .saturday {
  width: 32px;
}
.calendar.small table strong,
.calendar.small table a {
  display: block;
  width: 30px;
  height: 24px;
  margin: 0;
  padding: 0;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
}
.calendar.small table strong {
  color: #444;
}
.calendar.small table a {
  color: #999;
}
.calendar.small table td.active a {
  background: #444;
  color: #fff;
  font-weight: bold;
}
.calendar.small table td.active a strong {
  color: #fff;
}
.calendar.small table a:hover {
  text-decoration: underline;
  background-color: #f9f9f9;
}
.calendar.small table .sunday a {
  color: #e04b4b;
}
.calendar.small table .saturday a {
  color: #0094f3;
}
