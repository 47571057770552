// =============================================================================
// PC scss -> css variable 변환
// =============================================================================
@mixin set-colors($colors) {
  @each $color-name, $color-value in $colors {
    $variable-name: str-replace($color-name, '.', '-');
    --m-colors-#{$variable-name}: #{$color-value};
  }
}

@mixin set-fonts($fonts) {
  @each $prefix, $map in $common-fonts {
    @each $name, $value in $map {
      --m-#{$prefix}-#{$name}: #{$value};
    }
  }
}

:root,
.body_ssg {
  @include set-colors($colors);
  @include set-fonts($fonts);
}
