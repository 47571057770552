.tbl_data_scroll {
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #666;
  table-layout: fixed;
}
.tbl_data_scroll .heading td {
  padding: 10px 0;
  text-align: center;
  line-height: 20px;
  border-top: 1px solid #666;
  background-color: #f2f2f2;
  font-family: $fontR;
  font-weight: 400;
  color: #222;
}
.tbl_data_scroll .heading td.bdline {
  background: #f2f2f2 url(#{$imgPath}/common/bg_table_line.gif) no-repeat left top;
}
.tbl_data_scroll .table_scroll {
  overflow: hidden;
  overflow-y: scroll;
  background: #fff;
}
.tbl_data_scroll .table_scroll table {
  width: 100%;
  table-layout: fixed;
  background: #fff;
  word-wrap: break-word;
}
.tbl_data_scroll .table_scroll table thead th {
  background: none;
  border: 0;
  line-height: 0;
}
.tbl_data_scroll .table_scroll table thead .blind {
  overflow: hidden;
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}
.tbl_data_scroll .table_scroll table thead {
  overflow: hidden;
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}
.tbl_data_scroll .table_scroll table tbody tr td {
  font-size: 12px;
  color: #666;
  letter-spacing: -1px;
}
.tbl_data_scroll .table_scroll table tbody tr td .limit01 {
  display: block;
  width: 61px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}
.tbl_data_scroll .table_scroll table tbody tr td .in_ar {
  display: block;
  padding: 0 10px;
}
.tbl_data_scroll .table_scroll table tbody tr:first-child td {
  background-color: #fff;
}
.tbl_data_scroll .table_scroll table tr td {
  height: auto;
  padding: 10px 0;
  text-align: center;
  background: #fff;
  border-top: solid 1px #e5e5e5;
  color: #666;
  line-height: 20px;
  font-family: $fontR;
  font-weight: 400;
}
.tbl_data_scroll .table_scroll table tr td.aling_right {
  padding-right: 15px;
  text-align: right;
}
.tbl_data_scroll .table_scroll table tr td.bdline {
  background: url(#{$imgPath}/common/bg_table_line.gif) no-repeat left top;
}
.tbl_data_scroll .table_scroll table tr th {
  border-top: solid 1px #e5e5e5;
  background: #f9f9f9;
  font-family: $fontR;
  font-weight: 400;
  text-align: left;
}
.tbl_data_scroll .table_scroll table tr td strong.shinpping {
  display: block;
  color: #e53838;
  font-weight: normal;
}
.tbl_data_scroll .table_scroll table tr td strong.shinpping2 {
  display: block;
  color: #00aeef;
  font-weight: normal;
}
.tbl_data_scroll .table_scroll table tr td strong.shinpping3 {
  display: block;
  color: #ff6015;
  font-weight: normal;
}
.tbl_data_scroll .table_scroll table tr th .label {
  padding-left: 20px;
}
.tbl_data_scroll .table_scroll table.odd_style th {
  background: none;
  text-align: center;
  font-weight: normal;
}
.tbl_data_scroll .table_scroll table.odd_style td {
  color: #a1a1a1;
  text-align: center;
  font-weight: normal;
  border-right: 1px solid #e5e5e5;
}
.tbl_data_scroll .table_scroll table.odd_style2 th {
  color: #a1a1a1;
  background: none;
  font-weight: normal;
  text-align: center;
}
.tbl_data_scroll .table_scroll table.odd_style2 td {
  padding-left: 15px;
  text-align: left;
}
div.tbl_data_scroll table {
  table-layout: fixed;
}
div.tbl_data_scroll .heading td {
  border-top: 0;
}
.layer_pop h3 {
  padding-bottom: 13px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}
.tfooter tr {
  height: 50px;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
}
.tfooter tr th {
  background-color: #f9f9f9;
  color: #222;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
}
.tfooter tr td {
  font-weight: normal;
  text-align: right;
  padding-right: 27px;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
}
.tfooter tr td .ssg_price {
  font-size: 20px;
  line-height: 26px;
  font-family: $fontR;
  font-weight: 400;
  color: #e24f4f;
}
.tfooter tr td .ssg_cross {
  font-size: 20px;
  line-height: 26px;
  font-family: $fontR;
  font-weight: 400;
  color: #e24f4f;
}
.tbl_data {
  width: 100%;
  border-top: 1px solid #666;
}
.tbl_data thead th {
  padding: 12px 0 10px 0;
  background: #f9f9f9;
  border-bottom: 1px solid #d1d1d1;
  color: #222;
}
.tbl_data tbody th {
  padding: 12px 0 10px 0;
  text-align: left;
  color: #222;
  vertical-align: top;
  background: #f9f9f9;
  border-right: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
}
.tbl_data tbody th span {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 0 10px;
  font-size: 12px;
  color: #272727;
}
.tbl_data tbody th div span {
  display: inline;
  padding: 0;
  font-size: inherit;
  color: inherit;
}
.tbl_data tbody td {
  padding: 12px 0 10px 0;
  border-bottom: 1px solid #e5e5e5;
}
.tbl_data tbody td div {
  padding: 0 10px;
  font-size: 12px;
  color: #272727;
}
.tbl_data tbody td div div {
  padding: 0;
  font-size: inherit;
  color: inherit;
}
.tbl_data tbody td:first-child {
  background: none;
}
