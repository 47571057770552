.product_detail .all_amount {
  overflow: hidden;
  height: 40px;
  line-height: 40px;
}
.product_detail .all_amount li.fl_left {
  float: left;
  height: 40px;
  padding-left: 15px;
  line-height: 40px;
}
.product_detail .all_amount li.fl_right {
  float: right;
}
.product_detail .all_amount li:first-child {
  padding-left: 0;
}
.product_detail .data_list.order_addr {
  margin-top: 15px;
  padding: 13px 0 11px;
  border-top: 1px solid #666;
  border-bottom: 1px solid #ebebeb;
}
.product_detail .data_list.small.order_addr li {
  background: none;
}
.product_detail .data_list.small.order_addr li strong {
  padding-left: 5px;
  color: #000;
}
.tbl_data_scroll02 .table_scroll {
  height: 363px;
}
.table_scroll .line_dotted {
  background: url(#{$imgPath}/common/bg_vertical.gif) no-repeat 0 10px;
}
.garamond {
  font-weight: bold;
  font-size: 20px;
}
.tab_style {
  overflow: hidden;
}
.tab_style:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.tab_style li {
  float: left;
  padding: 0;
  font-family: $fontR;
  font-weight: 400;
}
.tab_style li a {
  display: block;
  text-decoration: none;
}
.tab_style.small li {
  background: url(#{$imgPath}/display/bg_line.gif) no-repeat 0 4px;
}
.tab_style.small li:first-child {
  background-image: none;
}
.tab_style.small li a {
  padding: 0 12px 0 14px;
  color: #666;
}
.tab_style.small li:first-child a {
  padding: 0 12px 0 0;
}
.tab_style.small li.active a {
  color: #222;
  font-weight: bold;
  text-decoration: underline;
}
.tab_style.small li a:hover {
  color: #222;
  font-weight: bold;
  text-decoration: underline;
}
.tab_style.large {
  height: 41px;
  width: 100%;
  background: url(#{$imgPath}/common/bg_tab.gif) repeat-x 0 100%;
}
.tab_style.large li {
  width: 158px;
  border: 1px solid #c4c4c4;
  border-bottom: 0;
  margin-left: -2px;
}
.tab_style.large li:first-child {
  width: 156px;
  margin-left: 0;
}
.tab_style.large li a {
  padding: 9px 0 10px;
  border: 1px solid #fff;
  border-bottom: 0;
  color: #747474;
  text-align: center;
}
.tab_style.large li a em {
  color: #222;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: -1px;
}
.tab_style.large li.active,
.tab_style.large li.lst_over {
  position: relative;
  height: 40px;
  border: 1px solid #777;
  border-bottom: 0;
}
.tab_style.large li.active a,
.tab_style.large li a:hover {
  overflow: hidden;
  position: relative;
  padding: 9px 0 12px;
  border: 1px solid #777;
  border-bottom: 0;
  background: #fff;
  color: #222;
  font-weight: bold;
  letter-spacing: -1px;
}
.tab_style.large li.active a em,
.tab_style.large li a:hover em {
  font-weight: normal;
}
.prim_contarea ul.data_list li span {
  display: block;
  color: #999;
}
.prim_contarea h3 {
  color: #222;
  padding-bottom: 0;
}
.layer_pop .view_photo {
  overflow: hidden;
  width: 532px;
  margin: 0 auto;
}
.layer_pop .view_photo .large_photo {
  width: 500px;
  height: 500px;
  margin: 0 auto 20px;
  font-size: 0;
  line-height: 0;
  border: 1px solid #000;
}
.layer_pop .view_photo .sliding_area {
  position: relative;
  width: 530px;
  height: 80px;
}
.layer_pop .view_photo .thumbnail_list {
  overflow: hidden;
  max-width: 580px;
  height: 80px;
  margin: 0 auto;
  text-align: center;
  padding-left: 45px;
}
.layer_pop .view_photo .thumbnail_list li {
  float: left;
  display: inline-block;
  width: 70px;
  height: 80px;
  margin-left: 22px;
  padding-top: 8px;
}
.layer_pop .view_photo .thumbnail_list li:first-child {
  margin-left: 0;
}
.layer_pop .view_photo .thumbnail_list li.active {
  position: relative;
}
.layer_pop .view_photo .thumbnail_list li.active .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 77px;
  background-image: url(#{$imgPath}/common/thumbnail_frame.png);
  background-repeat: no-repeat;
}
.layer_pop .photo_slide_module {
  overflow: hidden;
  width: 600px;
  height: 726px;
}
.layer_pop .direction.medium .btn.prev {
  left: 18px;
  background-position: 0 center;
}
.layer_pop .direction.medium .btn.next {
  right: 16px;
  background-position: -11px center;
}
.layer_pop .direction.medium .btn {
  position: absolute;
  top: 28px;
  width: 11px;
  height: 100%;
  min-height: 18px;
  border-radius: 0;
  background-color: transparent;
  background-image: url(#{$imgPath}/common/sp_fr.png);
  background-repeat: no-repeat;
}
.enuri .money_wrap .sale em {
  font-size: 11px;
  color: #a1a1a1;
  text-decoration: line-through;
}
.enuri .summary strong {
  color: #222;
}
.enuri table .summary {
  overflow: hidden;
  width: 280px;
  text-overflow: ellipsis;
  word-wrap: normal;
  white-space: nowrap;
}
.enuri .new_popup {
  display: inline-block;
  width: 11px;
  height: 11px;
  top: -1px;
  background: url(#{$imgPath}/common/ico_link.png) 0 0 no-repeat;
  background-size: 100%;
  vertical-align: middle;
}
.enuri .table_scroll table tr td {
  padding-left: 20px;
  border-left: 1px solid #e5e5e5;
}
.pop_importing_address .aside {
  text-align: right;
  margin: 5px 0;
}
.enuri .tbl_data_scroll {
  margin-top: 12px;
}
.enuri .tbl_data_scroll .table_scroll.table_scroll2 {
  height: 226px;
}
.enuri .tbl_data_scroll .table_scroll.table_scroll2 .logo_mall {
  display: inline-block;
  width: 74px;
  height: 16px;
  background: url(#{$imgPath}/common/ico_mall_v2.png);
}
.certification {
  width: 678px;
}
.certification .button_area {
  text-align: center;
}
.certification .button_area .btn:first-child {
  margin-right: 10px;
}
.certification .notice {
  text-align: center;
  margin-top: 13px;
}
.certification .advice {
  margin-top: 40px;
  padding-top: 12px;
  border-top: 1px solid #d1d1d1;
}
.certification .advice li {
  padding-left: 5px;
  letter-spacing: -1px;
  font-size: 11px;
  text-align: left;
  color: #767676;
  background: url(#{$imgPath}/common/bullet_square_01.gif) no-repeat 0 6px;
}
.layer_pop .delivery-cancel {
  padding: 0 20px;
  margin-bottom: 20px;
}
.layer_pop .delivery-cancel p {
  padding: 25px 0 25px 9px;
  border-bottom: 1px solid #d1d1d1;
  border-top: 1px dotted #666;
  font-family: $fontR;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -1px;
}
.layer_pop h2.delivery_cancel.no_br {
  border: none;
}
.layer_pop .pop_btn_area.delivery_cncl {
  padding-bottom: 20px;
}
.layer_pop h2.blind {
  height: 1px;
  font-size: 0;
  line-height: 0;
  padding: 0;
}
.item_quantity.medium {
  overflow: hidden;
  position: relative;
  width: 40px;
  height: 21px;
  text-align: left;
}
.item_quantity.medium input {
  float: left;
  width: 29px;
  height: 19px;
  font-size: 11px;
  border: 1px solid #dedede;
  background-color: #fff;
  text-align: center;
  vertical-align: top;
  line-height: 19px;
}
.item_quantity.medium.normal input {
  color: #777;
}
.item_quantity.medium.slightly input {
  color: #ccc;
}
.item_quantity.medium .btn {
  position: absolute;
  right: 0;
  width: 11px;
  height: 11px;
}
.item_quantity.medium .btn.increase {
  top: 0;
}
.item_quantity.medium .btn.decrease {
  bottom: 0;
}
.item_quantity.medium .btn .ir {
  background-image: url(#{$imgPath}/common/btn_item_quantity.gif);
}
.item_quantity.medium.normal .btn.increase .ir {
  background-position: 0 0;
}
.item_quantity.medium.normal .btn.decrease .ir {
  background-position: 0 -10px;
}
.item_quantity.medium.slightly .btn.increase .ir {
  background-position: -11px 0;
}
.item_quantity.medium.slightly .btn.decrease .ir {
  background-position: -11px -10px;
}
.content_primary .data_table {
  border-right: 0;
  border-left: 0;
}
.content_primary .data_table td strong {
  padding: 0;
  text-align: left;
}
.content_primary .data_list {
  padding: 10px 10px 0;
}
.content_primary .data_list.medium li {
  padding: 3px 10px;
  background: url(#{$imgPath}/common/bullet_square_01.gif) no-repeat 0 9px;
  line-height: 14px;
}
.content_primary .data_list.typemd {
  width: 100%;
  padding: 0;
}
.content_primary .data_list.typemd li {
  padding: 13px 10px 11px;
  background: 0 none;
  border-top: 1px dotted #e0e0e0;
  line-height: 18px;
}
.content_primary .data_list.typemd li:first-child {
  padding: 0 10px 11px;
  border-top: none;
}
.content_primary .data_list.typemd li img {
  width: auto !important;
  height: auto !important;
  max-width: 920px;
}
.content_primary .paginate {
  margin-top: 10px;
}
.content_primary .clear {
  zoom: 1;
}
.content_primary .clear:after {
  display: block;
  visibility: hidden;
  clear: both;
  width: 0;
  height: 0;
  content: '';
}
.content_primary .data_table .data .taln {
  display: inline-block;
  margin: 1px 5px 0 0;
  font-size: 12px;
  vertical-align: top;
}

/* 상품상세 */
.detail_product {
  width: 100%;
  position: relative;
  z-index: 2;
}
.detail_product:after {
  content: '';
  display: block;
  clear: both;
}
.detail_product .detail_left {
  width: 450px;
  float: left;
  margin-right: 35px;
  padding-bottom: 20px;
}
.detail_product .detail_left:after {
  content: '';
  display: block;
  clear: both;
}
.detail_product .detail_cont {
  width: 480px;
  float: left;
  padding-top: 10px;
}
.detail_product .item_image {
  position: relative;
  height: 450px;
  width: 450px;
  margin-top: 30px;
  *zoom: 1;
}
.detail_product .item_image img {
  vertical-align: top;
}
.detail_product .item_image .viewport {
  width: 450px;
  height: 450px;
  overflow: hidden;
}
.detail_product .item_image .viewport li {
  position: relative;
  float: left;
}
.detail_product .item_image .btn_zoom {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 450px;
  height: 450px;
  background: url(#{$imgPath}/common/btn_zoomin.png) 202px 199px no-repeat;
}
.detail_product .item_image.item_image_hover .btn_zoom {
  display: block !important;
}
.detail_product .item_image .direction.layer a.btn {
  top: 210px;
  width: 24px;
  height: 47px;
  background: url(#{$imgPath}/common/bg_direction_layer.png) no-repeat;
  z-index: 30;
}
.detail_product .item_image .direction.layer a.btn.prev {
  background-position: 0 -55px;
}
.detail_product .item_image .direction.layer a.btn.next {
  background-position: -76px -55px;
}
.detail_product .item_image .direction.layer a.btn.disable {
  display: none;
}
.detail_product .item_image .direction.layer a.btn.prev:hover {
  background-position: 0 0;
}
.detail_product .item_image .direction.layer a.btn.next:hover {
  background-position: -76px 0;
}
.opt_otherwrap {
  margin: 10px 0 -11px;
  padding: 0 10px;
  border: 1px solid #aaa;
  border-left: 0 none;
  border-right: 0 none;
  background: #f9f9f9;
  text-align: left;
}
.opt_otherwrap .sp_opt {
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: 10px;
  background: url(#{$imgPath}/product/sp_opt.png) no-repeat;
  line-height: 999px;
  vertical-align: top;
}
.opt_otherwrap .opt_tip {
  padding: 8px 10px 3px;
  letter-spacing: -1px;
}
.opt_otherwrap .opt_othlist {
  border-top: 1px dotted #969696;
}
.opt_otherwrap .opt_othlist:first-child {
  border-top: 0 none;
}
.opt_otherwrap .opt_full {
  width: 100%;
  padding: 8px 0 7px;
  line-height: 18px;
  color: #333;
}
.opt_otherwrap .opt_full:after {
  display: block;
  clear: both;
  content: '';
}
.opt_otherwrap .optt_left {
  float: left;
  width: 280px;
}
.opt_otherwrap .optt_left .color1 {
  color: #3992ff;
}
.opt_otherwrap .optt_left .color2 {
  color: #e7340b;
}
.opt_otherwrap .optt_checkpd {
  position: relative;
  padding-left: 23px;
  zoom: 1;
}
.opt_otherwrap .optt_checkpd .ico_optcheck {
  position: absolute;
  left: 2px;
  top: 1px;
  width: 15px;
  height: 15px;
  background-position: 0 0;
}
.opt_otherwrap .optt_checkpd .ico_optlist {
  position: absolute;
  left: 18px;
  top: 5px;
  width: 7px;
  height: 7px;
  background-position: -34px 0;
}
.opt_otherwrap .optt_rem {
  color: #888;
  white-space: nowrap;
}
.opt_otherwrap .optt_right {
  float: right;
}
.opt_otherwrap .opt_clist li {
  border-top: 1px dotted #969696;
}
.opt_otherwrap .opt_clist .optt_checkpd {
  padding-left: 32px;
}
.opt_otherwrap .optt_delpd {
  position: relative;
  padding-right: 20px;
  zoom: 1;
}
.opt_otherwrap .optt_delpd .optt_del {
  display: inline-block;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 2px;
  width: 13px;
  height: 13px;
  background-position: -18px 0;
  line-height: 999px;
  vertical-align: top;
}
.opt_otherwrap .optt_checkwarea {
  width: 148px;
}
.opt_otherwrap .optt_checkwarea:after {
  display: block;
  clear: both;
  content: '';
}
.opt_otherwrap .optt_checkwarea .item_quantity {
  float: left;
  margin: -2px 0;
}
.opt_otherwrap .optt_cwright {
  float: right;
  text-align: right;
}
.opt_sumwrap {
  margin-top: 10px;
  padding: 14px 10px 0;
  text-align: right;
  background: url(#{$imgPath}/product/bg_item_header.gif) 0 0 repeat-x;
}
.opt_sumwrap .txt {
  display: inline-block;
  margin: -8px 10px 8px 0;
  font-size: 0;
  line-height: 0;
  vertical-align: bottom;
}
.opt_sumwrap .price {
  display: inline-block;
  vertical-align: top;
}
.opt_sumwrap .price .ssg_price {
  display: inline-block;
  font-size: 27px;
  font-family: $fontR;
  font-weight: 400;
  line-height: 32px;
  color: #ff3945;
  vertical-align: top;
}
.opt_sumwrap .price .ssg_tx {
  display: inline-block;
  margin: -7px 0 7px 2px;
  font-size: 0;
  line-height: 0;
  vertical-align: bottom;
}
.review_please {
  overflow: hidden;
  margin-top: 20px;
}
.review_please h3,
.review_please .please_comment strong {
  float: left;
  margin-right: 15px;
  padding-bottom: 0;
  font-size: 18px;
  font-family: $fontR;
  font-weight: 400;
  color: #000;
  letter-spacing: -1px;
}
.review_please .star_rating.large {
  display: block;
  float: left;
  width: 80px;
  height: 24px;
  background-position: 0 -35px;
  overflow: visible;
}
.review_please .star_rating,
.review_please .star_rating span {
  display: block;
  height: 24px;
  background-color: transparent;
  background-image: url(#{$imgPath}/product/bg_star_rating02.png);
  background-repeat: no-repeat;
}
.review_please .star_rating.large.weighty span {
  background-position: 0 -55px;
}
.review_please .star_rating.large.weighty .num {
  float: left;
  display: inline;
  height: auto;
  background: none;
}
.detail_product .review_area {
  float: left;
  position: relative;
  width: 410px;
  height: auto;
  margin-top: 0;
  padding: 2px 20px 0;
  *zoom: 1;
}
.detail_product .review_area .rate {
  float: left;
  width: 150px;
  margin-top: 0;
  height: 24px;
}
.detail_product .review_area .rate .num {
  display: block;
  float: left;
  margin-top: 0;
  font-family: $fontR;
  font-weight: 400;
  font-size: 24px;
  color: #f14f4f;
  line-height: 24px;
}
.detail_product .review_area .rate .num strong {
  margin-right: 7px;
  font-weight: normal;
}
.detail_product .review_area .all_num {
  float: right;
  margin-left: 0;
  padding: 4px 6px 0 0;
  background: url(#{$imgPath}/product/ico_arrow.gif) no-repeat 100% 10px;
}
.detail_product .review_area .all_num a {
  color: #666;
  text-decoration: underline;
}
.detail_product .review_area .all_num a .count {
  color: #d54159;
}
.info_wrap {
  padding-top: 9px;
  color: #666;
  *zoom: 1;
}
.info_wrap:after {
  content: '';
  display: block;
  clear: both;
}
.info_wrap .column_l .layer_wrap {
  float: none;
}
.info_wrap .column_l .layer_wrap.layer_delivery {
  float: left;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  line-height: 0;
  vertical-align: middle;
}
.info_wrap .layer_wrap {
  position: relative;
  float: left;
  z-index: 0;
  zoom: 1;
}
.info_wrap .layer_wrap:after {
  display: block;
  clear: both;
  content: '';
}
.info_wrap .layer_wrap .taln {
  display: inline-block;
  vertical-align: top;
}
.item_header {
  padding: 10px 0 20px;
  background-image: url(#{$imgPath}/product/bg_item_header.gif);
  background-position: 0 bottom;
  background-repeat: repeat-x;
}
.item_header h2 {
  height: auto;
  margin-top: 7px;
  padding: 0 0 14px 0;
  font-family: $fontR;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  text-align: left;
  border-bottom: 0;
}
.detail_product .item_header {
  padding: 10px 0 15px;
  background-image: url(#{$imgPath}/product/bg_item_header.gif);
  background-position: 0 bottom;
  background-repeat: repeat-x;
}
.detail_product .item_header h2 {
  height: auto;
  margin-top: 7px;
  padding: 0;
  font-family: $fontR;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  text-align: left;
  border-bottom: 0;
}
.detail_product .item_header .icons {
  text-align: left;
  font-size: 0;
  line-height: 0;
  padding-bottom: 10px;
  border-bottom: 1px dotted #707070;
}
.item_header .book_info {
  overflow: hidden;
  width: 480px;
  margin-top: 7px;
}
.item_header .book_info li {
  float: left;
  height: 18px;
  line-height: 18px;
  padding-left: 9px;
  margin-right: 10px;
  background: #fff url(#{$imgPath}/product/bg_bullet01.png) 0 6px no-repeat;
  color: #666;
  font-family: $fontR;
  font-weight: 400;
  white-space: nowrap;
}
.icons .emart,
.icons .ssgmall,
.icons .depart,
.icons .traders,
.icons .boons {
  margin-right: 7px;
}
.icons .ico_item {
  margin-right: 4px;
}
.info_wrap .data_table .data .taln strong {
  font-weight: bold;
}
.info_wrap .layer_wrap label {
  display: inline-block;
  vertical-align: top;
}
.layer_wrap .tooltip_box {
  left: auto;
  right: 0;
  top: 16px;
  letter-spacing: 0;
}
.layer_wrap .tooltip_box.small {
  color: #666;
}
.layer_wrap .tooltip_box.small .txt.sec {
  padding-top: 5px;
  letter-spacing: -1px;
}
.layer_wrap .tooltip_box.date {
  width: 118px;
}
.layer_wrap .tooltip_box .txt {
  display: block;
  font-size: 11px;
  text-align: center;
}
.layer_wrap .tooltip_box .txt em {
  color: #000;
  letter-spacing: 0;
}
.layer_wrap .tooltip_box .period {
  margin-top: 3px;
  border-top: 1px dotted #666;
  padding-top: 10px;
}
.layer_wrap .tooltip_box .period strong {
  display: block;
  font-size: 11px;
  color: #222;
}
.layer_wrap .tooltip_box .period span {
  color: #000;
  line-height: 20px;
  letter-spacing: 0;
}
.layer_wrap .tooltip_box .total strong {
  color: #e24f4f;
}
.layer_wrap .tooltip_box.medium.period {
  min-width: 140px;
}
.layer_wrap .tooltip_box.medium.period02 {
  width: 182px;
}
.info_wrap .help_tooltip {
  display: inline-block;
  width: 13px;
  height: 13px;
  line-height: 13px;
  padding-right: 13px;
  vertical-align: middle;
}
.info_wrap .delivery_info dd.delivery_date .help_tooltip.blind_txt {
  line-height: 0;
  font-size: 0;
}
.info_wrap .hr {
  border-bottom: 1px dotted #707070;
  margin: 10px 0 10px 0;
  font-size: 0;
  line-height: 0;
  clear: both;
}
.detail_product .info_wrap .data .link_block li .tooltip_box .tooltip_close {
  position: absolute;
  right: 15px;
  top: 9px;
  width: 12px;
  height: 12px;
}
.detail_product .info_wrap .data .link_block li .tooltip_box .tooltip_close img {
  vertical-align: top;
}
.info_wrap .column_l dl {
  *zoom: 1;
}
.detail_product .info_wrap .column_l dl dt {
  float: left;
  width: 122px;
  padding: 6px 0 5px;
  line-height: 12px;
  text-align: left;
}
.detail_product .info_wrap .column_l dl dt.sell_price {
  padding-top: 8px;
}
.detail_product .info_wrap .column_l dl dd {
  float: right;
  width: 358px;
  padding-top: 4px;
  zoom: 1;
  text-align: left;
}
.detail_product .info_wrap .column_l dl dd.price_wrap {
  padding-bottom: 0;
}
.detail_product .info_wrap .column_l dl dd .price {
  line-height: normal;
  font-size: 17px;
  font-weight: bold;
  color: #3b3b3b;
  font-style: normal;
  vertical-align: -1px;
}
.detail_product .info_wrap .column_l dl dd .price3 {
  display: inline-block;
  margin-top: -7px;
  font-weight: bold;
  font-size: 27px;
  color: #3b3b3b;
  line-height: 33px;
  vertical-align: -3px;
}
.detail_product .info_wrap .column_l dl dd .vat {
  font-size: 11px;
  font-family: $fontR;
  font-weight: 400;
  color: #888;
}
.info_wrap .unique:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.info_wrap .unique.fixed_price {
  margin-top: -3px;
}
.info_wrap .unique dt {
  padding-top: 11px !important;
}
.info_wrap .unique dd.text {
  padding-top: 7px !important;
}
.info_wrap .unique .layer_wrap {
  margin-left: 8px;
  margin-top: 0;
}
.layer_pop .info_wrap .unique .layer_wrap.layer_delivery {
  margin-top: 0;
  vertical-align: middle;
  line-height: 0;
}
.layer_pop .info_wrap .delivery_info.sale.unique .layer_wrap .tooltip_box {
  top: 18px !important;
}
.info_wrap .delivery_info.unique .layer_wrap .tooltip_box {
  top: 23px !important;
}
.info_wrap .delivery_info.unique dd.delivery_date .text {
  *margin-right: 4px;
}
.info_wrap .delivery_info.unique dd.delivery_date.best .won {
  vertical-align: -1px !important;
}
.info_wrap dl dd .price {
  display: inline-block;
  font-family: $fontR;
  font-weight: 400;
  font-size: 24px;
  font-style: italic;
  color: #e53838;
  line-height: 33px;
  vertical-align: middle;
}
.info_wrap dl dd .price2 {
  display: inline-block;
  margin-top: -7px;
  font-weight: bold;
  font-size: 27px;
  color: #f24c4c;
  line-height: 33px;
  vertical-align: -3px;
}
.info_wrap dl dd .sale_none {
  font-family: $fontR;
  font-weight: 400;
  font-size: 28px;
  color: #000;
  line-height: 28px;
}
.info_wrap dl dd .onday {
  font-family: $fontR;
  font-weight: 400;
  font-size: 24px;
  color: #e53838;
  line-height: 24px;
}
.info_wrap dl dd.price_wrap .price_ty {
  float: left;
  margin-right: 0;
}
.info_wrap dl dd.price_wrap .price_ty img {
  vertical-align: -2px;
}
.info_wrap dl dd.price_wrap .tooltip_box {
  left: auto;
  right: 0;
  top: 17px;
}
.info_wrap dl dd.price_wrap .tooltip_box.discount .total th strong,
.info_wrap dl dd.price_wrap .tooltip_box.discount .total td strong {
  color: #e24f4f;
}
.info_wrap dl dd.price_wrap.best .layer_wrap .tooltip_box {
  width: 211px;
}
.info_wrap .delivery_info dd.delivery_date .text .ssg_tx {
  font-size: 12px;
}
.info_wrap .layer_delivery {
  margin-top: 3px;
  *margin-top: 0;
}
.info_wrap .delivery_info dd.delivery_date .text {
  font-size: 12px;
  color: #666;
}
.info_wrap .delivery_info dd.delivery_date .text .ssg_tx {
  font-size: 12px;
}
.info_wrap .delivery_info dd.delivery_reservation .text {
  max-width: 299px;
}
.info_wrap .delivery_info dd.delivery_date .taln {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  margin: 0;
  vertical-align: middle;
  line-height: 16px;
}
.info_wrap .delivery_info dd.delivery_date .taln.txt02 {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  margin: 0;
}
.info_wrap .delivery_info dd.delivery_date .text .login a {
  color: #666;
}
.info_wrap .delivery_info dd.delivery_date .help_tooltip {
  margin: 0;
  padding-right: 0;
  vertical-align: middle;
}
.info_wrap .delivery_info dd.delivery_date .help_tooltip.none {
  margin-top: 0;
}
.info_wrap .poz {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  position: relative;
  height: 13px;
  margin: 0;
  line-height: 13px;
  vertical-align: 0;
}
.info_wrap .poz.poz_type {
  vertical-align: 0;
  *padding-left: 5px;
}
.info_wrap .poz_view {
  margin-top: -4px;
  margin-left: 2px;
  *padding-left: 5px;
}
.info_wrap .layer_wrap p {
  letter-spacing: -1px;
}
.info_wrap .layer_wrap .txt_info {
  display: block;
  letter-spacing: -1px;
}
.info_wrap .layer_wrap .checkbox {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin: 2px 0 0 0;
  padding: 0;
  vertical-align: top;
}
.info_wrap .card_benefit {
  padding: 0;
  margin-top: -15px;
  clear: both;
}
.info_wrap .card_benefit:after {
  content: '';
  display: block;
  clear: both;
}
.info_wrap .card_benefit .help_tooltip {
  padding-right: 0;
}
.layer_pop .info_wrap .card_benefit {
  padding: 0;
  margin-top: -10px;
}
.info_wrap .card_benefit dt {
  margin-top: 15px;
}
.layer_pop .info_wrap .card_benefit dt {
  margin-top: 10px;
}
.info_wrap .card_benefit:after {
  content: '';
  display: block;
  clear: both;
}
.info_wrap .card_benefit dd {
  margin-top: 10px;
}
.info_wrap .card_benefit dd .btn_ssgpay_cnfrm {
  margin-left: 18px;
}
.info_wrap .card_benefit.special_card dt {
  padding-top: 2px !important;
}
.layer_pop .info_wrap .card_benefit dd {
  padding: 0;
  line-height: 12px;
  margin-top: 7px;
}
.info_wrap .card_benefit .tit {
  margin: 0;
}
.info_wrap .card_benefit .tit .price {
  color: #3b3b3b;
}
.info_wrap .card_benefit .tit .tooltip_box {
  right: 0;
  top: 16px;
}
.info_wrap .card_benefit .tit .tooltip_box .title,
.info_wrap .tooltip_box .title {
  display: block;
  margin-bottom: 10px;
  padding-bottom: 5px;
  text-align: left;
  font-size: 12px;
  color: #272727;
  border-bottom: 1px dotted #666;
  line-height: 16px;
  white-space: nowrap;
  background: 0 none;
}
.info_wrap .card_benefit .tit .tooltip_box .title,
.info_wrap .tooltip_box .period {
  padding-top: 10px;
}
.info_wrap .tooltip_box.discount .period {
  border-top: 1px dotted #666;
}
.info_wrap .card_benefit .tit .tooltip_box .title,
.info_wrap .tooltip_box .period strong,
.info_wrap .card_benefit .tit .tooltip_box .title,
.info_wrap .tooltip_box .period span {
  font-size: 11px;
  color: #222;
}
.info_wrap .card_benefit .tit .tooltip_box .title {
  font-size: 12px;
  padding: 3px 0;
  color: #272727;
  letter-spacing: -1px;
}
.info_wrap .tooltip_box .period p {
  font-size: 11px;
  padding: 3px 0;
  color: #666;
  letter-spacing: -1px;
}
.info_wrap .card_benefit .tit .tooltip_box.special .title {
  padding: 0 0 7px;
}
.info_wrap .tooltip_box.discount .sale_txt {
  border-top: 1px dotted #666;
}
.info_wrap .card_benefit .tit .text {
  font-size: 11px;
  letter-spacing: -1px;
}
.info_wrap .card_benefit .tit .card_event {
  letter-spacing: -1px;
}
.info_wrap .card_benefit .tit .card_event span {
  display: inline;
  line-height: 18px;
}
.info_wrap .card_benefit .tit .layer_wrap {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  float: none;
  line-height: 0;
  vertical-align: middle;
}
.info_wrap .card_benefit ul li {
  margin-top: 5px;
}
.info_wrap .card_benefit ul li {
  font-size: 11px;
  color: #888;
}
.info_wrap .btn.go {
  display: inline-block;
  width: 63px;
  height: 16px;
  padding: 1px 4px;
  border: 1px solid #d8d8d8;
  background: #fff url(#{$imgPath}/common/bg_btn_arr01.gif) 61px 7px no-repeat;
  line-height: 17px;
  font-size: 11px;
  color: #666;
  letter-spacing: -0.1em;
  text-align: left;
  vertical-align: middle;
  text-align: left;
}
.info_wrap .btn.go.go7 {
  margin-top: -3px !important;
}
.info_wrap .btn.go.go_none {
  width: auto;
}
.info_wrap .delivery_info .text02 {
  clear: both;
}
.info_wrap .delivery_info.none dt,
.info_wrap .delivery_info.none dd {
  padding-bottom: 5px !important;
}
.info_wrap .specify .delivery_info dd,
.info_wrap .specify .delivery_date dd {
  padding-top: 4px !important;
}
.info_wrap .delivery_info:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.info_wrap .delivery_info dd.delivery_date .text {
  font-size: 12px;
  color: #666;
}
.info_wrap .delivery_info dd.delivery_date .text .ssg_tx {
  font-size: 12px;
}
.info_wrap .delivery_info dd.delivery_date .text strong .ssg_tx {
  vertical-align: top;
}
.info_wrap .delivery_info dd.delivery_reservation .text {
  max-width: 299px;
}
.info_wrap .delivery_info dd.delivery_date .taln {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  margin: 0;
  vertical-align: middle;
  line-height: 16px;
}
.info_wrap .delivery_info dd.delivery_date .taln.txt02 {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  margin: 0;
}
.info_wrap .delivery_info dd.delivery_date .text .login a {
  color: #666;
}
.info_wrap .delivery_info dd.delivery_date .help_tooltip {
  margin: 0;
  padding-right: 0;
  vertical-align: middle;
}
.info_wrap .delivery_info dd.delivery_date .help_tooltip.none {
  margin-top: 0;
}
.info_wrap .delivery_info dd.delivery_date .layer_wrap {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  float: none;
  line-height: 0;
}
.info_wrap .delivery_info dd.delivery_date .layer_wrap .tooltip_box {
  left: auto;
  right: 0;
  top: 16px;
}
.info_wrap .delivery_info dd.delivery_date .layer_wrap .tooltip_box.tooltip_delivery {
  padding: 0;
  width: 202px;
}
.layer_pop .info_wrap .delivery_info dd.delivery_date.best .layer_wrap .tooltip_box {
  left: auto;
  right: 0;
  top: 19px;
}
.info_wrap .delivery_info dd.delivery_date.delivery_sort .layer_wrap .tooltip_box {
  left: auto;
  right: 0;
  top: 16px;
}
.info_wrap .column_l dl.option_dl2 {
  padding-bottom: 0;
}
.layer_pop .info_wrap .column_l dl.option_dl2 .field {
  margin-top: 5px;
  padding-top: 0px;
  max-height: 218px;
  overflow-y: auto;
  *overflow-x: hidden;
}
.layer_pop .info_wrap .column_l dl.option_dl2.chose .field {
  max-height: none;
  overflow-y: auto;
  *overflow-x: hidden;
}
.info_wrap .option_dl2:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.info_wrap .column_l dl.option_dl2 .field .data_table {
  margin-top: -2px;
}
.info_wrap .column_l dl.option_dl2.line_once {
  margin-bottom: 10px;
}
.layer_pop .info_wrap .column_l dl.option_dl2 dd {
  padding-top: 0;
}
.detail_product .info_wrap .column_l .line_once dd {
  padding: 0;
}
.detail_product .info_wrap .column_l .line_once dd .data_table th {
  padding: 0;
}
.detail_product .info_wrap .column_l .line_once dd .data_table td {
  padding: 0;
}
.info_wrap .column_l dl.option_dl2 dd.composition {
  padding-top: 0;
}
.info_wrap .column_l dl.option_dl2 dd.composition .data_table .optional th,
.info_wrap .column_l dl.option_dl2 dd.composition .data_table .optional td {
  padding: 5px 0 0;
}
.info_wrap .field .data_table.x_small tr td.price .in {
  line-height: 21px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 12px;
  padding: 0;
}
.info_wrap .field .data_table.x_small tr td.disition .in {
  padding-right: 11px;
  text-align: right;
}
.info_wrap .option_dl4:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.info_wrap .option_dl4.total_dl {
  margin-top: 10px;
  padding-top: 15px;
  background: url(#{$imgPath}/product/bg_item_header.gif) left top repeat-x;
}
.info_wrap .option_dl4.total_dl dd {
  float: left;
  text-align: left;
}
.info_wrap .option_dl4.total_dl dd .data {
  float: left;
}
.info_wrap .column_l dl.hope_dl {
  padding-top: 5px;
}
.info_wrap .column_l dl.hope_dl:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.info_wrap .column_l dl.hope_dl dt {
  margin-top: 0;
  padding-top: 8px !important;
}
.info_wrap .column_l dl.hope_dl dt .tooltip_box {
  width: 205px;
  right: 0;
  top: 15px;
}
.info_wrap .column_l dl.hope_dl dd {
  padding-top: 0 !important;
}
.info_wrap .column_l dl.hope_dl dd .data {
  margin: 0;
}
.info_wrap .column_l dl.hope_dl dt .help_tooltip {
  padding-right: 55px;
}
.info_wrap .column_l dl.hope_dl dt img {
  margin-top: 1px;
  vertical-align: top;
}
.info_wrap .column_l dl.orderer_option {
  margin-top: 6px;
}
.info_wrap .column_l dl.orderer_option:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.info_wrap .column_l dl.orderer_option dt {
  margin-top: 0;
}
.info_wrap .column_l dl.orderer_option dt .label.small {
  width: 92px;
  padding-left: 0;
  font-weight: normal;
  line-height: 13px;
  color: #3b3b3b;
  font-size: 11px;
  text-align: left;
  letter-spacing: -1px;
  background: 0 none;
}
.info_wrap .column_l dl.orderer_option dd {
  padding: 0 0 3px !important;
  vertical-align: top;
}
.info_wrap .column_l dl.orderer_option dd .input_text.small {
  font-size: 11px;
  line-height: 28px\9;
}
.info_wrap div.text {
  font-size: 11px;
  color: #666;
  text-align: left;
}
.info_wrap div.text .ssg_tx {
  font-size: 12px;
}
.info_wrap div.text p {
  line-height: 11px;
  letter-spacing: -1px;
  margin-top: 6px;
  margin-bottom: 14px;
}
.info_wrap .deliver_info {
  margin: 6px 0 10px;
  text-align: left;
}
.info_wrap .deliver_info:after {
  content: '';
  display: block;
  clear: both;
}
.info_wrap .button_area .btn.warning,
.info_wrap .button_area .btn.warning span {
  position: relative;
  color: #fff;
  background-color: #f14f4f;
  background-image: none;
}
.info_wrap .button_area .btn.warning.active {
  z-index: 10000;
}
.info_wrap .button_area .xx_large.buy {
  margin-right: 7px;
  margin-bottom: 10px;
}
.info_wrap .button_area .xx_large span.txt {
  width: 365px;
}
.info_wrap .button_area .xx_large span {
  width: 180px;
}
.info_wrap .button_area.btn_wrap_s .xx_large span {
  width: 131px;
}
.info_wrap .button_area.btn_wrap_s .xx_large span.txt {
  width: 374px;
}
.info_wrap .button_area .burial {
  clear: both;
  float: left;
  position: relative;
  width: 190px;
  margin-right: 7px;
}
.info_wrap .button_area .burial .smart_tip {
  left: -57px;
  top: 60px;
}
.info_wrap .button_area .burial .tooltip_box .tooltip_close {
  top: 5px;
  float: none;
  margin-right: 0;
}
.layer_pop .info_wrap .button_area .burial .tooltip_box .data_table tr td {
  letter-spacing: 0;
  text-align: left;
}
.layer_pop .info_wrap .button_area .burial .tooltip_box p.desc {
  text-align: left;
}
.tooltip_box p.btn_wrap {
  padding: 5px 0 0;
}
.info_wrap .button_area .btn.slightest,
.info_wrap .button_area .btn.slightest span {
  color: #666;
  background-image: none;
}
.info_wrap .button_area .btn.slightly,
.info_wrap .button_area .btn.slightly span {
  color: #666;
  background-image: none;
}
.info_wrap .button_area .btn.normal {
  float: left;
  width: 375px;
  margin-right: 7px;
  border: 1px solid #dadada;
}
.info_wrap .button_area .btn.normal,
.info_wrap .button_area .btn.normal span {
  margin-bottom: 10px;
  color: #797979;
  background-color: #f9f9f9;
  background-image: none;
  cursor: default;
}
.info_wrap .button_area .btn.weighty,
.info_wrap .button_area .btn.weighty span {
  color: #fff;
  background-image: none;
}
.info_wrap .button_area .btn.weightiest,
.info_wrap .button_area .btn.weightiest span {
  color: #fff;
  background-color: #3b3b3b;
  background-image: none;
}
.info_wrap .button_area .btn.warning,
.info_wrap .button_area .btn.warning span {
  position: relative;
  color: #fff;
  background-color: #f14f4f;
  background-image: none;
}
.info_wrap .button_area .btn.warning.active {
  z-index: 10000;
}
.info_wrap .button_area {
  position: relative;
  margin: 10px 0 0;
  *zoom: 1;
}
.info_wrap .button_area a {
  float: left;
  margin-right: 7px;
  margin-bottom: 10px;
  padding: 0;
  border: 0;
}
.info_wrap .button_area:after {
  display: block;
  visibility: hidden;
  clear: both;
  width: 0;
  height: 0;
  content: '';
}
.info_wrap .button_area .sold_out_btn {
  clear: both;
  margin-right: 7px;
}
.info_wrap .button_area .advice_txt {
  clear: both;
  width: 387px;
  text-align: center;
}
.info_wrap .button_area p.btn.x_large span {
  width: 376px;
}
.info_wrap .button_area .btn.x_large.clip span {
  width: 64px;
}
.info_wrap .button_area .btn.x_large.cart span,
.info_wrap .button_area .btn.x_large.buy span,
.info_wrap .button_area .btn.x_large.visit span {
  width: 131px;
}
.btn_clip {
  display: block;
}
.btn_clip a {
  width: 85px;
  height: 56px;
  margin: 0 !important;
  background: url(#{$imgPath}/product/btn_clipping_type.png) -200px 0 no-repeat;
}
.btn_clip.wish a {
  background-position: -200px -100px;
}
.btn_clip.clip_red a {
  background-position: 0 -100px;
}
.btn_clip.clip_yellow a {
  background-position: -100px 0;
}
.info_wrap .total_dl dt {
  padding-top: 16px !important;
}
.info_wrap dl dd strong.onday {
  font-family: $fontR;
  font-weight: 400;
  font-size: 24px;
  font-style: italic;
  color: #e53838;
  line-height: 24px;
}
.info_wrap dl dd.price_wrap {
  margin-top: 0;
}
.info_wrap dl dd.price_wrap.best .layer_wrap .tooltip_box.small {
  width: 211px;
}
.info_wrap dl dd.price_wrap.best .layer_wrap .tooltip_box.small .data_table tr td {
  letter-spacing: 0;
}
.info_wrap dl dd.price_wrap .price_ty {
  float: left;
}
.layer_pop .info_wrap dl dd.price_wrap .tooltip_box {
  left: auto;
  right: 0;
  top: 19px;
}
.info_wrap dl dd.price_wrap .tooltip_box .sale_txt {
  border-top: 1px dotted #666;
}
.info_wrap dl dd.text {
  margin-top: -4px;
  font-size: 12px;
  color: #666;
}
.info_wrap dl dd.text.small {
  letter-spacing: -1px;
}
.info_wrap dl dd.text p {
  letter-spacing: -1px;
}
.info_wrap .card_benefit .tit .tooltip_box {
  right: 0;
  top: 14px;
}
.info_wrap .card_benefit .tit .tooltip_box .title,
.info_wrap .tooltip_box .title {
  display: block;
  margin-bottom: 7px;
  padding-bottom: 7px;
  font-size: 12px;
  color: #272727;
  border-bottom: 0;
  line-height: 16px;
  white-space: nowrap;
}
.info_wrap .card_benefit .tit .btn_delivery {
  display: block;
  margin-top: 5px;
}
.info_wrap .card_benefit .tit .ssg_tx {
  vertical-align: -1px;
}
.info_wrap .card_benefit ul li {
  font-size: 11px;
  color: #888;
  letter-spacing: 0;
}
.info_wrap .non_price {
  clear: both;
  margin-top: 7px;
  padding: 8px 0;
  color: #666;
  background: #f4f4f4;
  text-align: center;
}
.info_wrap .non_bottom {
  padding-bottom: 0 !important;
}
.info_wrap .delivery_info dd {
  margin-top: -2px;
  vertical-align: top;
}
.info_wrap .delivery_info .text {
  float: left;
}
.info_wrap .delivery_info .text span {
  display: inline-block;
  font-size: 11px;
  vertical-align: middle;
}
.info_wrap .delivery_info .txt02 {
  margin-right: 3px;
}
.info_wrap .delivery_info dd.delivery_date {
  margin-top: 0;
}
.info_wrap .delivery_info dd.delivery_date .taln {
  display: inline-block;
  margin: 0 6px;
  vertical-align: middle;
  line-height: 16px;
}
.info_wrap .delivery_info dd.delivery_date.low .layer_wrap .btn.x-small.ssg_tooltip {
  margin-top: -1px;
}
.info_wrap .delivery_info dd.delivery_date.txt {
  font-size: 11px;
  vertical-align: top;
  line-height: 12px;
}
.info_wrap .delivery_info dd.delivery_date.txt .btn.time {
  margin-left: 4px;
}
.info_wrap .delivery_info dd.delivery_date.txt .tooltip_box {
  right: 0;
  top: 16px;
}
.info_wrap .delivery_info dd.delivery_date.txt .tooltip_store {
  right: 0;
  top: 19px;
}
.info_wrap .delivery_info dd.delivery_date .just_txt {
  margin-top: -4px;
  width: 100%;
}
.info_wrap .delivery_info dd.delivery_date .text .login a {
  color: #666;
}
.info_wrap .delivery_info dd.delivery_date .help_tooltip {
  margin: 0;
  vertical-align: middle;
}
.info_wrap .delivery_info dd.delivery_date.low .poz {
  margin-top: 6px;
}
.info_wrap .delivery_info dd.delivery_date.txt .btn.go.go5 {
  display: none;
}
.info_wrap .option_dl2 dt {
  float: none;
  width: 100% !important;
}
.info_wrap .option_dl2 dd {
  float: none;
  width: 100% !important;
}
.layer_pop .detail_product .info_wrap .option_dl2 dd.option_txt {
  padding-top: 2px;
}
.layer_pop .detail_product .info_wrap .option_dl2 dd.option_txt .data_table .taln strong {
  padding-right: 0;
}
.info_wrap .option_dl2.chose .in .layer_wrap {
  float: none;
}
.info_wrap .option_dl2.chose .in .btn.x-small.ssg_tooltip {
  margin-left: 0;
}
.layer_pop .info_wrap .option_dl3 {
  zoom: 1;
  margin-top: 0;
}
.info_wrap .option_dl3:after {
  display: block;
  clear: both;
  content: '';
}
.info_wrap .option_dl3 dt {
  display: inline-block;
  vertical-align: top;
}
.info_wrap .option_dl3 dd {
  display: inline-block;
  vertical-align: top;
}
.layer_pop .detail_product .info_wrap .column_l .option_dl3 dd {
  padding-top: 2px;
}
.info_wrap .option_dl3 .more_store {
  margin-top: 15px;
}
.info_wrap .option_dl4 {
  zoom: 1;
}
.info_wrap .column_l .total_dl dt {
  padding: 11px 0 0;
}
.info_wrap .column_l .total_dl dt dt {
  padding: 0;
}
.info_wrap .column_l .total_dl .price_wrap .price2 {
  margin-top: -1px;
}
.info_wrap .option_dl4 .data {
  display: inline-block;
  height: 38px;
  line-height: 38px;
}
.info_wrap .option_dl4 .data .price2 {
  line-height: 38px;
}
.info_wrap .orderer_option dt {
  margin-top: 7px;
}
.info_wrap .orderer_option dt .label.small {
  width: 92px;
  padding-left: 0;
  font-weight: normal;
  line-height: 13px;
  color: #3b3b3b;
  font-size: 11px;
  text-align: left;
  letter-spacing: -1px;
  background: 0 none;
}
.info_wrap .orderer_option dd {
  padding: 3px 0 5px;
  vertical-align: top;
}
.info_wrap .orderer_option dd .input_text.small {
  width: 86%;
  font-size: 11px;
}
.info_wrap .hope_dl .calendar.small table thead tr th.this {
  width: auto;
  text-align: center;
}
.info_wrap .hope_dl .calendar.small table thead tr th a .ir {
  top: 2px;
}
.info_wrap .hope_dl .text .hope_warning {
  margin: 9px 0 5px -114px;
  line-height: 15px;
}
.info_wrap .card_lgroup {
  position: relative;
}
.info_wrap .column_l .dl_other dt {
  padding: 4px 0 0;
}
.info_wrap .column_l .dl_other dd {
  margin-top: -3px;
}
.info_wrap .label.small {
  width: 92px;
  padding-left: 18px;
  background: url(#{$imgPath}/common/bg_bullet01.gif) 5px 44% no-repeat;
  font-weight: normal;
  line-height: 28px;
  color: #222;
  font-size: 11px;
  letter-spacing: -1px;
}
.info_wrap .option_dl td {
  padding: 6px 0 4px;
  color: #666;
  vertical-align: top;
}
.info_wrap .option_dl th {
  padding: 6px 0 4px;
  border: 0;
  background: none;
  vertical-align: top;
}
.info_wrap .option_dl2 td {
  padding: 2px 0 4px;
  color: #666;
  vertical-align: top;
}
.info_wrap .option_dl2 td .input_text.small {
  line-height: 28px;
}
.info_wrap .option_dl2 th {
  padding: 2px 0 4px;
  border: 0;
  background: none;
  vertical-align: middle;
}
.layer_pop .info_wrap .option_dl2.chose .field {
  height: auto !important;
  margin-top: 10px !important;
  padding: 0;
  overflow-y: visible !important;
}
.info_wrap .select.small {
  width: 275px;
}
.info_wrap .field {
  clear: both;
  position: relative;
  margin: 0;
  padding-top: 10px;
  background: none;
  z-index: 20;
  zoom: 1;
}
.info_wrap .field:after {
  display: block;
  clear: both;
  content: '';
}
.info_wrap .field.choose_cart {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 294px;
  padding-top: 0;
}
.info_wrap .field .data_table.x_small tr td {
  padding: 0;
  padding-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 2px solid #fff;
  background: #ffdce2;
  vertical-align: top;
}
.info_wrap .field .data_table.x_small tr th {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: $fontR;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  border-top: 2px solid #fff;
  background: #ffdce2;
}
.info_wrap .field .data_table.x_small tr th .tooltip_box {
  right: 0;
  top: 19px;
}
.info_wrap .field .data_table.x_small tr th .tooltip_box.third_stage {
  right: 99px;
  top: 58px;
}
.info_wrap .field .data_table.x_small tr th .in {
  display: block;
  padding: 0 0 0 15px;
}
.info_wrap .field .data_table.x_small tr th .in input {
  overflow: hidden;
  display: inline-block;
  width: 13px;
  height: 13px;
  margin: 1px 0 0 0;
  padding: 0;
  vertical-align: -2px;
}
.info_wrap .field .data_table.x_small tr th .in label {
  display: inline-block;
  vertical-align: top;
}
.info_wrap .field .data_table.x_small tr.bundle th,
.field .data_table.x_small tr.bundle td {
  border-top: 2px solid #fff;
}
.info_wrap .field .data_table.x_small tr.bundle th {
  padding-left: 25px;
  border-top: 2px solid #fff;
  background-image: url(#{$imgPath}/product/ico_under.png);
  background-position: 20px 16px;
  background-repeat: no-repeat;
  color: #888;
}
.info_wrap .field .data_table.x_small tr td.quantity .in {
  padding: 0 0 0 20px;
}
.info_wrap .field .data_table.x_small tr td.price {
  display: table-cell;
  font-weight: bold;
  text-align: right;
}
.info_wrap .field .data_table.x_small tr td.price .in {
  padding: 0 10px 0 0;
}
.info_wrap .field .data_table.x_small tr td.disition .in {
  padding-right: 20px;
  text-align: right;
}
.info_wrap .field .data_table.x_small .ico_under {
  margin-right: 10px;
  vertical-align: text-top;
}
.info_wrap .field .data_table.x_small .stock {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  color: #888;
}
.info_wrap .field .data_table.x_small .layer_wrap .stock {
  color: #888;
}
.info_wrap .btn.del {
  overflow: hidden;
  position: relative;
  width: 11px;
  height: 11px;
  padding: 0;
  border: 1px solid #d7d7d7;
  vertical-align: middle;
  *width: 13px;
  *height: 13px;
}
.info_wrap .btn.del .ir {
  background: #fff url(#{$imgPath}/common/btn_del.png) center center no-repeat;
  *top: -1px;
  *left: -1px;
  *width: 11px;
  *height: 11px;
}
.info_wrap .message.small {
  margin-top: 12px;
  font-size: 11px;
  color: #888;
}
.info_wrap .column_l .message.small {
  zoom: 1;
}
.info_wrap .column_l .message.small:after {
  content: '';
  display: block;
  clear: both;
}
.info_wrap .point_next {
  overflow: hidden;
  padding: 2px 0;
  text-align: center;
  font-size: 12px;
  color: #666;
}
.info_wrap .point_next strong {
  color: #f14f4f;
}
.info_wrap .data .item_quantity {
  float: left;
}
.info_wrap .data p {
  float: left;
  margin-left: 10px;
  line-height: 21px;
}
.info_wrap .column_l dl.hope_dl dt .tooltip_box {
  right: 0;
  top: 16px;
}
.info_wrap .column_l dl.hope_dl dt .layer_wrap {
  width: 68px;
}
.info_wrap .column_l dl dd.message_dd {
  float: none;
  width: 100%;
}
.info_wrap .column_l dl dd.message_dd .tooltip_box .button_area {
  margin: 0 0 10px 0;
}
.info_wrap .message_dd .help_tooltip {
  vertical-align: middle;
}
.info_wrap .message_dd .tooltip_box {
  right: 0;
  top: 19px;
}
.info_wrap .message.medium {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  float: none;
  margin-top: 0px;
  padding: 5px 10px 5px;
  border: 1px solid #b2b2b2;
  line-height: 19px;
  white-space: nowrap;
}
.info_wrap .message.medium strong {
  height: 15px;
  margin-right: 5px;
  padding-right: 9px;
  border-right: 1px solid #c8c8c8;
  line-height: 19px;
  font-weight: normal;
  color: #222;
}
.info_wrap .message.medium strong img {
  vertical-align: -2px;
}
.info_wrap .message.medium span {
  font-weight: bold;
}
.info_wrap table th {
  text-align: left;
}
.datepicker table th {
  text-align: center;
}
.tooltip_box .data_table th,
.tooltip_box .data_table td {
  margin: 0;
  padding: 3px 0;
}
.tooltip_box .data_table th {
  background: none;
  border: 0;
}
.tooltip_box .data_table td strong {
  padding: 0;
}
.info_wrap .hope_dl {
  padding-bottom: 2px;
}
.info_wrap .multi_tbl tr:first-child td {
  padding-bottom: 10px;
}
.btn.x-small.toggle {
  display: inline-block;
  height: 18px;
  margin: -1px 0 0 0;
  padding: 0 15px 0 5px;
  border: 1px solid #d8d8d8;
  background-color: #fcfcfc;
  background-image: url(#{$imgPath}/common/bg_btn_toggle01.png);
  background-position: 100% -3px;
  background-repeat: no-repeat;
  font-size: 11px;
  color: #666;
  letter-spacing: -0.1em;
  vertical-align: top;
  line-height: 18px;
}
.btn.x-small.toggle.active {
  background-position: 100% -20px;
}
.btn.x-small.ssg_tooltip {
  display: inline-block;
  height: 18px;
  padding: 0 15px 0 5px;
  background-color: #fff;
  background-position: 100% 0;
  border: 1px solid #d8d8d8;
  vertical-align: top;
  line-height: 19px;
  color: #666;
  text-decoration: none;
}
.btn.x-small.ssg_tooltip.active {
  background-position: 100% -21px;
}
.tooltip_box p.btn_wrap .btn {
  margin: 0 2px;
  padding: 0 3px;
  background: 0 none;
  font-weight: normal;
}
.layer_pop .datepicker_panel {
  height: 28px;
}
.layer_wrap .tooltip_box .ptxt {
  font-size: 11px;
  line-height: 16px;
  color: #666;
  letter-spacing: -1px;
}
.info_wrap .button_area {
  position: relative;
  margin: 10px 0 0;
}
.info_wrap .button_area .tooltip_box.small_03 {
  left: -7px;
  top: -98px;
}
.info_wrap .button_area .tooltip_box.small_03.wish {
  right: 0;
  top: -85px;
}
.info_wrap .button_area .burial .tooltip_box.medium {
  position: absolute;
  right: 0;
  top: 57px;
}
.btn.layer_red {
  float: left;
  width: 180px;
  height: 55px;
  margin: 0 4px 10px 0;
  background: #f14f4f;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
}
.btn.layer_black {
  float: left;
  width: 180px;
  height: 55px;
  margin: 0 3px 0 0;
  background: #3b3b3b;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
}
.btn.layer_red span,
.btn.layer_black span {
  padding-top: 16px;
  *padding-top: 13px;
  *line-height: 24px;
}
.button_area .btn_clip {
  float: left;
  display: block;
  width: 85px;
  height: 56px;
  margin: 0 !important;
  background: url(#{$imgPath}/product/btn_clipping_type.png) -200px 0 no-repeat;
}
.button_area .btn_clip.wish {
  background-position: -200px -100px;
}
.btn.layer_warning {
  width: 366px;
  height: 53px;
  margin-top: 10px;
  background-color: #f9f9f9;
  font-weight: bold;
  font-size: 20px;
  color: #797979;
  line-height: 53px;
  border: 1px solid #dadada;
}
.info_wrap .button_area .btn_detail {
  display: block;
  width: 480px;
  height: 27px;
  margin: 10px 0 -10px 0;
  padding-top: 8px !important;
  border: 1px solid #c9c9c9 !important;
  text-align: center;
}
.info_wrap .bn_deli_area {
  margin-top: 7px;
  float: left;
}
.info_wrap .bn_deli_area img {
  vertical-align: top;
}
.option_btn {
  float: left;
}
.btn_select {
  float: right;
  display: block;
  width: 43px;
  height: 22px;
  padding: 4px 0 0;
  border: 1px solid #b2b2b2;
  font-size: 11px;
  color: #666;
  text-decoration: none;
  letter-spacing: -1px;
  text-align: center;
}
.layer_pop .manage_item {
  padding: 30px;
}
.layer_pop .manage_item .tbl_cart th {
  background: #f8f8f8;
}
.layer_pop .manage_item .data_tbl .tbl_cart td {
  padding: 0;
  background: none;
}
.layer_pop .manage_item .data_tbl .tbl_cart td:first-child {
  padding: 15px 0 0 6px;
  vertical-align: top;
}
.layer_pop .manage_item .data_tbl .mi_thumbarea {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 70px;
  margin-right: 10px;
}
.layer_pop .manage_item .data_tbl .mi_soldout,
.layer_pop .manage_item .data_tbl .mi_ready,
.layer_pop .manage_item .data_tbl .mi_limit {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.layer_pop .manage_item .data_tbl .case_soldout .txt {
  color: #888;
}
.layer_pop .manage_item .data_tbl .case_soldout .choice_btnlist {
  display: none;
}
.layer_pop .manage_item .data_tbl .case_soldout .choice_btnlist.sold_out {
  display: block;
}
.layer_pop .manage_item .data_tbl .case_soldout .mi_soldout {
  display: block;
}
.layer_pop .manage_item .data_tbl .case_ready .choice_btnlist {
  display: none;
}
.layer_pop .manage_item .data_tbl .case_ready .choice_btnlist.ready {
  display: block;
}
.layer_pop .manage_item .data_tbl .case_ready .mi_ready {
  display: block;
}
.layer_pop .manage_item .data_tbl .case_limit .choice_btnlist {
  display: none;
}
.layer_pop .manage_item .data_tbl .case_limit .mi_limit {
  display: block;
}
.layer_pop .manage_item .data_tbl .choice_btnlist.sold_out {
  display: none;
}
.layer_pop .manage_item .tbl_cart th {
  height: 18px;
  padding: 11px 0 9px;
  border-bottom: 1px solid #ededed;
  background: #fafafa;
  color: #222;
  line-height: 0;
}
.layer_pop .manage_item .table_itemlist2.tbl_cart .in {
  padding: 20px 0 10px;
}
.layer_pop .manage_item .table_itemlist2.tbl_cart .in .case_article .ca_iteminfo {
  width: 177px;
  margin-left: 10px;
}
.layer_pop .manage_item .table_itemlist2.tbl_cart .choice_btnlist {
  margin-top: 10px;
}
.layer_pop .manage_item .table_itemlist2.tbl_cart .ca_thumbarea {
  margin-left: 10px;
}
.layer_pop .manage_item .table_itemlist2.tbl_cart td {
  height: 130px;
  padding: 20px 0;
  background: url(#{$imgPath}/common/bg_manage_item_pop.gif) 0 20px no-repeat;
}
.layer_pop .manage_item .table_itemlist2.tbl_cart td:first-child {
  padding-left: 0;
  background: none;
}
.layer_pop .manage_item .table_itemlist2.tbl_cart .item_promotion span {
  margin-left: 10px;
}
.layer_pop .manage_item .tbl_cart .in .case_article .ca_thumbarea .btn_big_view {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 22px;
  height: 22px;
  background-position: -80px 0;
  font-size: 0;
  line-height: 0;
}
.layer_pop .manage_item .sp_case {
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: 10px;
  background: url(#{$imgPath}/order/sp_case.png) no-repeat 0 0;
  line-height: 999px;
  vertical-align: top;
}
.layer_pop .info_wrap .column_l .staff_discount {
  text-align: center;
}
.layer_pop .info_wrap .column_l .staff_discount .staff_txt {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  line-height: 20px;
}
.layer_pop .info_wrap .column_l .staff_discount .layer_wrap {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
}
.layer_pop2 {
  position: absolute;
  top: 100px;
  left: 30%;
  z-index: 99999;
  width: 900px;
  background-color: #fff;
  border: 1px solid #eee;
}
.layer_pop2 .content_intro {
  position: relative;
  padding: 33px 0 12px;
  margin: 0 20px;
  border-bottom: 2px solid #000;
}
.layer_pop2 .ssg_num {
  float: left;
  width: 25px;
  height: 25px;
  margin-top: 3px;
  margin-left: 5px;
  background: url(#{$imgPath}/mem/bg_pink_num.png) no-repeat 0 0;
  font-size: 11px;
  color: #fff;
  font-weight: bold;
  line-height: 27px;
  vertical-align: middle;
  text-align: center;
}
.layer_pop2 .content_intro h2 {
  float: left;
  font-family: $fontR;
  font-size: 25px;
  color: #222d36;
  letter-spacing: -1px;
  line-height: 30px;
  font-weight: 400;
}
.layer_pop2 .content_intro .intro_aside {
  overflow: hidden;
}
.layer_pop2 .content_intro .intro_aside .detail_view {
  position: absolute;
  right: 0;
  bottom: 10px;
}
.layer_pop2 .content_intro .intro_aside .intro_advice {
  position: absolute;
  top: 44px;
  right: 88px;
  text-align: right;
  color: #03aecc;
}
.layer_pop2 .content_intro .intro_aside .p_sum {
  position: absolute;
  width: 300px;
  top: 30px;
  right: 88px;
  text-align: right;
  color: #4a565e;
  line-height: 35px;
  font-weight: bold;
}
.layer_pop2 .content_intro .intro_aside .p_sum .ssg_tx {
  font-size: 12px;
  margin-left: 3px;
}
.layer_pop2 .content_intro .intro_aside .p_sum .ssg_price {
  font-family: $fontM;
  font-weight: 500;
  font-size: 29px;
}
.layer_pop2 .myssg_close {
  position: absolute;
  top: -12px;
  right: -12px;
}
.layer_pop2 .section {
  position: relative;
}
.layer_pop2 .section.my_point {
  text-align: center;
}
.layer_pop2 .content_intro .intro_content {
  position: relative;
  border-top: 2px solid #fff;
}
.layer_center {
  left: 50%;
  margin-left: -450px;
}
.layer_pop3 {
  display: none;
  position: relative;
  background-color: #fff;
  border: 1px solid #eee;
}
.layer_pop3 .content_intro {
  position: relative;
  padding: 33px 0 12px;
  margin: 0 20px;
  border-bottom: 2px solid #000;
}
.layer_pop3 .content_intro h2 {
  font-family: $fontR;
  font-size: 25px;
  color: #222d36;
  letter-spacing: -1px;
  line-height: 30px;
  font-weight: 400;
}
.layer_pop3 .myssg_close {
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: transparent;
}
.myclip {
  height: 700px;
  overflow-y: scroll;
}
.myclip ul {
  width: 860px;
  height: 100%;
  margin: 30px auto;
  background: url(#{$imgPath}/common/bg_dot_bar.gif) -12px 0 repeat;
}
.myclip .layer_wrap ul {
  width: auto;
  height: auto;
  margin: 0;
  background: none;
}
.myclip ul li {
  width: 286px;
  margin-bottom: 40px;
  text-align: center;
}
.myclip .layer_wrap ul li {
  width: auto;
  margin: 0;
  text-align: left;
}
.myclip ul li .item_box {
  width: 200px;
  margin: 0 auto;
  font-family: $fontR;
  font-weight: 400;
}
.myclip ul li .masonry_brick {
  display: block;
  width: 253px;
  margin: 0 auto;
  border: 1px solid #d8d8d8;
}
.myclip ul li a.event_txt {
  display: inline-block;
  position: relative;
  width: 253px;
  height: 104px;
  padding-top: 42px;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  background-color: #fafafa;
  border: 1px solid #d8d8d8;
}
.myclip ul li a.event_txt span {
  display: block;
  padding: 15px 10px 0;
  line-height: 18px;
  color: #747474;
}
.myclip ul li .itembx {
  width: 223px;
  margin: 0 auto;
}
.myclip ul li .itembx .period {
  margin: 15px 0 10px 0;
  font-size: 11px;
  color: #999;
}
.myclip ul li .itembx .brand {
  font-weight: bold;
}
.myclip ul li .itembx .layer_wrap {
  line-height: 1.5em;
}
.myclip ul li .itembx .layer_wrap .help_sale_info {
  display: none;
}
.brand_link {
  display: block;
  position: relative;
  text-decoration: none;
}
.brand_box {
  display: block;
  width: 192px;
  height: 117px;
  padding: 2px;
  border: 2px solid #bcbcbc;
}
.box_inner {
  display: block;
  width: 190px;
  height: 115px;
  border: 1px solid #bcbcbc;
  background: #fafafa;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}
.box_inner span {
  display: inline-block;
  vertical-align: middle;
  color: #000;
}
.box_inner span.brand_img {
  position: absolute;
  top: 5px;
  left: 10px;
}
.box_inner span.brand_img img {
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.box_inner .align {
  width: 1px;
  height: 115px;
  vertical-align: middle;
}
.brand_name {
  margin: 7px 0 0 0;
  color: #666;
  text-align: left;
}
.brand_name strong {
  color: #00aac4;
  text-align: left;
}
.brand_item {
  margin-top: 3px;
  color: #666;
  text-align: left;
}
.layer_pop2 .data_tbl {
  padding: 0 30px;
}
.data_tbl {
  margin: 50px 0 20px;
  text-align: center;
  font-size: 12px;
}
.data_tbl .data_table {
  width: 100%;
  border-top: 1px solid #7c7c7c;
}
.data_tbl .data_table td {
  padding: 12px 0 10px;
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  color: #222;
}
.my_benefits .data_tbl .data_table td:first-child {
  border-left: none;
}
.data_tbl .data_table tbody th {
  padding: 0;
  border-bottom: 1px solid #e5e5e5;
  background: none;
  color: #222;
  font-weight: normal;
}
.data_tbl .data_table tbody th a {
  text-decoration: none;
  color: #222;
}
.data_tbl .data_table tbody th a:hover,
.data_tbl .data_table tbody td a:hover {
  text-decoration: underline;
}
.data_tbl .data_table td a {
  text-decoration: none;
  color: #222;
}
.data_tbl .data_table td.al_l a.summary {
  display: block;
  padding-left: 10px;
}
.data_tbl .al_l {
  text-align: left;
}
.data_tbl .al_r {
  text-align: right;
}
.data_tbl .al_c {
  text-align: center;
}
.data_tbl .vr_t {
  vertical-align: top;
}
.data_tbl .txt {
  font-size: 12px;
  color: #222;
}
.data_tbl .txt.small {
  font-size: 11px;
}
.data_tbl .txt.plus,
.data_tbl .txt.plus a {
  color: #39b4b5;
}
.data_tbl .txt.minus,
.data_tbl .txt.minus a {
  color: #e24f4f;
}
.data_tbl .txt.slight,
.data_tbl .txt.slight a {
  color: #777;
}
.data_tbl .txt.slightly,
.data_tbl .txt.slightly a {
  color: #666;
}
.data_tbl .txt.strong {
  font-weight: bold;
}
.data_tbl .txt.normal {
  padding: 0;
  font-weight: normal;
  background: none;
}
.data_tbl .txt.indent {
  text-indent: 10px;
}
.data_tbl .txt.spacing {
  letter-spacing: -1px;
}
.content_myssg .display_store {
  margin-bottom: 5px;
}
.data_tbl .txt.under {
  text-decoration: underline;
}
.data_tbl .txt.sort {
  color: #777;
}
.data_tbl .blue_txt {
  color: #39b4b5;
}
.data_tbl .tc_btn {
  margin-top: 1px;
  line-height: 0;
}
.data_tbl .tc_btn .b_type {
  display: inline-block;
  min-width: 40px;
  padding: 2px 3px 0;
  border: 1px solid #ccc;
  background: #eee;
  font-size: 11px;
  line-height: 12px;
  color: #666;
  letter-spacing: -1px;
  vertical-align: top;
  text-decoration: none;
  box-shadow: inset 0 1px 0 0 #fdfdfd;
}
.data_tbl p.txt.slight.soacing {
  line-height: 16px;
  margin: 1px 0 8px;
}
.data_tbl p.txt.slight.soacing em {
  color: #373737;
}
.ca_iteminfo {
  text-align: left;
}
.ca_thumb img {
  vertical-align: top;
}
.ca_iteminfo {
  text-align: left;
}
.ca_iteminfo .summary {
  line-height: 18px;
  margin-bottom: 10px;
}
.ca_iteminfo .typer {
  margin-top: 3px;
  font-size: 11px;
  line-height: 14px;
  color: #00aac4;
}
.ca_iteminfo .typer.ty2 {
  letter-spacing: -0.05em;
}
.ca_iteminfo .typer .point {
  color: #e34545;
}
.ca_iteminfo .ico_gift {
  width: 12px;
  height: 9px;
  margin: -3px 0 0 4px;
  background-position: -190px -98px;
  vertical-align: middle;
}
.txt_mall {
  display: inline-block;
  margin: 1px 3px -1px;
  padding-top: 1px;
  font-size: 12px;
  line-height: 18px;
  vertical-align: top;
  white-space: nowrap;
}
.ico_map {
  display: inline-block;
  margin: 0 4px -3px 0;
  vertical-align: top;
}
.ico_map img {
  vertical-align: top;
}
.ico_map2 {
  display: inline-block;
  margin: 0 4px -3px 0;
  vertical-align: top;
}
.ico_map2 img {
  vertical-align: top;
}
.layer_pop2 .my_point .content_intro .intro_content {
  position: relative;
  border-top: 2px solid #fff;
}
.layer_pop2 .my_point ul {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 50px 8px 50px;
  background-color: #fff;
}
.layer_pop2 .my_point ul:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.layer_pop2 .my_point ul li {
  float: left;
  width: 220px;
  height: 64px;
  padding-top: 116px;
  text-align: center;
  background-position: 47px 14px;
  background-repeat: no-repeat;
  border-left: 1px dotted #c1c1c1;
  background: url(#{$imgPath}/mem/bg_point.gif) left top no-repeat;
}
.layer_pop2 .my_point ul.switch li {
  background: url(#{$imgPath}/mem/bg_switch.gif) left top no-repeat;
}
.layer_pop2 .my_point ul li:first-child {
  border-left: 0 none;
}
.layer_pop2 .my_point ul li.product {
  background-position: 47px 0px;
}
.layer_pop2 .my_point ul li.event {
  background-position: 47px -200px;
}
.layer_pop2 .my_point ul li.cart {
  background-position: 47px -400px;
}
.layer_pop2 .my_point ul li.extinction {
  background-position: 47px -600px;
}
.layer_pop2 .my_point ul li strong {
  display: block;
  padding-top: 4px;
  font-family: $fontM;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  vertical-align: -2px;
}
.layer_pop2 .my_point ul li strong span.ssg_tx {
  margin-left: 3px;
  font-size: 12px;
  font-family: $fontR;
  font-weight: 400;
  color: #4a565e;
}
.layer_pop2 .my_point ul li p {
  display: block;
  line-height: 12px;
  margin-top: -3px;
  *margin-top: 5px;
  color: #7c7c7c;
}
.layer_pop2 .no_data {
  height: 240px;
  margin-bottom: 30px;
  line-height: 30px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 24px;
  color: #222;
  text-align: center;
  vertical-align: middle;
}
.layer_pop2 .no_data .blank {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
  width: 0;
  height: 240px;
  overflow: hidden;
}
.layer_pop2 .no_data p {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
  text-align: center;
}
.layer_pop2 .no_data p span {
  display: block;
}
.layer_pop2 .no_data .cs_btn.large {
  margin-top: 10px;
}
.layer_pop2 .rs_btn_area {
  padding-top: 15px;
  text-align: center;
}
.recent_viewed {
  padding: 50px 50px 40px 50px;
}
.recent_viewed .itembx .price_unit .applied em {
  font-size: 20px;
}
.recent_viewed .item_info .summary {
  width: 140px;
}
.recent_viewed .itembx {
  padding: 40px;
}
.recent_viewed table tr td:first-child .itembx {
  padding-left: 0;
}
.view_item_list {
  position: relative;
}
.view_item_list .br {
  border: 1px solid white;
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 900px;
  z-index: 10;
}
.view_item_list.num {
  position: relative;
  margin-top: 20px;
}
.view_item_list .title {
  display: block;
  height: 22px;
  border-bottom: 1px solid #d3d3d3;
  font-size: 12px;
}
.view_item_list .title em {
  color: #fc3d72;
}
.view_item_list ul {
  overflow: hidden;
  width: 840px;
  padding: 10px 0;
  background: url(#{$imgPath}/mem/bg_br.gif);
  border-bottom: 1px solid #d3d3d3;
}
.view_item_list ul li {
  position: relative;
  float: left;
  width: 139px;
  padding: 20px 40px 30px;
}
.view_item_list.num ul li {
  padding: 37px 40px 47px;
}
.view_item_list ul li .info_top {
  top: 0;
}
.view_item_list .itembx .summary a {
  height: 34px;
}
.view_item_list .itembx .price_unit {
  height: 35px;
}
.view_item_list .itembx .price_unit .applied em {
  font-size: 20px;
  font-style: normal;
}
.view_item_list .bgline {
  position: absolute;
  bottom: 50px;
  left: 203px;
  width: 1px;
  height: 563px;
  background: url(#{$imgPath}/common/bg_list_01.gif) no-repeat 0 0;
}
.view_item_list .line_02 {
  left: 421px;
}
.view_item_list .line_03 {
  left: 640px;
}
.view_item_list .btn.more {
  display: block;
  height: 40px;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #b2b2b2;
  background: url(#{$imgPath}/common/bg_more.gif) repeat-x 0 0;
  color: #222;
  font-family: $fontR;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
}
.view_item_list .btn.more span {
  padding-right: 12px;
  background: url(#{$imgPath}/common/bg_arrow3.gif) no-repeat 100% 16px;
  cursor: pointer;
}
.view_item_list .btn.more.style2 span {
  background: url(#{$imgPath}/product/bg_arrow.gif) no-repeat 100% 48%;
}
.view_item_list .btn.go {
  position: absolute;
  z-index: 30;
  top: -6px;
  right: 0;
  display: inline-block;
  height: 16px;
  padding: 2px 15px 0 4px;
  border: 1px solid #d8d8d8;
  background: #fcfcfc url(#{$imgPath}/common/bg_btn_arr01.gif) 115px 7px no-repeat;
  line-height: 17px;
  font-size: 11px;
  color: #666;
  letter-spacing: -0.1em;
  text-align: left;
  vertical-align: middle;
  text-align: left;
}
.customer_comment .view_item_list {
  margin-top: 50px;
}
.customer_comment .view_item_list ul {
  min-height: 327px;
  max-height: 654px;
}
.customer_comment .view_item_list ul li {
  padding: 20px 34px 30px 35px;
}
.customer_comment ul li {
  width: 219px;
  border-left: 1px dotted #e9e9e9;
  margin-left: -1px;
  height: auto;
  min-height: 275px;
  padding: 0;
  padding-top: 50px;
}
.customer_comment .itembx {
  position: relative;
  width: 140px;
  margin: 0 auto;
}
.customer_comment .itembx.show_comment .item_info {
  margin-top: 20px;
}
.customer_comment .itembx .item_info {
  position: relative;
}
.customer_comment .itembx .item_info .rate_comment {
  position: absolute;
  left: -10%;
  width: 144px;
  padding: 15px 12px;
  border: 2px solid #c6c6c6;
}
.customer_comment .itembx .thumbnail {
  height: 140px;
}
.customer_comment .itembx .point_bx {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 42px;
  height: 42px;
  background: url(#{$imgPath}/common/bg_point.gif) no-repeat;
  color: #fff;
  font-weight: bold;
  padding: 7px 0 0 3px;
}
.customer_comment .itembx .point_bx em {
  display: inline-block;
  text-decoration: none;
  width: 35px;
  text-align: center;
}
.customer_comment .write_review {
  display: block;
  width: 118px;
  border: 2px solid #c6c6c6;
  padding: 7px 0 7px 10px;
  background: url(#{$imgPath}/common/bg_arrow4.gif) no-repeat 105px center;
  font-family: $fontR;
  font-weight: 400;
  color: #858585;
  line-height: 12px;
}
.customer_comment .rate_comment .star_rating {
  width: auto;
  overflow: hidden;
  float: none;
}
.customer_comment .rate_comment .real_comment {
  clear: both;
}
.customer_comment .rate_comment .arrow_top {
  position: absolute;
  top: -9px;
  left: 50%;
  width: 13px;
  height: 9px;
  background: url(#{$imgPath}/common/bg_arrow_top.gif) no-repeat;
}
.delivery_condition {
  width: 800px;
  margin: 7px auto 50px;
}
.delivery_condition table td {
  border-bottom: 1px solid #e3e3e3;
  padding: 21px 0 18px 26px;
  text-align: left;
}
.delivery_condition table td:first-child {
  padding-left: 0;
  text-align: center;
}
.delivery_condition table td .order_data li {
  color: #a9a9a9;
  font-size: 12px;
}
.delivery_condition table td .order_data li:first-child {
  margin-bottom: 13px;
}
.delivery_condition table td .order_data li span {
  display: inline-block;
  width: 64px;
}
.delivery_condition table td .price {
  color: #a9a9a9;
}
.delivery_condition table td .price strong {
  display: block;
  margin-bottom: 8px;
}
.delivery_condition table td .price em {
  padding-right: 2px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 22px;
  color: #000;
  vertical-align: -1px;
}
.delivery_condition table td.btn_type {
  padding: 0;
  text-align: center;
}
.delivery_condition table td.btn_type span {
  display: block;
  height: 82px;
  margin: 21px 0 18px 0;
  border-left: 1px dotted #c1c1c1;
}
.delivery_condition table td.btn_type span a {
  display: block;
  height: 73px;
  padding-top: 9px;
}
.delivery_condition .button_area {
  margin-top: 20px;
}
