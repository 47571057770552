/* 간편회원통합 & 네이버ID 로그인 */
.simple_login_btn .btn {
  height: 38px;
  padding-left: 41px;
  background-image: url(#{$imgPath}/common/bg_btn_sns.png);
  font-family: $fontR;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  line-height: 38px;
  letter-spacing: -0.5px;
  cursor: pointer;
}
.simple_login_btn .btn span {
  width: 146px;
  background-image: url(#{$imgPath}/common/bg_btn_sns.png);
}
.simple_login_btn .btn.naver {
  background-position: 0 0;
}
.simple_login_btn .btn.naver span {
  background-position: 100% -40px;
}
.simple_login_btn .btn.facebook {
  background-position: 0 -80px;
}
.simple_login_btn .btn.facebook span {
  background-position: 100% -120px;
}
