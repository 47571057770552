.tab {
  *zoom: 1;
}
.tab:after {
  display: block;
  visibility: hidden;
  clear: both;
  width: 0;
  height: 0;
  content: '';
}
.tab li {
  float: left;
  position: relative;
}
.tab li.active {
  z-index: 10;
}
.tab.hover li.lst_over {
  z-index: 10;
}
.tab li a {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
  list-style: none;
}
.tab li a:focus,
.tab li.active a {
  z-index: 20;
}
.tab.x_small li {
  border-left: 1px solid #e0e0e0;
}
.tab.x_small li:first-child {
  border-left: 0;
}
.tab.x_small li a {
  padding-left: 10px;
}
.tab.x_small li:first-child a {
  padding-left: 0;
}
.tab.x_small li a {
  padding-right: 10px;
  color: #888;
  font-size: 11px;
}
.tab.x_small li.active a {
  color: #272727;
  font-size: 11px;
}
.tab.small li {
  padding: 0 10px;
}
.tab.small li a {
  height: 16px;
  line-height: 20px;
  color: #999;
}
.tab.small li a:hover {
  text-decoration: underline;
}
.tab.small li.active a {
  color: #272727;
}
.tab.medium {
  text-align: center;
}
.tab.medium li {
  display: inline-block;
  *display: inline;
  float: none;
}
.tab.medium li a {
  height: 28px;
  padding: 0 2px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #666;
  background-color: #fff;
}
.tab.medium li.active a {
  border-bottom: 3px solid #6bbcb6;
  color: #222;
}
.tab.large {
  position: relative;
  width: 100%;
  height: 41px;
  margin-top: 21px;
  background: url(#{$imgPath}/common/bg_tab.gif) repeat-x 0 100%;
}
.tab.large li {
  height: 38px;
  border: 1px solid #c4c4c4;
  border-bottom: 0;
  margin-left: -2px;
}
.tab.large li.no_data a {
  display: none;
}
.tab.large li:first-child {
  margin-left: 0;
  margin-right: -2px;
  border-right: 1px solid #fff;
}
.tab.large li a,
.tab.large li p {
  padding: 10px 0 9px;
  border: 1px solid #fff;
  border-bottom: 0;
  color: #747474;
  text-align: center;
  border-top: 1px solid transparent;
  height: 18px;
  font-family: $fontR;
  font-weight: 400;
}
.tab.large li p {
  color: #999;
}
.tab.large li p em,
.tab.large li a em {
  color: #222;
  font-size: 11px;
  line-height: 16px;
}
.tab.large li p span,
.tab.large li a span {
  line-height: 1.5em;
}
.tab.large li.active {
  position: relative;
  height: 40px;
  border: 1px solid #777;
  border-bottom: 0;
}
.tab.large.hover li.lst_over {
  position: relative;
  height: 40px;
  border: 1px solid #777;
  border-bottom: 0;
}
.tab.large.hover li.lst_over p {
  overflow: hidden;
  position: relative;
  padding: 9px 0 12px;
  border: 1px solid #777;
  border-bottom: 0;
  background: #fff;
  color: #999;
  font-weight: bold;
}
.tab.large li.active a {
  overflow: hidden;
  position: relative;
  padding: 9px 0 12px;
  border: 1px solid #777;
  border-bottom: 0;
  background: #fff;
  color: #222;
  font-weight: bold;
}
.tab.large.hover li.active a,
.tab.large.hover li a:hover {
  overflow: hidden;
  position: relative;
  padding: 9px 0 12px;
  border: 1px solid #777;
  border-bottom: 0;
  background: #fff;
  color: #222;
  font-weight: bold;
}
.tab.large li.active a em,
.tab.large li a:hover em {
  font-weight: normal;
}
.tab.large.t_dep1 li {
  width: 100%;
}
.tab.large.t_dep2 li {
  width: 50%;
  margin-left: -2px;
  *margin-left: -1px;
}
.tab.large.t_dep2 li:first-child {
  margin-left: 0;
  *margin-left: -1px;
}
.tab.large.t_dep3 li {
  width: 33.33%;
  margin-left: -2px;
}
.tab.large.t_dep3 li:first-child {
  margin-left: 0;
  *margin-left: -1px;
}
.tab.large.t_dep4 li {
  width: 25%;
  margin-left: -2px;
}
.tab.large.t_dep4 li:first-child {
  margin-left: 0;
}
.tab.large.t_dep5 li {
  width: 20%;
  margin-left: -2px;
}
.tab.large.t_dep5 li:first-child {
  margin-left: 0;
}
.tab.large.t_dep6 li {
  width: 16.66%;
  margin-left: -2px;
}
.tab.large.t_dep6 li:first-child {
  margin-left: 0;
  *margin-left: -2px;
}
.tab.large.t_dep7 li {
  width: 14.28%;
  margin-left: -2px;
}
.tab.large.t_dep7 li:first-child {
  margin-left: 0;
}
.tab.large.t_dep8 li {
  width: 12.5%;
  margin-left: -2px;
}
.tab.large.t_dep8 li:first-child {
  margin-left: 0;
}
.tab.large li.active {
  z-index: 50;
}
.tab.large li.active a {
  z-index: 50;
}
.tab.large li a:active,
.tab.large li a:focus {
  background: none;
  border-right: 1px solid transparent;
  border-left: 1px solid #fff;
}
.tab.large li.active a:active,
.tab.large li.active a:focus {
  background: #fff;
  z-index: 20;
  border-right: 1px solid #777;
  border-left: 1px solid #777;
}
.tab.small li {
  margin-left: 8px;
  padding: 0 10px 15px 10px;
}
.tab.small li li {
  padding-left: 14px;
}
.tab.small li a {
  color: #666;
}
.tab.small li a:hover,
.tab.small li.active a {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}
.tab.small li.no_line {
  padding-left: 10px;
  background: none;
}
.md_cat_tab .tab.large li a,
.md_cat_tab .tab.large li p {
  background-color: #f8f8f8;
}
.md_cat_tab .tab.large li.active a {
  background-color: #fff;
}
