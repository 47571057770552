.data_table,
.data_table table {
  width: 100%;
  border-width: 0;
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 0;
}
.data_table th,
.data_table td {
  border-width: 0;
}
.data_table.small {
  border-top: 1px solid #272727;
  background-color: #fff;
}
.data_table.small th {
  height: 32px;
  padding: 9px 0;
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  border-left: 1px solid #dfdfdf;
  background-color: #f9f9f9;
  letter-spacing: -0.05em;
}
.data_table.small td {
  height: 32px;
  padding: 9px 20px;
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  border-left: 1px solid #dfdfdf;
}
.data_table.medium {
  border-top: solid 1px #272727;
  border-right: solid 1px #e9e9e9;
  border-left: solid 1px #e9e9e9;
}
.data_table.medium th {
  height: 16px;
  padding: 7px 0;
  border-bottom: 1px solid #d0d0d0;
  line-height: 16px;
  font-size: 12px;
  color: #333;
}
.data_table.medium tbody th {
  border-bottom: 1px solid #efefef;
}
.data_table.medium td {
  padding: 10px 0;
  border-bottom: 1px solid #efefef;
  line-height: 20px;
  font-size: 1em;
  color: #666;
}
.data_table.large {
  width: 100%;
  border: 0;
  border-top: 1px solid #000;
  border-left: 1px solid #e9e9e9;
}
.data_table.large thead th {
  padding: 12px 20px 10px;
}
.data_table.large tfoot td {
  height: 50px;
  background-color: #fdfdfd;
}
.data_table.large tbody th {
  padding: 12px 20px 10px;
}
.data_table.large th {
  padding: 15px 20px;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  background-color: #f9f9f9;
}
.data_table.large td {
  padding: 15px 20px;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.item_table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
}
.item_table th,
.item_table td {
  border-width: 0;
}
.item_table.large {
  border-top: 1px solid #000;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
}
.item_table.large thead th {
  padding: 11px 0 9px;
  background-color: #f9f9f9;
  line-height: 0.95em;
}
.item_table.large tfoot td {
  height: 50px;
  border-top: 1px solid #e9e9e9;
  background-color: #fdfdfd;
}
.item_table.large tbody td {
  border-top: 1px solid #e9e9e9;
  text-align: center;
}
.fieldset .field {
  zoom: 1;
}
.fieldset .field:after {
  display: block;
  visibility: hidden;
  clear: both;
  width: 0;
  height: 0;
  content: '';
}
.fieldset .field .label {
  float: left;
  display: inline;
  font-size: 12px;
}
.fieldset.small {
  margin-top: -8px;
}
.fieldset.small .field {
  margin-top: 8px;
}
.fieldset.small .field .label {
  width: 80px;
  line-height: 28px;
  color: #222;
}
.fieldset.medium .field {
  padding: 12px 10px;
  vertical-align: middle;
  border-top: 1px solid #e5e5e5;
}
.fieldset.medium .field .label {
  width: 154px;
  line-height: 28px;
  font-weight: bold;
  color: #222;
}
.fieldset.medium .field .insert {
  position: relative;
  margin-left: 154px;
  line-height: 28px;
  vertical-align: middle;
  zoom: 1;
}
.fieldset.medium .field .insert div {
  margin-top: 10px;
}
.fieldset.medium .field .insert div:first-child {
  margin-top: 0;
}
.fieldset.medium .field .insert .label {
  float: none;
  font-weight: normal;
}
.fieldset.medium .field .insert .btn {
  vertical-align: middle;
}
.fieldset.medium .field .insert .last {
  margin-right: 20px;
}
.fieldset.medium .field:after {
  display: block;
  visibility: hidden;
  clear: both;
  width: 0;
  height: 0;
  content: '';
}
.fieldset.medium .field {
  *zoom: 1;
}
.fieldset.medium .field .ins_ch {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0 !important;
}
.fieldset.medium .field .ins_ch .ch_txt {
  display: inline-block;
  position: relative;
  font-size: 11px;
  vertical-align: top;
  zoom: 1;
}
.fieldset.medium .field .ins_ch .ch_txt .bul {
  display: inline-block;
  overflow: hidden;
  position: absolute;
  left: -8px;
  top: 12px;
  width: 2px;
  height: 2px;
  background: #959595;
  line-height: 999px;
  vertical-align: top;
}
.fieldset.medium .field .ins_ch .ch_btn {
  display: inline-block;
  margin-left: 5px;
  vertical-align: top;
}
.fieldset.medium .field .ins_ch .ch_btn .btn {
  vertical-align: top;
}
.fieldset.large .field .label {
  width: 130px;
  line-height: 40px;
  font-weight: bold;
  color: #222;
}
.warning_message.small {
  padding-left: 20px;
  background-image: url(#{$imgPath}/common/ico_exclamation.png);
  background-repeat: no-repeat;
  background-position: left top;
}
.warning_message.medium {
  overflow: hidden;
  margin: 0;
  padding: 10px 0;
  background-color: #f9f9f9;
  line-height: 20px;
  color: #666;
  text-align: center;
}
.warning_message.medium .label {
  display: block;
  visibility: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  line-height: 0;
  font-size: 0;
}
.warning_message.medium .colon {
  display: block;
  visibility: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  line-height: 0;
  font-size: 0;
}
.warning_message.medium .text {
  display: inline-block;
  padding-left: 30px;
  background: url(#{$imgPath}/common/ico_warning.gif) no-repeat 0 0;
}
.item_search {
  display: block;
  position: relative;
  padding-right: 73px;
}
.item_search input {
  display: block;
  width: 100%;
}
.item_search .btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 63px;
}
.option_select.small label {
  margin-left: 15px;
}
.option_select.small label:first-child {
  margin-left: 0;
}
.option_select.medium:after {
  display: block;
  visibility: hidden;
  clear: both;
  width: 0;
  height: 0;
  content: '';
}
.option_select.medium label {
  float: left;
  line-height: 32px;
}
.option_select.medium {
  *zoom: 1;
  overflow: hidden;
}
.option_select.large label {
  display: block;
  line-height: 32px;
}
.direction.medium {
  position: static;
}
.direction.medium .btn {
  position: absolute;
  top: 0;
  width: 18px;
  height: 100%;
  min-height: 18px;
  border-radius: 0;
  background-color: transparent;
  background-image: url(#{$imgPath}/common/btn_direction_medium.gif);
  background-repeat: no-repeat;
}
.direction.medium .btn.prev {
  left: 0;
  background-position: -22px center;
}
.direction.medium .btn.next {
  right: 0;
  background-position: -1px center;
}
.direction.large {
  position: static;
}
.direction.large .btn {
  position: absolute;
  top: 50%;
  width: 31px;
  height: 50px;
  margin-top: -25px;
  border-radius: 0;
  background-color: transparent;
  background-image: url(#{$imgPath}/common/bg_direction_large.png);
  background-repeat: no-repeat;
  z-index: 30;
}
.direction.large .btn.prev {
  left: 0;
  *left: -4px;
  background-position: 0 0;
}
.direction.large .btn.next {
  right: 0;
  background-position: -100px 0;
}
