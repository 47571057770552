.total_price .tbl_data_scroll .table_scroll {
  overflow: hidden;
  overflow-y: scroll;
  height: 226px;
  line-height: 21px;
}
.total_price .tbl_data_scroll .table_scroll tbody th {
  background: #f9f9f9;
  border-top: 1px solid #d1d1d1;
  font-weight: normal;
}
.total_price .tbl_data_scroll .heading td {
  background: #f2f2f2;
  border-bottom: 1px solid #d1d1d1;
}
.total_price .tbl_data_scroll .table_scroll th .ellip {
  display: block;
  padding-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 141px;
}
.total_price .tbl_data_scroll .order_cancel td {
  background: url(#{$imgPath}/common/bg_table_line.gif) repeat-y left top;
}
.total_price .tbl_data_scroll .order_cancel td:first-child {
  background: none;
}
.total_price .tbl_data_scroll .order_cancel tbody tr td:first-child {
  border-left: 0;
}
.total_price .tbl_data_scroll .table_scroll .block {
  display: block;
}
.total_price .tbl_data_scroll .table_scroll .coupon_name {
  text-align: left;
}
.total_price .tbl_data_scroll .table_scroll .coupon_name span {
  display: block;
  padding-left: 10px;
}
.total_price .tbl_data_scroll .table_scroll .discount {
  text-align: right;
}
.total_price .tbl_data_scroll .table_scroll .discount span {
  display: block;
  padding-right: 10px;
}
.total_price .tbl_data_scroll .table_scroll .discount span.ssg_cross,
.total_price .tbl_data_scroll .table_scroll .discount span.ssg_tx {
  display: inline;
  padding: 0;
}
.total_price .tbl_data_scroll .table_scroll .note {
  text-align: left;
  color: #666;
}
.total_price .tbl_data_scroll .table_scroll .note span {
  display: block;
  padding-left: 10px;
}
.tbl_data_scroll .table_scroll table tr th {
  border-bottom: 0;
}
.discount_list .tbl_data_scroll {
  margin-top: 12px;
}
.discount_list .tbl_data_scroll .table_scroll {
  height: 215px;
}
.discount_list .tbl_data_scroll tr.heading td {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
}
#tooltipDiv_totBnfList .discount_list .tbl_data_scroll tr.heading td {
  height: auto;
}
.discount_list .tbl_data_scroll .table_scroll table tr th {
  border-bottom: 0;
}
.discount_list .tbl_data_scroll .table_scroll table tr td {
  border-right: 1px solid #e5e5e5;
  font-size: 12px;
}
.discount_list .tbl_data_scroll .table_scroll table tr td span {
  line-height: 33px;
}
.discount_list .tbl_data_scroll .table_scroll table tr td.align_left.weighty {
  padding-left: 20px;
  padding-right: 20px;
  height: 37px;
  color: #272727;
}
.discount_list .tbl_data_scroll .table_scroll table tr td.weightiest {
  color: #e55756;
}
.discount_list .tbl_data_scroll .table_scroll table tr td:first-child span {
  display: block;
  overflow: hidden;
  width: 275px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.discount_list .tbl_data_scroll .table_scroll table tr td.align_left span {
  padding-left: 20px;
}
.discount_list .tbl_data_scroll .table_scroll table tr td.fs11 {
  font-size: 11px;
}
.discount_list .tbl_data_scroll .table_scroll.table_scroll2 table tr td span {
  display: block;
  line-height: 17px;
}
.discount_list .tbl_data_scroll .table_scroll.table_scroll2 table tr td.align_right span {
  padding-right: 20px;
  color: #666;
}
.discount_list .tbl_data_scroll .table_scroll.table_scroll2 table tr td span.ssg_cross,
.discount_list .tbl_data_scroll .table_scroll.table_scroll2 table tr td span.ssg_tx {
  display: inline;
  padding-right: 0;
}
.discount_list .em.slightly {
  color: #666;
}
.table_scroll .fs11 {
  font-size: 11px;
}
.discount_list .em.slightly.fs11 {
  color: #666;
}
.discount_list .tbl_data_scroll .table_scroll table tr td span.fs12 {
  line-height: 17px;
  color: #a1a1a1;
}
.total_price .tbl_data_scroll .table_scroll .note span {
  display: block;
}
