.dimmed {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 600;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.dimmed02 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 600;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
}
.layer_pop {
  position: absolute;
  z-index: 700;
  width: 600px;
  border: 1px solid #000;
  border-top: 2px solid #000;
  background-color: #fff;
}
.layer_pop h2 {
  height: 53px;
  font-family: $fontR;
  font-size: 22px;
  line-height: 53px;
  color: #222;
  border-bottom: 1px dotted #666;
  text-align: center;
  font-weight: 400;
}
.layer_pop .section {
  padding: 27px 29px 30px;
}
.layer_pop .btn.close {
  position: absolute;
  top: 16px;
  right: 15px;
  width: 29px;
  height: 29px;
  z-index: 3;
}
.layer_pop .btn.close img {
  vertical-align: top;
}
.layer_pop .button_area {
  margin-top: 30px;
  margin-bottom: 40px;
}
.layer_pop .garamond {
  font-size: 20px;
  font-weight: bold;
}
.layer_pop .fl_left {
  float: left;
}
.layer_pop .fl_right {
  float: right;
}
