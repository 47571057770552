/*쿠폰*/
.bcoupon {
  position: relative;
  display: block;
  width: 240px;
  height: 118px;
  background: url(#{$imgPath}/common/coupon_all.png) no-repeat 0 0;
}
.bcoupon .value {
  position: absolute;
  display: block;
  width: 120px;
  height: 118px;
  line-height: 118px;
  font-size: 75px;
  color: #ea718d;
  font-weight: bold;
  font-style: italic;
  right: 100px;
  text-align: center;
}
.bcoupon.s_mall .value {
  color: #fff;
}
.bcoupon.s_mall.won .value,
.bcoupon.s_mall.won0 .value {
  right: 100px;
}
.bcoupon.s_mall.won00 .value {
  right: 103px;
}
.bcoupon.won .value {
  font-size: 50px;
  letter-spacing: -4px;
}
.bcoupon.won0 .value {
  font-size: 42px;
  letter-spacing: -2px;
}
.bcoupon.won00 .value {
  font-size: 40px;
  letter-spacing: -2px;
  right: 110px;
}
.bcoupon.ssg.per,
.bcoupon.ssg.per0 {
  background-position: 0 0;
}
.bcoupon.ssg.won,
.bcoupon.ssg.won0,
.bcoupon.ssg.won00 {
  background-position: -300px 0;
}
.bcoupon.s_mall.per,
.bcoupon.s_mall.per0 {
  background-position: 0 -200px;
}
.bcoupon.s_mall.won,
.bcoupon.s_mall.won0,
.bcoupon.s_mall.won00 {
  background-position: -300px -200px;
}
.bcoupon.emart.per {
  background-position: 0 -400px;
}
.bcoupon.emart.per0 {
  background-position: 0 -600px;
}
.bcoupon.emart.per .value,
.bcoupon.emart.per0 .value {
  font-family: $fontR;
  font-size: 54px;
  text-align: center;
  font-style: normal;
  color: #fff;
  font-weight: normal;
  right: 100px;
  line-height: 130px;
}
.bcoupon.emart.won .value {
  width: 200px;
  right: 40px;
  font-family: $fontR;
  font-size: 45px;
  text-align: center;
  font-style: normal;
  color: #fff;
  font-weight: 400;
  line-height: 125px;
}
.bcoupon.emart.won0 .value,
.bcoupon.emart.won00 .value {
  width: 200px;
  right: 40px;
  font-family: $fontR;
  font-size: 40px;
  text-align: center;
  font-style: normal;
  color: #fff;
  font-weight: 400;
  line-height: 125px;
}
.bcoupon.emart.won,
.bcoupon.emart.won0,
.bcoupon.emart.won00 {
  background-position: -300px -400px;
}
.bcoupon.efree {
  background-position: 0 -800px;
}
.bcoupon.efree.bojung,
.bcoupon.efree.center {
  background-position: -1200px -800px;
}
.bcoupon.efree .value {
  display: none;
}
.bcoupon.ssg_free {
  background-position: -300px -800px;
}
.bcoupon.ssg_free .value {
  display: none;
}
.bcoupon.s_mall_free {
  background-position: -600px -800px;
}
.bcoupon.s_mall_free .value {
  display: none;
}
.bcoupon.emart_free {
  background-position: -900px -800px;
}
.bcoupon.emart_free .value {
  display: none;
}
.bcoupon.together {
  background-position: 0 -2500px;
  width: 248px;
}
.scoupon {
  position: relative;
  display: block;
  width: 108px;
  height: 54px;
  background: url(#{$imgPath}/common/coupon_all.png) no-repeat 0 -1000px;
}
.scoupon .value {
  position: absolute;
  display: block;
  width: 60px;
  height: 54px;
  line-height: 54px;
  font-size: 30px;
  color: #ea718d;
  font-weight: bold;
  font-style: italic;
  right: 44px;
  text-align: center;
}
.scoupon.s_mall .value {
  color: #fff;
}
.scoupon.s_mall.won .value,
.scoupon.s_mall.won0 .value {
  right: 42px;
}
.scoupon.ssg.won00 .value {
  right: 47px;
}
.scoupon.s_mall.won00 .value {
  right: 45px;
}
.scoupon.won .value {
  font-size: 22px;
  letter-spacing: -2px;
}
.scoupon.won0 .value,
.scoupon.won00 .value {
  font-size: 17px;
  letter-spacing: -1px;
}
.scoupon.ssg.per,
.scoupon.ssg.per0 {
  background-position: 0 -1000px;
}
.scoupon.ssg.won,
.scoupon.ssg.won0,
.scoupon.ssg.won00 {
  background-position: -300px -1000px;
}
.scoupon.s_mall.per,
.scoupon.s_mall.per0 {
  background-position: 0 -1100px;
}
.scoupon.s_mall.won,
.scoupon.s_mall.won0,
.scoupon.s_mall.won00 {
  background-position: -300px -1100px;
}
.scoupon.emart.per {
  background-position: 0 -1200px;
}
.scoupon.emart.per0 {
  background-position: 0 -1200px;
}
.scoupon.emart.per .value,
.scoupon.emart.per0 .value {
  font-family: $fontR;
  font-size: 28px;
  text-align: center;
  font-style: normal;
  color: #fff;
  font-weight: 400;
  right: 41px;
  line-height: 61px;
  letter-spacing: -2px;
}
.scoupon.emart.max_per .value {
  font-family: $fontR;
  font-size: 28px;
  text-align: center;
  font-style: normal;
  color: #fff;
  font-weight: 400;
  right: 39px;
  line-height: 61px;
  letter-spacing: -2px;
}
.scoupon.emart.won .value {
  width: 100px;
  right: 13px;
  font-family: $fontR;
  font-size: 20px;
  text-align: center;
  font-style: normal;
  color: #fff;
  font-weight: 400;
  line-height: 57px;
}
.scoupon.emart.won0 .value,
.scoupon.emart.won00 .value {
  width: 100px;
  right: 13px;
  font-family: $fontR;
  font-size: 20px;
  text-align: center;
  font-style: normal;
  color: #fff;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -1px;
}
.scoupon.emart.max_won .value {
  width: 80px;
  right: 13px;
  font-family: $fontR;
  font-size: 20px;
  text-align: right;
  font-style: normal;
  color: #fff;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -1px;
  padding-right: 20px;
}
.scoupon.emart.won,
.scoupon.emart.won0,
.scoupon.emart.won00 {
  background-position: -300px -1200px;
}
.scoupon.emart.max_per {
  background-position: -600px -1200px;
}
.scoupon.emart.max_won {
  background-position: -900px -1200px;
}
.scoupon.efree {
  background-position: 0 -1400px;
}
.scoupon.efree.bojung,
.scoupon.efree.center {
  background-position: -600px -1400px;
}
.scoupon.efree .value {
  display: none;
}
.scoupon.ssg_free {
  background-position: -150px -1400px;
}
.scoupon.ssg_free .value {
  display: none;
}
.scoupon.s_mall_free {
  background-position: -300px -1400px;
}
.scoupon.s_mall_free .value {
  display: none;
}
.scoupon.emart_free {
  background-position: -450px -1400px;
}
.scoupon.emart_free .value {
  display: none;
}
.scoupon.together {
  background-position: -600px -2500px;
  width: 116px;
}
.scoupon .gift_coupon {
  width: 47px;
  height: 40px;
  position: absolute;
  top: -23px;
  right: -10px;
  background: url(#{$imgPath}/common/coupon_all.png) -600px 0 no-repeat;
  overflow: hidden;
  text-indent: -1000em;
}
.mcoupon {
  position: relative;
  display: block;
  width: 216px;
  height: 107px;
  background: url(#{$imgPath}/common/coupon_all.png) no-repeat 0 -1500px;
}
.mcoupon .value {
  position: absolute;
  display: block;
  width: 108px;
  height: 107px;
  line-height: 107px;
  font-size: 67px;
  color: #ea718d;
  font-weight: bold;
  font-style: italic;
  right: 90px;
  text-align: center;
}
.mcoupon.s_mall .value {
  color: #fff;
}
.mcoupon.s_mall.won .value,
.mcoupon.s_mall.won0 .value {
  right: 88px;
}
.mcoupon.ssg.won00 .value {
  right: 96px;
}
.mcoupon.s_mall.won00 .value {
  right: 91px;
}
.mcoupon.won .value {
  font-size: 45px;
  letter-spacing: -2px;
}
.mcoupon.won0 .value,
.mcoupon.won00 .value {
  font-size: 34px;
  letter-spacing: -1px;
}
.mcoupon.ssg.per,
.mcoupon.ssg.per0 {
  background-position: 0 -1500px;
}
.mcoupon.ssg.won,
.mcoupon.ssg.won0,
.mcoupon.ssg.won00 {
  background-position: -300px -1500px;
}
.mcoupon.s_mall.per,
.mcoupon.s_mall.per0 {
  background-position: 0 -1700px;
}
.mcoupon.s_mall.won,
.mcoupon.s_mall.won0,
.mcoupon.s_mall.won00 {
  background-position: -300px -1700px;
}
.mcoupon.emart.per {
  background-position: 0 -2100px;
}
.mcoupon.emart.per0 {
  background-position: 0 -1900px;
}
.mcoupon.emart.per .value,
.mcoupon.emart.per0 .value {
  font-family: $fontR;
  font-size: 48px;
  text-align: center;
  font-style: normal;
  color: #fff;
  font-weight: normal;
  right: 89px;
  line-height: 119px;
}
.mcoupon.emart.won .value {
  width: 200px;
  right: 25px;
  font-family: $fontR;
  font-size: 40px;
  text-align: center;
  font-style: normal;
  color: #fff;
  font-weight: 400;
  line-height: 115px;
}
.mcoupon.emart.won0 .value,
.mcoupon.emart.won00 .value {
  width: 200px;
  right: 24px;
  font-family: $fontR;
  font-size: 36px;
  text-align: center;
  font-style: normal;
  color: #fff;
  font-weight: 400;
  line-height: 115px;
}
.mcoupon.emart.won,
.mcoupon.emart.won0,
.mcoupon.emart.won00 {
  background-position: -300px -1900px;
}
.mcoupon.efree {
  background-position: 0 -2300px;
}
.mcoupon.efree.bojung,
.mcoupon.efree.center {
  background-position: -1200px -2300px;
}
.mcoupon.efree .value {
  display: none;
}
.mcoupon.ssg_free {
  background-position: -300px -2300px;
}
.mcoupon.ssg_free .value {
  display: none;
}
.mcoupon.s_mall_free {
  background-position: -600px -2300px;
}
.mcoupon.s_mall_free .value {
  display: none;
}
.mcoupon.emart_free {
  background-position: -900px -2300px;
}
.mcoupon.emart_free .value {
  display: none;
}
.mcoupon.together {
  background-position: -300px -2500px;
}
.ssg-modal-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background-color: #fff;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.guide_stl1 {
  margin-top: 30px;
}
.layer_pop .event_layer .advice {
  margin-top: 23px;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #ff0000;
}
.layer_pop .event_layer .advice strong {
  color: #222;
}
.layer_pop .event_layer .advice2 {
  font-size: 12px;
  text-align: center;
  color: #222;
}

/* 쿠폰 텍스트화 및 굿모닝 쓱배송 추가 (v1제거 시 이마트 쿠폰 value에 적용한 폰트 속성 삭제)*/
.bcoupon .value .tx {
  display: none;
}
.mcoupon .value .tx {
  display: none;
}
.scoupon .value .tx {
  display: none;
}
.bcoupon.v2 {
  position: relative;
  display: block;
  width: 240px;
  height: 118px;
  background: url(#{$imgPath}/common/coupon_all_v2.png) no-repeat 0 0;
}
.bcoupon.v2 .value {
  position: absolute;
  display: block;
  overflow: hidden;
  width: 140px;
  height: 118px;
  line-height: 113px;
  font-size: 66px;
  color: #ea718d;
  right: 74px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}
.bcoupon.v2 .value .tx {
  display: inline-block;
  margin-left: 1px;
  font-size: 24px;
  font-weight: normal;
}
.bcoupon.v2.ssg .value {
  line-height: 110px;
}
.bcoupon.v2.s_mall .value,
.bcoupon.v2.emart .value {
  color: #fff;
}
.bcoupon.v2.won .value {
  font-size: 48px;
  letter-spacing: -2px;
}
.bcoupon.v2.won0 .value {
  font-size: 42px;
  letter-spacing: -2px;
}
.bcoupon.v2.won00 .value {
  font-size: 36px;
  letter-spacing: -2px;
}
.bcoupon.v2.won .value .tx,
.bcoupon.v2.won0 .value .tx,
.bcoupon.v2.won00 .value .tx {
  font-size: 20px;
  vertical-align: 1px;
}
.bcoupon.v2.ssg.per,
.bcoupon.v2.ssg.per0 {
  background-position: 0 0;
}
.bcoupon.v2.ssg.per .value,
.bcoupon.v2.ssg.per0 .value {
  line-height: 107px;
}
.bcoupon.v2.ssg.per .value .tx,
.bcoupon.v2.ssg.per0 .value .tx {
  vertical-align: 4px;
}
.bcoupon.v2.ssg.won,
.bcoupon.v2.ssg.won0,
.bcoupon.v2.ssg.won00 {
  background-position: -300px 0;
}
.scoupon.v2.ssg.won00 .value,
.bcoupon.v2.s_mall.won .value,
.bcoupon.v2.s_mall.won0 .value,
.bcoupon.v2.s_mall.won00 .value {
  right: 74px;
}
.bcoupon.v2.ssg.ship {
  background-position: -900px 0;
}
.bcoupon.v2.s_mall.per,
.bcoupon.v2.s_mall.per0 {
  background-position: 0 -200px;
}
.bcoupon.v2.s_mall.won,
.bcoupon.v2.s_mall.won0,
.bcoupon.v2.s_mall.won00 {
  background-position: -300px -200px;
}
.bcoupon.v2.s_mall.ship {
  background-position: -900px -200px;
}
.bcoupon.v2.emart.per {
  background-position: 0 -400px;
}
.bcoupon.v2.emart.per0 {
  background-position: 0 -600px;
}
.bcoupon.v2.emart.per .value,
.bcoupon.v2.emart.per0 .value {
  width: 205px;
  right: 35px;
  font-size: 54px;
  line-height: 130px;
  font-weight: bold;
}
.bcoupon.v2.emart.won .value {
  width: 205px;
  right: 35px;
  font-size: 45px;
  line-height: 125px;
  font-weight: bold;
}
.bcoupon.v2.emart.won0 .value,
.bcoupon.v2.emart.won00 .value {
  width: 205px;
  right: 35px;
  font-size: 40px;
  line-height: 125px;
  font-weight: bold;
}
.bcoupon.v2.emart.won,
.bcoupon.v2.emart.won0,
.bcoupon.v2.emart.won00 {
  background-position: -300px -400px;
}
.bcoupon.v2.emart.ship {
  background-position: -900px -400px;
}
.bcoupon.v2.efree {
  background-position: 0 -800px;
}
.bcoupon.v2.efree.bojung,
.bcoupon.v2.efree.center {
  background-position: -1200px -800px;
}
.bcoupon.v2.efree .value {
  display: none;
}
.bcoupon.v2.ssg_free {
  background-position: -300px -800px;
}
.bcoupon.v2.ssg_free .value {
  font-size: 36px;
  line-height: 118px;
}
.bcoupon.v2.s_mall_free {
  background-position: -600px -800px;
}
.bcoupon.v2.s_mall_free .value {
  color: #fff;
  font-size: 36px;
  line-height: 118px;
}
.bcoupon.v2.emart_free {
  background-position: -900px -800px;
}
.bcoupon.v2.emart_free .value {
  display: none;
}
.bcoupon.v2.emorning_free {
  height: 148px;
  background-position: -1500px -800px;
}
.bcoupon.v2.emorning_free .value {
  display: none;
}
.bcoupon.v2.ship .value {
  right: 70px;
  font-size: 48px;
  line-height: 135px;
  letter-spacing: -1px;
}
.bcoupon.v2.ship .value .tx {
  font-size: 20px;
  vertical-align: 2px;
}
.bcoupon.v2.estore_fee {
  background-position: 0 -2970px;
}
.bcoupon.v2.emorning_fee {
  height: 148px;
  background-position: 0 -2710px;
}
.bcoupon.v2.emorning_fee .value,
.bcoupon.v2.estore_fee .value {
  right: 54px;
  bottom: 17px;
  height: auto;
  color: #4d2107;
  font-size: 22px;
  line-height: 1.5;
}
.bcoupon.v2.emorning_fee .value .tx,
.bcoupon.v2.estore_fee .value .tx {
  font-size: 17px;
  vertical-align: 1px;
}
.bcoupon.v2.together {
  background-position: 0 -2500px;
  width: 248px;
}
.scoupon.v2 {
  position: relative;
  display: block;
  width: 110px;
  height: 54px;
  background: url(#{$imgPath}/common/coupon_all_v2.png) no-repeat 0 -1000px;
}
.scoupon.v2 .value {
  overflow: hidden;
  position: absolute;
  display: block;
  width: 68px;
  height: 54px;
  line-height: 50px;
  font-size: 28px;
  color: #ea718d;
  right: 32px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}
.scoupon.v2 .value .tx {
  display: inline-block;
  margin-left: 1px;
  font-size: 11px;
  vertical-align: 2px;
  font-weight: normal;
}
.scoupon.v2.ssg .value {
  line-height: 50px;
}
.scoupon.v2.ssg .value .tx {
  vertical-align: 1px;
}
.scoupon.v2.ssg.won00 .value {
  right: 32px;
}
.scoupon.v2.s_mall .value {
  color: #fff;
}
.scoupon.v2.emart .value {
  width: 93px;
  right: 15px;
  color: #fff;
}
.scoupon.v2.won .value {
  font-size: 22px;
  letter-spacing: -1px;
  font-weight: bold;
}
.scoupon.v2.won0 .value {
  font-size: 18px;
  letter-spacing: -1px;
  font-weight: bold;
}
.scoupon.v2.won00 .value {
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: bold;
}
.scoupon.v2.ssg.per,
.scoupon.v2.ssg.per0 {
  background-position: 0 -1000px;
}
.scoupon.v2.ssg.per .value .tx,
.scoupon.v2.ssg.per0 .value .tx {
  vertical-align: 2px;
}
.scoupon.v2.ssg.won,
.scoupon.v2.ssg.won0,
.scoupon.v2.ssg.won00 {
  background-position: -300px -1000px;
}
.scoupon.v2.s_mall.won .value,
.scoupon.v2.s_mall.won0 .value,
.scoupon.v2.s_mall.won00 .value {
  right: 32px;
}
.scoupon.v2.ssg.ship {
  background-position: -1200px -1000px;
}
.scoupon.v2.s_mall.per,
.scoupon.v2.s_mall.per0 {
  background-position: 0 -1100px;
}
.scoupon.v2.s_mall.won,
.scoupon.v2.s_mall.won0,
.scoupon.v2.s_mall.won00 {
  background-position: -300px -1100px;
}
.scoupon.v2.s_mall.ship {
  background-position: -1200px -1100px;
}
.scoupon.v2.emart.per {
  background-position: 0 -1200px;
}
.scoupon.v2.emart.per0 {
  background-position: 0 -1200px;
}
.scoupon.v2.emart.per .value,
.scoupon.v2.emart.per0 .value {
  font-size: 28px;
  line-height: 61px;
  letter-spacing: -1px;
  font-weight: bold;
}
.scoupon.v2.emart.max_per .value {
  width: 60px;
  font-size: 22px;
  left: 37px;
  right: auto;
  font-weight: bold;
  text-align: left;
  line-height: 61px;
  letter-spacing: -1px;
}
.scoupon.v2.emart.won .value {
  width: 100px;
  right: 13px;
  font-style: normal;
  color: #fff;
  font-family: $fontR;
  font-weight: 400;
  line-height: 57px;
}
.scoupon.v2.emart.won0 .value,
.scoupon.v2.emart.won00 .value {
  width: 100px;
  right: 13px;
  line-height: 57px;
  letter-spacing: -1px;
}
.scoupon.v2.emart.max_won .value {
  width: 80px;
  left: 24px;
  text-align: left;
  line-height: 57px;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -1px;
}
.scoupon.v2.emart.max_won .value .tx {
  position: absolute;
  bottom: -6px;
}
.scoupon.v2.emart.won,
.scoupon.v2.emart.won0,
.scoupon.v2.emart.won00 {
  background-position: -300px -1200px;
}
.scoupon.v2.emart.max_per {
  background-position: -600px -1200px;
}
.scoupon.v2.emart.max_won {
  background-position: -900px -1200px;
}
.scoupon.v2.emart.ship {
  background-position: -1200px -1200px;
}
.scoupon.v2.efree {
  background-position: 0 -1400px;
}
.scoupon.v2.efree.bojung,
.scoupon.v2.efree.center {
  background-position: -600px -1400px;
}
.scoupon.v2.efree .value {
  display: none;
}
.scoupon.v2.ssg_free {
  background-position: -150px -1400px;
}
.scoupon.v2.ssg_free .value {
  font-size: 16px;
}
.scoupon.v2.s_mall_free {
  background-position: -300px -1400px;
}
.scoupon.v2.s_mall_free .value {
  font-size: 16px;
  color: #fff;
}
.scoupon.v2.emart_free {
  background-position: -450px -1400px;
}
.scoupon.v2.emart_free .value {
  display: none;
}
.scoupon.v2.emorning_free {
  height: 66px;
  background-position: -750px -1400px;
}
.scoupon.v2.emorning_free .value {
  display: none;
}
.scoupon.v2.ship .value {
  font-size: 22px;
  line-height: 62px;
  letter-spacing: -1px;
}
.scoupon.v2.ship .value .tx {
  font-size: 11px;
  vertical-align: 3px;
}
.scoupon.v2.estore_fee {
  background-position: -600px -2970px;
}
.scoupon.v2.emorning_fee {
  height: 66px;
  background-position: -600px -2710px;
}
.scoupon.v2.emorning_fee .value,
.scoupon.v2.estore_fee .value {
  right: 24px;
  bottom: 6px;
  height: auto;
  color: #4d2107;
  font-size: 12px;
  line-height: 1.5;
}
.scoupon.v2.emorning_fee .value .tx,
.scoupon.v2.estore_fee .value .tx {
  font-size: 10px;
  vertical-align: 1px;
}
.scoupon.v2.together {
  background-position: -600px -2500px;
  width: 116px;
}
.scoupon.v2 .gift_coupon {
  width: 47px;
  height: 40px;
  position: absolute;
  top: -23px;
  right: -10px;
  background: url(#{$imgPath}/common/coupon_all_v2.png) -600px 0 no-repeat;
  overflow: hidden;
  text-indent: -1000em;
}
.mcoupon.v2 {
  position: relative;
  display: block;
  width: 217px;
  height: 107px;
  background: url(#{$imgPath}/common/coupon_all_v2.png) no-repeat 0 -1500px;
}
.mcoupon.v2 .value {
  position: absolute;
  display: block;
  overflow: hidden;
  width: 125px;
  height: 107px;
  line-height: 107px;
  font-size: 60px;
  color: #ea718d;
  right: 67px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}
.mcoupon.v2 .value .tx {
  display: inline-block;
  margin-left: 1px;
  font-size: 24px;
  font-weight: normal;
}
.mcoupon.v2.ssg .value {
  line-height: 100px;
}
.mcoupon.v2.ssg .value .tx {
  vertical-align: 1px;
}
.mcoupon.v2.s_mall .value,
.mcoupon.v2.emart .value {
  color: #fff;
}
.mcoupon.v2.won .value {
  font-size: 42px;
  letter-spacing: -1px;
}
.mcoupon.v2.won0 .value {
  font-size: 34px;
  letter-spacing: -1px;
}
.mcoupon.v2.won00 .value {
  right: 67px;
  font-size: 30px;
  letter-spacing: -1px;
}
.mcoupon.v2.won .value .tx,
.mcoupon.v2.won0 .value .tx,
.mcoupon.v2.won00 .value .tx {
  font-size: 20px;
  font-weight: normal;
}
.mcoupon.v2.ssg.per,
.mcoupon.v2.ssg.per0 {
  background-position: 0 -1500px;
}
.mcoupon.v2.ssg.per .value,
.mcoupon.v2.ssg.per0 .value {
  line-height: 98px;
}
.mcoupon.v2.ssg.per .value .tx,
.mcoupon.v2.ssg.per0 .value .tx {
  vertical-align: 3px;
}
.mcoupon.v2.ssg.won,
.mcoupon.v2.ssg.won0,
.mcoupon.v2.ssg.won00 {
  background-position: -300px -1500px;
}
.mcoupon.v2.s_mall.won .value,
.mcoupon.v2.s_mall.won0 .value,
.mcoupon.v2.s_mall.won00 .value {
  right: 67px;
}
.mcoupon.v2.ssg.ship {
  background-position: -680px -1500px;
}
.mcoupon.v2.s_mall.per,
.mcoupon.v2.s_mall.per0 {
  background-position: 0 -1700px;
}
.mcoupon.v2.s_mall.won,
.mcoupon.v2.s_mall.won0,
.mcoupon.v2.s_mall.won00 {
  background-position: -300px -1700px;
}
.mcoupon.v2.s_mall.ship {
  background-position: -680px -1700px;
}
.mcoupon.v2.emart.per {
  background-position: 0 -2100px;
}
.mcoupon.v2.emart.per0 {
  background-position: 0 -1900px;
}
.mcoupon.v2.emart.per .value,
.mcoupon.v2.emart.per0 .value {
  width: 184px;
  right: 31px;
  font-size: 48px;
  line-height: 125px;
  font-weight: bold;
}
.mcoupon.v2.emart.won .value {
  width: 184px;
  right: 31px;
  font-size: 40px;
  line-height: 120px;
  font-weight: bold;
}
.mcoupon.v2.emart.won0 .value,
.mcoupon.v2.emart.won00 .value {
  width: 184px;
  right: 31px;
  font-size: 36px;
  line-height: 120px;
  font-weight: bold;
}
.mcoupon.v2.emart.won,
.mcoupon.v2.emart.won0,
.mcoupon.v2.emart.won00 {
  background-position: -300px -1900px;
}
.mcoupon.v2.emart.ship {
  background-position: -680px -1900px;
}
.mcoupon.v2.efree {
  background-position: 0 -2300px;
}
.mcoupon.v2.efree.bojung,
.mcoupon.v2.efree.center {
  background-position: -1200px -2300px;
}
.mcoupon.v2.efree .value {
  display: none;
}
.mcoupon.v2.ssg_free {
  background-position: -300px -2300px;
}
.mcoupon.v2.ssg_free .value {
  font-size: 32px;
  line-height: 107px;
}
.mcoupon.v2.s_mall_free {
  background-position: -600px -2300px;
}
.mcoupon.v2.s_mall_free .value {
  color: #fff;
  font-size: 32px;
  line-height: 107px;
}
.mcoupon.v2.emart_free {
  background-position: -900px -2300px;
}
.mcoupon.v2.emart_free .value {
  display: none;
}
.mcoupon.v2.emorning_free {
  height: 133px;
  background-position: -1500px -2300px;
}
.mcoupon.v2.emorning_free .value {
  display: none;
}
.mcoupon.v2.ship .value {
  right: 64px;
  font-size: 42px;
  line-height: 125px;
  letter-spacing: -1px;
}
.mcoupon.v2.ship .value .tx {
  font-size: 20px;
  vertical-align: 3px;
  font-weight: normal;
}
.mcoupon.v2.estore_fee {
  background-position: -300px -2970px;
}
.mcoupon.v2.emorning_fee {
  height: 133px;
  background-position: -300px -2710px;
}
.mcoupon.v2.emorning_fee .value,
.mcoupon.v2.estore_fee .value {
  right: 54px;
  bottom: 12px;
  height: auto;
  color: #4d2107;
  font-size: 22px;
  line-height: 1.5;
}
.mcoupon.v2.emorning_fee .value .tx,
.mcoupon.v2.estore_fee .value .tx {
  font-size: 17px;
  vertical-align: 1px;
}
.mcoupon.v2.together {
  background-position: -300px -2500px;
}

/*상품상세 추가*/
.option_dl2 .op_chk .op_in {
  padding-left: 16px;
}
.option_dl2 .op_chk .op_chk_txt {
  font-size: 12px;
  color: #13a4c6;
}
.option_dl2 .op_chk .op_chk_advice {
  font-size: 11px;
  color: #777;
  font-family: $fontR;
  font-weight: 400;
}

/*IE7 아이템리스트 반짝임 대응*/
#content.best_section,
#content.search_section,
#content.category_sec,
#content.category_section,
#content.view_event,
#content.display_main,
#content.image_temp,
#content.content_exhibition {
  width: 1020px;
}
#content.content_myssg,
#content.content_csc {
  width: 790px;
}

/*IE7 탭분기*/
#shppcst_info .t_dep6 li {
  width: 128px;
}
#shppcst_info .t_dep6 li:first-child {
  width: 132px;
}

/* 상품상세레이어 */
.ssg-productinfo-layer {
  position: absolute;
  text-align: center;
  z-index: 100001;
  width: 1020px;
  left: 50% !important;
  margin-left: -510px !important;
  *margin-left: -1020px !important;
}
.pr_gpwrap {
  clear: both;
  padding-top: 7px;
}
.pr_gpwrap .gp_btn {
  display: inline-block;
  position: relative;
  vertical-align: top;
  *display: inline;
  zoom: 1;
}
.pr_gpwrap .gp_btn img {
  vertical-align: top;
}
.layer_pop .pr_gpwrap .gp_btn a {
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 15px;
  left: 0;
  bottom: 0;
  margin: 0;
  background: url(../img/common/b.gif);
  line-height: 999px;
  margin: 0;
}
