.category {
  display: none;
  position: relative;
  z-index: 20;
  width: 1020px;
  margin: 0 auto;
}
#header_sm .lnb .category {
  display: block;
}
.category_nav {
  border-bottom: 1px solid #707070;
  background-color: #fff;
  z-index: 10;
}
.category_nav {
  clear: both;
  height: 61px;
}
.category_nav h2 {
  display: block;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  font-size: 1px;
  line-height: 0;
  text-indent: -999em;
  white-space: nowrap;
}
.category_nav ul {
  float: left;
  margin: 15px 10px;
  text-align: center;
}
.category_nav ul:after {
  display: block;
  visibility: hidden;
  clear: both;
  width: 0;
  height: 0;
  content: '';
}
.category_nav ul li {
  float: left;
  padding: 0 13px;
  height: 30px;
}
.category_nav ul li a {
  display: block;
  position: relative;
  height: 30px;
  line-height: 30px;
  color: #333;
  font-family: $fontR;
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
  letter-spacing: -0.05em;
  text-decoration: none;
}
.category_nav ul li.fashion a {
  width: 71px;
}
.category_nav ul li.beauty a {
  width: 66px;
}
.category_nav ul li.living a {
  width: 55px;
}
.category_nav ul li.baby a {
  width: 92px;
}
.category_nav ul li.food a {
  width: 45px;
}
.category_nav ul li.digital a {
  width: 65px;
}
.category_nav ul li.culture a {
  width: 75px;
}
.category_nav ul li a:hover {
  text-decoration: underline;
}
.category_nav ul li a:focus,
.category_nav ul li.active a {
  z-index: 10;
}
.category_nav ul li a .ir {
  background-image: url(#{$imgPath}/layout/category_nav.gif);
  background-repeat: no-repeat;
}
.category_nav ul li.fashion a .ir {
  background-position: 0 0;
}
.category_nav ul li.beauty a .ir {
  background-position: 0 -40px;
}
.category_nav ul li.living a .ir {
  background-position: 0 -80px;
}
.category_nav ul li.baby a .ir {
  background-position: 0 -120px;
}
.category_nav ul li.food a .ir {
  background-position: 0 -160px;
}
.category_nav ul li.digital a .ir {
  background-position: 0 -200px;
}
.category_nav ul li.culture a .ir {
  background-position: 0 -240px;
}
.category_nav ul li.fashion a:hover .ir,
.category_nav ul li.fashion a:focus .ir,
.category_nav ul li.fashion.active a .ir {
  background-position: -150px 0;
}
.category_nav ul li.beauty a:hover .ir,
.category_nav ul li.beauty a:focus .ir,
.category_nav ul li.beauty.active a .ir {
  background-position: -150px -40px;
}
.category_nav ul li.living a:hover .ir,
.category_nav ul li.living a:focus .ir,
.category_nav ul li.living.active a .ir {
  background-position: -150px -80px;
}
.category_nav ul li.baby a:hover .ir,
.category_nav ul li.baby a:focus .ir,
.category_nav ul li.baby.active a .ir {
  background-position: -150px -120px;
}
.category_nav ul li.food a:hover .ir,
.category_nav ul li.food a:focus .ir,
.category_nav ul li.food.active a .ir {
  background-position: -150px -160px;
}
.category_nav ul li.digital a:hover .ir,
.category_nav ul li.digital a:focus .ir,
.category_nav ul li.digital.active a .ir {
  background-position: -150px -200px;
}
.category_nav ul li.culture a:hover .ir,
.category_nav ul li.culture a:focus .ir,
.category_nav ul li.culture.active a .ir {
  background-position: -150px -240px;
}
.category .promotion {
  display: block;
}
.category.active .promotion {
  display: none;
}
