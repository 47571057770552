.pagination.small strong {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url(#{$imgPath}/common/bg_pagination_small.png) 0 -20px no-repeat;
}
.pagination.small span,
.pagination.small a {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url(#{$imgPath}/common/bg_pagination_small.png) no-repeat;
  cursor: pointer;
}
.pagination.medium {
  position: relative;
  margin: 0 auto;
  line-height: 23px;
  font-size: 12px;
  font-family: $fontR;
  font-weight: 400;
  text-align: center;
}
.pagination.medium a {
  display: inline-block;
  padding: 0 0.6em;
  border: 1px solid #e9e9e9;
  font-weight: bold;
  color: #666;
  text-decoration: none;
  vertical-align: top;
}
.pagination.medium strong {
  display: inline-block;
  padding: 0 0.6em;
  border: 1px solid #e9e9e9;
  color: #d2303b;
  vertical-align: top;
}
.pagination.medium .btn {
  overflow: hidden;
  position: relative;
  width: 25px;
  padding: 0;
}
.pagination.medium .btn {
  *width: 23px;
}
.pagination.medium .btn .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(#{$imgPath}/common/bg_pagination_medium.gif) no-repeat;
}
.pagination.medium .btn.prev .after {
  background-position: 0 0;
}
.pagination.medium .btn.next .after {
  background-position: 0 -23px;
}
.pagination.medium .btn.first .after {
  background-position: 0 -46px;
}
.pagination.medium .btn.last .after {
  background-position: 0 -69px;
}

/* 몰공통 paginate */
/*.paginate{position:relative;margin:10px auto;line-height:23px;font-size:12px;font-family:'돋움',Dotum,'굴림',Gulim,Tahoma,Verdana,Geneva,Arial,Helvetica,sans-serif;text-align:center}
.paginate.mgt20{margin-top:10px !important}
.paginate a{display:inline-block;padding:0 .6em;border:1px solid #e9e9e9;font-weight:bold;color:#666;text-decoration:none;vertical-align:top}
.paginate strong{display:inline-block;padding:0 .6em;border:1px solid #e9e9e9;color:#fff;background-color:#8a8a8a;vertical-align:top}
.paginate .btn_first,.paginate .btn_prev,.paginate .btn_next,.paginate .btn_last{overflow:hidden;position:relative;width:25px;height:23px;padding:0}
.paginate .btn_prev,.paginate .btn_next,.paginate .btn_first,.paginate .btn_last{width:23px;height:23px;background:url(#{$imgPath}/common/bg_pagination_medium.gif) no-repeat}
.paginate .btn_prev{background-position:0 0}
.paginate .btn_next{background-position:0 -23px}
.paginate .btn_first{background-position:0 -46px}
.paginate .btn_last{background-position:0 -69px}
.paginate .gap{display:inline-block;padding:0 .3em;font-weight:bold;line-height:25px;vertical-align:top}*/

.paginate {
  text-align: center;
  margin: 10px auto;
}
.paginate a,
.paginate span.gap,
.paginate strong {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0 8px 0 7px;
  font-size: 12px;
  font-family: $fontR;
  font-weight: 400;
  color: #333;
  line-height: normal;
  text-decoration: none;
  vertical-align: middle;
  color: #666;
  border: 1px solid #e5e5e5;
  line-height: 21px;
  height: 21px;
  overflow: hidden;
  text-align: center;
}
.paginate a:hover,
.paginate a:active,
.paginate a:focus {
  background-color: #f4f4f4;
}
.paginate strong {
  color: #ffffff;
  background: #747474;
  border-color: #747474;
}
.paginate a.btn_first,
.paginate a.btn_prev,
.paginate a.btn_next,
.paginate a.btn_last {
  padding: 0;
  background-image: url(#{$imgPath}/common_layout/sp_paginate.gif);
  background-repeat: no-repeat;
  background-color: #f4f4f4;
}
.paginate a.btn_first {
  background-position: 0 0;
  width: 51px;
}
.paginate a.btn_prev {
  background-position: 0 -25px;
  width: 49px;
}
.paginate a.btn_next {
  background-position: 0 -50px;
  width: 49px;
}
.paginate a.btn_last {
  background-position: 0 -75px;
  width: 61px;
}

/* paginate - 이전, 다음 텍스트 X */
.com_paginate {
  margin: 30px auto 10px;
  text-align: center;
}
.com_paginate a,
.com_paginate span.gap,
.com_paginate strong {
  display: inline-block;
  overflow: hidden;
  position: relative;
  min-width: 7px;
  height: 28px;
  margin: 0 1px;
  padding: 0 11px 0 10px;
  border: 1px solid #e9e9e9;
  font-size: 11px;
  line-height: 30px;
  color: #222;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}
.com_paginate a:hover,
.com_paginate a:active,
.com_paginate a:focus {
  background: #fafafa;
}
.com_paginate strong {
  border-color: #222;
  background: #222;
  font-weight: normal;
  color: #fff;
}
.com_paginate a.btn_first,
.com_paginate a.btn_prev,
.com_paginate a.btn_next,
.com_paginate a.btn_last {
  display: inline-block;
  overflow: hidden;
  background: #fafafa url(#{$imgPath}/product/v2/sp_cdtl_v2.png) no-repeat;
}
.com_paginate a.btn_first {
  background-position: -90px -60px;
}
.com_paginate a.btn_prev {
  margin-right: 6px;
  background-position: -120px -60px;
}
.com_paginate a.btn_next {
  margin-left: 6px;
  background-position: -150px -60px;
}
.com_paginate a.btn_last {
  background-position: -180px -60px;
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .com_paginate a.btn_first,
  .com_paginate a.btn_prev,
  .com_paginate a.btn_next,
  .com_paginate a.btn_last {
    background-image: url(#{$imgPath}/product/v2/sp_cdtl_v2@2x.png);
    background-size: 480px auto;
  }
}
